import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIEvent, DiceRollEvent, EventType, LogEvent, ResourceEvent } from '../../types';

export interface EventsSliceState {
    logEvents: APIEvent<LogEvent>[];
    resourceEvents: APIEvent<ResourceEvent>[];
    diceRollEvents: APIEvent<DiceRollEvent>[];
}

const initialState: EventsSliceState = {
    logEvents: [],
    resourceEvents: [],
    diceRollEvents: []
};

export const events = createSlice({
    name: 'events',
    initialState,
    reducers: {
        save: (state, action: PayloadAction<APIEvent<any>[]>) => {
            if (action.payload.length > 0) {
                switch (action.payload[0].event_type) {
                    case EventType.DICE_ROLL:
                        state.diceRollEvents = action.payload;
                        break;
                    case EventType.RESOURCE:
                        state.resourceEvents = action.payload;
                        break;
                    case EventType.LOG:
                        state.logEvents = action.payload;
                        break;
                }
            }
        },
        clear: (state) => {
            state.logEvents = [];
            state.resourceEvents = [];
            state.diceRollEvents = [];
        }
    }
});
