import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIPlaySession, APIPlaySessionData } from '../../types';

export interface PlaySessionSliceState {
    playSessions: Record<string, APIPlaySession[]>;
    sessionData: Record<string, APIPlaySessionData>;
}

const initialSessionState: PlaySessionSliceState = {
    playSessions: {},
    sessionData: {}
};

export const sessions = createSlice({
    name: 'sessions',
    initialState: initialSessionState,
    reducers: {
        add: (state, action: PayloadAction<APIPlaySession>) => {
            const { campaign_id } = action.payload;
            if (!Boolean(state.playSessions[campaign_id])) {
                state.playSessions[campaign_id] = [];
            }
            state.playSessions[campaign_id].push(action.payload);
        },
        save: (state, action: PayloadAction<{ campaignUUID: string; sessions: APIPlaySession[] }>) => {
            const { campaignUUID, sessions } = action.payload;
            state.playSessions[campaignUUID] = sessions;
        },
        addData: (state, action: PayloadAction<APIPlaySessionData>) => {
            const { session_id } = action.payload;
            const currentIDs = (state.sessionData?.[session_id]?.latest_events ?? []).map((val) => val.event_id);
            const newIDs = action.payload.latest_events.map((val) => val.event_id);
            const diff = newIDs.filter((id) => !currentIDs.includes(id));
            if (diff.length > 0 || currentIDs.length === 0) {
                state.sessionData[session_id] = action.payload;
            }
        }
    }
});
