import React from 'react';

import { useTheme } from 'styled-components';
import {
    Button,
    Flex,
    Box,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Spacer,
    useRadioGroup,
    useRadio,
    UseRadioProps
} from '@chakra-ui/react';

import { APIUpdateCharacter, DiceRollEvent } from '../../../types';
import { useActiveCharacter, useDiceRollEvents } from '../../../hooks/dataHooks';

import * as S from './DiceRollActions.style';
import { lighten } from 'polished';

enum StatType {
    SPEED = 'Speed',
    MIGHT = 'Might',
    INTELLECT = 'Intellect'
}

const STAT_TYPE_MAP: Record<StatType, string> = {
    [StatType.MIGHT]: 'might',
    [StatType.SPEED]: 'speed',
    [StatType.INTELLECT]: 'intellect'
};

const EFFORT_MODIFIER = (effort: number) => effort * 3;
const LEVEL_MODIFIER = (level: number) => level * 3;

// ===== Slider =====

interface IOptionSliderProps {
    minValue: number;
    maxValue: number;
    title: string;
    value: number;
    step?: number;
    setValue: (val: any) => void;
}

function OptionSlider(props: IOptionSliderProps) {
    const theme = useTheme();
    const { value, setValue, minValue, maxValue, title, step = 1 } = props;
    return (
        <Box bg={theme.colours.card} borderRadius="8px" padding="4px" paddingBottom="8px">
            <Flex padding="8px" color={theme.colours.body}>
                <Box>{title}</Box>
                <Spacer />
                <Box>{value}</Box>
            </Flex>
            <Flex paddingRight="20px" paddingLeft="20px">
                <Slider size="lg" value={value} min={minValue} max={maxValue} step={step} onChange={setValue}>
                    <SliderTrack bg={lighten(0.3, theme.colours.primary)}>
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={theme.colours.primary} />
                    </SliderTrack>
                    <SliderThumb boxSize={4} />
                </Slider>
            </Flex>
        </Box>
    );
}

// ===== Radio Card =====

function RadioCard(props: React.PropsWithChildren<UseRadioProps>) {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const theme = useTheme();

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Flex as="label" flexGrow={1} flexBasis={0}>
            <input {...input} />
            <Flex
                {...(checkbox as any)}
                flexGrow={1}
                height="50px"
                cursor="pointer"
                borderRadius="8px"
                alignItems="center"
                justifyContent="center"
                bg={theme.colours.card}
                color={theme.colours.body}
                _checked={{
                    color: theme.colours.buttonTextFull,
                    bg: theme.colours.primary
                }}
            >
                <Flex>{props.children}</Flex>
            </Flex>
        </Flex>
    );
}

// ===== Root Component =====

interface IDiceRollActionsProps {
    memberName: string;
}

export function DiceRollActions(props: IDiceRollActionsProps) {
    const theme = useTheme();
    const { memberName } = props;
    const { saveDiceEvent } = useDiceRollEvents();
    const [effortCount, setEffortCount] = React.useState(0);
    const [bonusCount, setBonusCount] = React.useState(0);
    const [diceSides, setDiceSides] = React.useState(20);
    const [statType, setStatType] = React.useState(StatType.INTELLECT);
    const { activeCharacter, updateCharacter, isGameMaster } = useActiveCharacter();

    const maxEffort = isGameMaster ? 6 : activeCharacter?.metadata?.effort ?? 0;

    const rollDice = (sides: number = diceSides) => {
        const bonus = EFFORT_MODIFIER(effortCount) + LEVEL_MODIFIER(bonusCount);
        const result = 1 + Math.floor(Math.random() * sides);
        const data: DiceRollEvent = {
            bonus: bonus,
            roll_result: result,
            dice_type: `D${sides}`,
            member_name: memberName
        };

        if (effortCount > 0 && !isGameMaster) {
            const edge = (activeCharacter as any)?.[`${STAT_TYPE_MAP[statType]}_edge`] ?? 0;
            const statPool = (activeCharacter as any)?.[`${STAT_TYPE_MAP[statType]}_current`];
            const useTotal = 2 * effortCount + 1 - edge;

            if (statPool - useTotal < 0) {
                console.error('Not enough points in pool to roll with effort.');
                return;
            }

            saveDiceEvent(data);
            const updateData: APIUpdateCharacter = { [`${STAT_TYPE_MAP[statType]}_current`]: statPool - useTotal };
            updateCharacter(updateData);
        } else {
            saveDiceEvent(data);
        }

        resetState();
    };

    const resetState = () => {
        setBonusCount(0);
        setEffortCount(0);
    };

    const statOptions = [StatType.SPEED, StatType.INTELLECT, StatType.MIGHT];
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'stateType',
        defaultValue: StatType.INTELLECT,
        onChange: setStatType as any
    });

    return (
        <S.DiceActionsRoot>
            <Flex {...(getRootProps() as any)} gap="8px">
                {statOptions.map((value) => (
                    <RadioCard key={value} {...getRadioProps({ value })}>
                        {value}
                    </RadioCard>
                ))}
            </Flex>
            <OptionSlider
                value={diceSides}
                minValue={0}
                maxValue={100}
                setValue={setDiceSides}
                title="Sides"
                step={2}
            />
            <OptionSlider value={bonusCount} minValue={0} maxValue={4} setValue={setBonusCount} title="Bonus" />
            <OptionSlider
                value={effortCount}
                minValue={0}
                maxValue={maxEffort}
                setValue={setEffortCount}
                title="Effort"
            />
            <Flex paddingTop="8px">
                <Button colorScheme={theme.colours.buttonScheme} size="lg" onClick={() => rollDice()} flexGrow={3}>
                    Roll
                </Button>
                <Box width="8px" />
                <Button colorScheme={theme.colours.buttonScheme} size="lg" onClick={() => rollDice(20)} flexGrow={1}>
                    D20
                </Button>
            </Flex>
        </S.DiceActionsRoot>
    );
}
