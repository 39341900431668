import styled from 'styled-components';
import { TextStyles } from '../../../../theme/fonts';

export const OverviewPanelWrapper = styled.div`
    display: flex;
    height: auto;
    border-radius: 15px;
    flex-grow: 1;
    flex-direction: column;
`;

export const ControlBar = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    justify-content: flex-end;
`;

export const OverviewPanelContainer = styled.div`
    overflow: auto;
    display: grid;
    grid-template-columns: [start] 1fr [line1] 1fr [line2] 1fr [end];
    grid-template-rows: [top] 1fr [split1] 1fr [bottom];
    gap: 30px;
    height: 100%;
`;

export const LargePanel = styled.div`
    background: ${props => props.theme.colours.card};
    transition: background-color 0.5s ease;
    display: grid;
    grid-column: span 2;
    border-radius: 15px;
`;

export const SmallPanel = styled.div`
    background: ${props => props.theme.colours.card};
    transition: background-color 0.5s ease;
    display: grid;
    grid-column: span 1;
    border-radius: 15px;
`;

export const SmallPannelInner = styled.div`
    color: ${p => p.theme.colours.subHeading};
    transition: color 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 50px;
`;

export const KeyPanelWrapper = styled.div`
    display: flex;
`;

export const KeyPanel = styled.div`
    width: 100%;
    background: pink;
`;

export const StatLine = styled.div`
    ${TextStyles.Text.Medium65};
    font-size: 24px;
    display: flex;
    justify-content: space-between;
`;

export const StatKey = styled.div`
`;

export const StatValue = styled.div`
    text-align: right;
`;