import { APICreateInventory, APIInventoryGroup, APIInventoryItem, APIUpdateInventory } from '../types';
import { apiHost, authHeaders } from './api';

async function loadInventory(characterID: string): Promise<APIInventoryItem[] | null> {
    const result = await fetch(`${apiHost()}/api/v1/characters/${characterID}/inventory-items`, {
        method: 'GET',
        headers: { ...authHeaders() }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function createInventory(characterID: string, cypherData: APICreateInventory): Promise<APIInventoryItem | null> {
    const result = await fetch(`${apiHost()}/api/v1/characters/${characterID}/inventory-items`, {
        method: 'POST',
        headers: { ...authHeaders(), 'Content-Type': 'application/json' },
        body: JSON.stringify(cypherData)
    });

    if (result.status !== 201) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function updateInventory(itemID: string, updateData: APIUpdateInventory): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/inventory-items/${itemID}`, {
        method: 'PUT',
        headers: { ...authHeaders(), 'Content-Type': 'application/json' },
        body: JSON.stringify(updateData)
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function deleteInventory(itemID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/inventory-items/${itemID}`, {
        method: 'DELETE',
        headers: { ...authHeaders() }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function loadInventoryGroups(characterID: string): Promise<APIInventoryGroup[] | null> {
    const result = await fetch(`${apiHost()}/api/v1/characters/${characterID}/inventory-groups`, {
        method: 'GET',
        headers: { ...authHeaders() }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function createInventoryGroup(characterID: string, title: string): Promise<APIInventoryGroup | null> {
    const result = await fetch(`${apiHost()}/api/v1/characters/${characterID}/inventory-groups`, {
        method: 'POST',
        headers: { ...authHeaders(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ title })
    });

    if (result.status !== 201) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function updateInventoryGroup(groupID: string, newTitle: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/inventory-groups/${groupID}`, {
        method: 'PUT',
        headers: { ...authHeaders(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ title: newTitle })
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function deleteInventoryGroup(groupID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/inventory-groups/${groupID}`, {
        method: 'DELETE',
        headers: { ...authHeaders() }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

export const inventory = {
    loadInventory,
    createInventory,
    updateInventory,
    deleteInventory,
    loadInventoryGroups,
    createInventoryGroup,
    updateInventoryGroup,
    deleteInventoryGroup
};
