import React from 'react';
import CharacterDetailsPanel from './CharacterDetailsPanel/CharacterDetailsPanel';
import CharacterLeftNav from './CharacterLeftNav/CharacterLeftNav';
import * as S from './CharacterPage.style';
import { useSelector } from 'react-redux';
import { selectors } from '../../state';
import { LogInLock } from '../../components/LogInLock';
import { useParams } from 'react-router-dom';
import { useCharacters } from '../../hooks/dataHooks';

export interface ICharacterPageProps {}

const CharacterPage: React.FC<ICharacterPageProps> = () => {
    const { characterID = '' } = useParams();
    const { loadCharacters } = useCharacters();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const character = useSelector((state: any) => selectors.selectCharacter(state, characterID));
    const [greeting, setGreeting] = React.useState('');
    const currentHour = new Date().getHours();

    React.useEffect(() => {
        if (currentHour < 12) {
            setGreeting(`Good Morning`);
        } else if (currentHour < 17) {
            setGreeting(`Good Afternoon`);
        } else if (currentHour < 24) {
            setGreeting(`Good Evening`);
        } else {
            setGreeting(`Welcome`);
        }
    }, [currentHour]);

    React.useEffect(() => {
        loadCharacters();
    }, [activeUser]);

    return (
        <LogInLock>
            <S.CharacterPageWrapper>
                <S.WelcomeBanner>
                    <S.WelcomePrimaryText>{`${greeting} ${
                        character ? character.name : 'Un-named'
                    }`}</S.WelcomePrimaryText>
                </S.WelcomeBanner>
                <S.CharacterBodyWrapper>
                    <CharacterLeftNav />
                    {character && <CharacterDetailsPanel character={character} loadCharacters={loadCharacters} />}
                </S.CharacterBodyWrapper>
            </S.CharacterPageWrapper>
        </LogInLock>
    );
};

export default CharacterPage;
