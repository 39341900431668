import React from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BlockBlobClient } from '@azure/storage-blob';

import { apis } from '../network';
import { actions, AppState, selectors } from '../state';
import {
    APICharacterSkill,
    APICreateInventory,
    APICreateResource,
    APIUpdateCharacter,
    APIUpdateInventory,
    APIUpdateLogEvent,
    DiceRollEvent,
    EventType,
    InventoryItemType,
    LogEvent,
    MemberType,
    ResourceEvent
} from '../types';

// ===== Campaigns =====

export function useCamaigns() {
    const dispatch = useDispatch();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const [isLoadingCampaigns, setIsLoadingCampaigns] = React.useState(false);
    const campaigns = useSelector(selectors.selectCampaigns);

    const loadCampaigns = React.useCallback(async () => {
        if (activeUser) {
            setIsLoadingCampaigns(true);
            const result = await apis.campaigns.getCampaigns();
            if (result) {
                dispatch(actions.campaigns.save(result));
            }
            setIsLoadingCampaigns(false);
        }
    }, [activeUser]);

    const createNewCampaign = React.useCallback(
        async (campaignName: string) => {
            if (activeUser && campaignName) {
                await apis.campaigns.createCampaign(campaignName);
                loadCampaigns();
            }
        },
        [activeUser]
    );

    const removeCampaign = React.useCallback(
        async (campaignUUID: string) => {
            if (activeUser) {
                await apis.campaigns.deleteCampaign(campaignUUID);
                loadCampaigns();
            }
        },
        [activeUser]
    );

    React.useEffect(() => {
        loadCampaigns();
    }, [activeUser]);

    return {
        campaigns,
        activeUser,
        loadCampaigns,
        removeCampaign,
        createNewCampaign,
        isLoadingCampaigns,
        dataLoading: !Boolean(campaigns) && isLoadingCampaigns
    };
}

// ===== Active Campaign =====

export function useActiveCampaign() {
    const { campaignID = '' } = useParams();
    const { activeUser, loadCampaigns, ...extrProps } = useCamaigns();
    const activeCampaign = useSelector((state: AppState) => selectors.selectCampaign(state, campaignID));

    const isGameMaster = activeCampaign?.member_type === MemberType.GAME_MASTER;
    const observedCharacter = useSelector(selectors.selectObservedCharacter);
    const activeCharacter = useSelector((state: AppState) =>
        selectors.selectCharacter(state, activeCampaign?.active_character ?? '')
    );

    const setCampaignCharacter = async (characterID: string) => {
        if (activeUser && activeCampaign && characterID) {
            await apis.campaigns.editCampaignMembership(activeCampaign.id, {
                active_character: characterID
            });
            loadCampaigns();
        }
    };

    return {
        ...extrProps,
        activeUser,
        isGameMaster,
        loadCampaigns,
        activeCampaign,
        setCampaignCharacter,
        activeCharacter: isGameMaster ? observedCharacter : activeCharacter
    };
}

// ===== Play Sessions =====

export function usePlaySessions() {
    const dispatch = useDispatch();
    const { activeUser, activeCampaign, dataLoading, ...extraProps } = useActiveCampaign();
    const [isLoadingSessions, setIsLoadingSessions] = React.useState(false);
    const sessions = useSelector((state: AppState) =>
        selectors.selectCampaignSessions(state, activeCampaign?.id ?? '')
    );

    const loadSessions = React.useCallback(async () => {
        if (activeCampaign && activeUser) {
            setIsLoadingSessions(true);
            const result = await apis.playsessions.getPlaySessions(activeCampaign.id);
            if (result) {
                dispatch(actions.sessions.save({ campaignUUID: activeCampaign.id, sessions: result }));
            }
            setIsLoadingSessions(false);
        }
    }, [activeCampaign, activeUser]);

    const addSession = React.useCallback(
        async (sessionName: string) => {
            if (activeCampaign && activeUser) {
                const result = await apis.playsessions.createPlaySession(activeCampaign?.id ?? '', sessionName);
                if (result) {
                    dispatch(actions.sessions.add(result));
                    loadSessions();
                }
            }
        },
        [activeCampaign, activeUser]
    );

    const removeSession = async (sessionID: string) => {
        if (activeUser) {
            const result = await apis.playsessions.deletePlaySession(sessionID);
            if (result) {
                loadSessions();
            }
        }
    };

    React.useEffect(() => {
        if (activeCampaign) {
            loadSessions();
        }
    }, [activeCampaign, activeUser]);

    return {
        ...extraProps,
        sessions,
        activeUser,
        addSession,
        removeSession,
        loadSessions,
        activeCampaign,
        isLoadingSessions,
        dataLoading: dataLoading || (!Boolean(sessions) && isLoadingSessions)
    };
}

// ===== Active Play Session =====

export function useActivePlaySession() {
    const dispatch = useDispatch();
    const { sessionID = '' } = useParams();
    const { activeUser, activeCampaign, dataLoading, ...extraProps } = usePlaySessions();
    const activeSessionData = useSelector((state: AppState) => selectors.selectPlaySessionData(state, sessionID));
    const [isLoadingSessionData, setIsLoadingSessionData] = React.useState(!activeSessionData);
    const activeSession = useSelector((state: AppState) =>
        selectors.selectPlaySession(state, activeCampaign?.id ?? '', sessionID)
    );

    const loadSessionData = React.useCallback(async () => {
        if (activeUser && activeSession) {
            const result = await apis.playsessions.getPlaySessionData(activeSession.id);
            if (result) {
                dispatch(actions.sessions.addData(result));
            }
        }
    }, [activeSession, activeUser]);

    const joinActivePlaySession = async () => {
        if (activeUser && activeSession) {
            await apis.playsessions.joinPlaySession(activeSession.id);
        }
    };

    React.useEffect(() => {
        if (!activeSessionData) {
            loadSessionData().then(() => setIsLoadingSessionData(false));
        }
    }, [activeSession, activeUser]);

    return {
        ...extraProps,
        activeUser,
        activeCampaign,
        activeSession,
        activeSessionData,
        loadSessionData,
        joinActivePlaySession,
        dataLoading: dataLoading || (!Boolean(activeSessionData) && isLoadingSessionData)
    };
}

// ===== Events =====

export function useDiceRollEvents() {
    const dispatch = useDispatch();
    const { sessionID = '' } = useParams();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const diceEvents = useSelector(selectors.selectDiceRollEvents);
    const latestEventID = useSelector((state: AppState) =>
        selectors.getLatestEventID(state, sessionID, EventType.DICE_ROLL)
    );

    const loadDiceEvents = async () => {
        if (activeUser && sessionID) {
            const events = await apis.events.getEvents<DiceRollEvent>(sessionID, EventType.DICE_ROLL);
            dispatch(actions.events.save(events));
        }
    };

    const saveDiceEvent = async (eventData: DiceRollEvent) => {
        if (activeUser && sessionID) {
            await apis.events.createEvent(sessionID, EventType.DICE_ROLL, eventData);
            loadDiceEvents();
        }
    };

    React.useEffect(() => {
        loadDiceEvents();
    }, [activeUser, sessionID]);

    return {
        activeUser,
        diceEvents,
        saveDiceEvent,
        loadDiceEvents,
        latestEventID
    };
}

export function useLogEvents() {
    const dispatch = useDispatch();
    const { sessionID = '' } = useParams();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const logEvents = useSelector(selectors.getLogEvents);
    const latestEventID = useSelector((state: AppState) => selectors.getLatestEventID(state, sessionID, EventType.LOG));

    const loadLogEvents = async () => {
        if (activeUser && sessionID) {
            const events = await apis.events.getEvents<LogEvent>(sessionID, EventType.LOG);
            dispatch(actions.events.save(events));
        }
    };

    const saveLogEvent = async (eventData: LogEvent) => {
        if (activeUser && sessionID) {
            await apis.events.createEvent(sessionID, EventType.LOG, eventData);
            loadLogEvents();
        }
    };

    const updateLogEvent = async (eventID: string, eventData: APIUpdateLogEvent) => {
        if (activeUser && sessionID && eventID) {
            await apis.events.updateEvent(eventID, eventData);
            loadLogEvents();
        }
    };

    const deleteLogEvent = async (eventID: string) => {
        if (activeUser && eventID) {
            await apis.events.deleteEvent(eventID);
            loadLogEvents();
        }
    };

    React.useEffect(() => {
        loadLogEvents();
    }, [activeUser, sessionID]);

    return {
        logEvents,
        activeUser,
        saveLogEvent,
        loadLogEvents,
        deleteLogEvent,
        latestEventID,
        updateLogEvent
    };
}

export function useResourceEvents() {
    const dispatch = useDispatch();
    const { sessionID = '' } = useParams();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const resourceEvents = useSelector(selectors.selectResourceEvents);
    const latestEventID = useSelector((state: AppState) =>
        selectors.getLatestEventID(state, sessionID, EventType.RESOURCE)
    );

    const loadResourceEvents = async () => {
        if (activeUser && sessionID) {
            const events = await apis.events.getEvents<ResourceEvent>(sessionID, EventType.RESOURCE);
            dispatch(actions.events.save(events));
        }
    };

    React.useEffect(() => {
        loadResourceEvents();
    }, [activeUser, sessionID]);

    return {
        activeUser,
        latestEventID,
        resourceEvents,
        loadResourceEvents
    };
}

// ===== Characters =====

export function useCharacters() {
    const dispatch = useDispatch();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const characters = useSelector(selectors.selectCharacters);
    const campaignCharacters = useSelector(selectors.selectCampaignCharacters);

    const loadCharacters = async () => {
        if (activeUser) {
            const chars = (await apis.character.getUserCharacters()) ?? [];
            dispatch(actions.characters.save(chars));
        }
    };

    const fetchCampaignCharacters = async (campaignUUID: string) => {
        if (activeUser && campaignUUID) {
            const chars = (await apis.character.loadCampaignCharacters(campaignUUID)) ?? [];
            dispatch(actions.characters.saveCampaign(chars));
        }
    };

    const removeCharacter = async (characterID: string) => {
        if (activeUser) {
            await apis.character.deleteCharacter(characterID);
            loadCharacters();
        }
    };

    React.useEffect(() => {
        loadCharacters();
    }, [activeUser]);

    return {
        activeUser,
        characters,
        loadCharacters,
        removeCharacter,
        campaignCharacters,
        fetchCampaignCharacters
    };
}

export function useActiveCharacter(charID?: string) {
    const dispatch = useDispatch();
    const { activeUser, loadCharacters, fetchCampaignCharacters, campaignCharacters, ...extraProps } = useCharacters();
    const { campaignID = '' } = useParams();
    const activeCampaign = useSelector((state: AppState) => selectors.selectCampaign(state, campaignID));

    const isGameMaster = activeCampaign?.member_type === MemberType.GAME_MASTER;
    const observedCharacter = useSelector(selectors.selectObservedCharacter);
    const activeCharacter = useSelector((state: AppState) =>
        selectors.selectCharacter(state, charID ?? activeCampaign?.active_character ?? '')
    );

    const changeCharacter = async (data: APIUpdateCharacter) => {
        if (activeUser && activeCharacter) {
            await apis.character.updateCharacter(activeCharacter.id, data);
            loadCharacters();
            if (isGameMaster) {
                fetchLatestCampaignCharacters();
            }
        } else {
            console.log(activeUser, activeCharacter);
        }
    };

    const observeCharacter = (characterUUID: string) => {
        dispatch(actions.characters.observe(characterUUID));
    };

    const fetchLatestCampaignCharacters = async () => {
        if (isGameMaster && activeCampaign) {
            await fetchCampaignCharacters(activeCampaign.id);
            if (!observedCharacter) {
                dispatch(actions.characters.observe(campaignCharacters?.[0]?.id ?? null));
            }
        }
    };

    React.useEffect(() => {
        if (isGameMaster) {
            fetchLatestCampaignCharacters();
        }
    }, []);

    return {
        ...extraProps,
        activeUser,
        loadCharacters,
        isGameMaster,
        activeCampaign,
        observeCharacter,
        campaignCharacters,
        fetchCampaignCharacters,
        fetchLatestCampaignCharacters,
        updateCharacter: changeCharacter,
        activeCharacter: isGameMaster ? observedCharacter : activeCharacter
    };
}

export function useInventory(charID?: string) {
    const dispatch = useDispatch();
    const { characterID: backupCharID = '' } = useParams();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const inventory = useSelector(selectors.selectInventory);
    const inventoryGroups = useSelector(selectors.selectInventoryGroups);
    const characterID = charID ?? backupCharID;

    const cyphers = inventory.filter((val) => val.type == InventoryItemType.CYPHER);
    const artifacts = inventory.filter((val) => val.type == InventoryItemType.ARTIFACT);
    const genericInventory = inventory.filter((val) => val.type == InventoryItemType.GENERIC);

    const fetchInventory = async () => {
        if (activeUser && characterID) {
            const result = await apis.inventory.loadInventory(characterID);
            if (result) {
                dispatch(actions.characters.saveInventory(result));
            }
        }
    };

    const fetchInventoryGroups = async () => {
        if (activeUser && characterID) {
            const result = await apis.inventory.loadInventoryGroups(characterID);
            if (result) {
                dispatch(actions.characters.saveInventoryGroups(result));
            }
        }
    };

    const addInventory = async (toAdd: APICreateInventory) => {
        if (activeUser && characterID) {
            const result = await apis.inventory.createInventory(characterID, toAdd);
            if (result) {
                fetchInventory();
            }
        }
    };

    const addInventoryGroup = async (title: string) => {
        if (activeUser && characterID) {
            const result = await apis.inventory.createInventoryGroup(characterID, title);
            if (result) {
                fetchInventoryGroups();
            }
        }
    };

    const removeInventory = async (itemID: string) => {
        if (activeUser) {
            const result = await apis.inventory.deleteInventory(itemID);
            if (result) {
                fetchInventory();
            }
        }
    };

    const removeInventoryGroup = async (groupID: string) => {
        if (activeUser) {
            const result = await apis.inventory.deleteInventoryGroup(groupID);
            if (result) {
                fetchInventoryGroups();
                fetchInventory();
            }
        }
    };

    const changeInventory = async (itemID: string, changeData: APIUpdateInventory) => {
        if (activeUser) {
            const result = await apis.inventory.updateInventory(itemID, changeData);
            if (result) {
                fetchInventory();
            }
        }
    };

    const changeInventoryGroup = async (groupID: string, title: string) => {
        if (activeUser) {
            const result = await apis.inventory.updateInventoryGroup(groupID, title);
            if (result) {
                fetchInventoryGroups();
            }
        }
    };

    const groupFilteredInventory = (groupID: string | null) => {
        return genericInventory.filter((item) => item.group_id === groupID);
    };

    React.useEffect(() => {
        fetchInventory();
        fetchInventoryGroups();
    }, [activeUser, characterID]);

    return {
        activeUser,
        inventory: genericInventory,
        cyphers,
        artifacts,
        fetchInventory,
        addInventory,
        removeInventory,
        changeInventory,
        fetchInventoryGroups,
        addInventoryGroup,
        removeInventoryGroup,
        changeInventoryGroup,
        inventoryGroups,
        groupFilteredInventory
    };
}

export function useSkills(charID?: string) {
    const { characterID: backupCharID = '' } = useParams();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const { loadCharacters } = useCharacters();
    const characterID = charID ?? backupCharID;
    const character = useSelector((state: any) => selectors.selectCharacter(state, characterID));
    const skills = character?.skills ?? [];

    const addSkill = async (toAdd: APICharacterSkill) => {
        if (activeUser && characterID && character) {
            const currSkills = character?.skills ?? [];
            const updateData = { skills: [...currSkills, toAdd] };
            await apis.character.updateCharacter(characterID, updateData);
            await loadCharacters();
        }
    };

    const removeSkill = async (toRemove: APICharacterSkill) => {
        if (activeUser && characterID && character) {
            var newSkills = skills.filter((val: APICharacterSkill) => val.name !== toRemove.name);
            const updateData = { skills: newSkills };
            await apis.character.updateCharacter(character.id, updateData);
            await loadCharacters();
        }
    };

    React.useEffect(() => {
        loadCharacters();
    }, [activeUser, characterID]);

    return { activeUser, skills, addSkill, removeSkill, loadCharacters };
}

// ===== Session Resources =====

export function useSessionResources() {
    const dispatch = useDispatch();
    const { sessionID = '' } = useParams();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const activeResources = useSelector(selectors.selectResources);
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [isUploading, setIsUploading] = React.useState(false);

    const loadSessionResources = async () => {
        if (activeUser && sessionID) {
            const resources = await apis.resources.getSessionResources(sessionID);
            if (resources) {
                dispatch(actions.resources.save(resources));
            }
        }
    };

    const uploadSelectedFile = async () => {
        if (selectedFile && activeUser && sessionID) {
            const data: APICreateResource = { name: selectedFile.name, file_size: selectedFile.size };
            const newResource = await apis.resources.createResource(data);
            setIsUploading(true);

            if (!newResource) {
                console.error('Failed to create resource');
                setIsUploading(false);
                return setSelectedFile(null);
            }

            const client = new BlockBlobClient(newResource.upload_url);
            const uploadBlobResponse = await client.uploadData(selectedFile);

            if (uploadBlobResponse.errorCode) {
                console.error('Failed to upload resource');
                setIsUploading(false);
                return setSelectedFile(null);
            }

            let success = await apis.resources.updateResource(newResource.id);
            if (!success) {
                console.error('Failed to update resource');
                setIsUploading(false);
                return setSelectedFile(null);
            }

            success = await apis.resources.assignSessionResource(sessionID, newResource.id);
            if (!success) {
                console.error('Failed to assign session resource');
                setIsUploading(false);
                return setSelectedFile(null);
            }

            await loadSessionResources();
            setIsUploading(false);
            setSelectedFile(null);
        }
    };

    const deleteSessionResource = async (resourceID: string) => {
        if (activeUser && sessionID) {
            await apis.resources.removeSessionResource(sessionID, resourceID);
            await loadSessionResources();
        }
    };

    React.useEffect(() => {
        loadSessionResources();
    }, []);

    React.useEffect(() => {
        uploadSelectedFile();
    }, [selectedFile]);

    return {
        isUploading,
        selectedFile,
        setSelectedFile,
        activeResources,
        deleteSessionResource,
        loadSessionResources
    };
}
