import styled from 'styled-components';

export const PageRoot = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colours.background};
`;

export const Text = styled.div`
    font-size: 32px;
    color: #757575;
    text-align: center;
    ${(props) => props.theme.text.Display.Medium65}
`;
