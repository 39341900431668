import styled from 'styled-components';
import { TextStyles } from '../../../../theme/fonts';

export const ModalWrapper = styled.div`
    background: ${props => props.theme.colours.background};
    padding: 40px;
    border-radius: 15px;

`;

export const ModalTitle = styled.div`
    ${TextStyles.Display.Medium65}
    font-size: 32px;
    margin-bottom: 40px;
    color: ${props => props.theme.colours.heading}
`;

export const FormWrapper = styled.div`
    width: 400px;
`;

export const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const InputLabel = styled.label`
    ${TextStyles.Text.Medium65};
    font-size: 18px;
    color: ${props => props.theme.colours.body};
`;

export const InputField = styled.input`
    ${TextStyles.Text.Roman55};
    padding: 10px;
    font-size: 18px;
    border: none;
    background: ${props => props.theme.colours.card};
    color: ${props => props.theme.colours.body};
    width: 75px;
    text-align: center;
    border-radius: 5px;


    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:active {
        outline: pink;
        border: pink;
    }

    &:focus {
        outline: ${props => props.theme.colours.primary} 2px solid;
    }
`;

export const ModalFooter = styled.div`
    margin-top: 40px;
`;

export const SubmitInput = styled.input`
    color: ${props => props.theme.colours.buttonTextFull};
    background: ${props => props.theme.colours.button};
    ${TextStyles.Text.Medium65};
    font-size: 18px;
    padding: 8px 32px;
    border: none;
    border-radius: 5px;

    &:hover {
        color: ${props => props.theme.colours.buttonTextFull};
        background: ${props => props.theme.colours.buttonHover};
    }
`;