import styled from 'styled-components';
import { TextStyles } from '../../../theme/fonts';

export const CharacterPageWrapper = styled.div`
    flex-grow: 1;
    padding: 80px 100px;
    background: ${(p) => p.theme.colours.background};
`;

export const WelcomeBanner = styled.div`
    margin-bottom: 100px;
`;

export const WelcomePrimaryText = styled.div`
    ${TextStyles.Display.Bold75}
    font-size:3.645835rem;
    color: ${(p) => p.theme.colours.heading};
`;

export const WelcomeSubText = styled.div`
    ${TextStyles.Display.Light45}
    font-style: italic;
    font-size: 1.25rem;
    color: ${(p) => p.theme.colours.subHeading};
`;

export const CharacterInstructions = styled.div`
    ${TextStyles.Display.Medium65}
    font-size: 1.7rem;
    line-height: 20pt;
    color: ${(p) => p.theme.colours.heading};
    margin-bottom: 1.7rem;
`;

export const CharacterSlideContainer = styled.div`
    display: flex;
    gap: 67px;
`;

export const CharacterSlideWrapper = styled.div`
    margin: -20px;
    padding: 20px;
    width: 1256px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: visible;
    scroll-behavior: smooth;
    ${(p) => p.theme.scrollbar}
`;

export const CharacterSlider = styled.div`
    display: flex;
    gap: 67px;
    width: auto;
`;

export const EmptySliderMessage = styled.div`
    ${TextStyles.Display.Roman55};
    font-size: 47px;
    color: ${(p) => p.theme.colours.subHeadingAlt};
    text-align: center;
    max-width: 50rem;
`;
