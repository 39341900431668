import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { events, EventsSliceState } from './slices/events';
import { auth, AuthSliceState } from './slices/auth';
import { sessions, PlaySessionSliceState } from './slices/sessions';
import { campaigns, CampaignsSliceState } from './slices/campaigns';
import { resources, ResourcesSliceState } from './slices/resources';
import { characters, CharacterSliceState } from './slices/characters';
import { preferences, PreferencesSliceState } from './slices/preferences';

const middlewares: any[] = [];

const rootReducer = combineReducers({
    events: events.reducer,
    auth: auth.reducer,
    sessions: sessions.reducer,
    resources: resources.reducer,
    campaigns: campaigns.reducer,
    characters: characters.reducer,
    preferences: preferences.reducer
});

export const actions = {
    events: events.actions,
    auth: auth.actions,
    sessions: sessions.actions,
    resources: resources.actions,
    campaigns: campaigns.actions,
    characters: characters.actions,
    preferences: preferences.actions
};

export interface AppState {
    events: EventsSliceState;
    auth: AuthSliceState;
    campaigns: CampaignsSliceState;
    resources: ResourcesSliceState;
    characters: CharacterSliceState;
    sessions: PlaySessionSliceState;
    preferences: PreferencesSliceState;
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
