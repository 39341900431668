import React from 'react';

import { CypherDataModal } from '../CypherDataModal';
import { APIInventoryItem, APIUpdateInventory, InventoryItemType } from '../../../types';

import * as S from './CypherCard.style';
import { Menu, MenuItem } from '../../Menu';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { MarkdownText } from '../../MarkdownText/MarkdownText';

interface ICypherCardProps {
    cypher: APIInventoryItem;
    isGameMaster?: boolean;
    type: InventoryItemType;
    removeCypher: (itemID: string) => void;
    changeCypher: (itemID: string, cypherData: APIUpdateInventory) => void;
}

export function CypherCard(props: ICypherCardProps) {
    const { type, cypher, removeCypher, changeCypher, isGameMaster = false } = props;
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    return (
        <S.CypherCardRoot disabled={isGameMaster}>
            <S.CardTitleBar>
                <S.CardTitleText>{cypher.name}</S.CardTitleText>
                <S.CardText>Level: {cypher.level ?? 'Unknown'}</S.CardText>
                {!isGameMaster && (
                    <Menu>
                        <MenuItem icon={<EditIcon />} onClick={() => setIsModalOpen(true)}>
                            Edit
                        </MenuItem>
                        <MenuItem icon={<DeleteIcon />} onClick={() => removeCypher(cypher.id)}>
                            Delete
                        </MenuItem>
                    </Menu>
                )}
            </S.CardTitleBar>
            {cypher.description && (
                <S.CardText>
                    <MarkdownText value={cypher.description} />
                </S.CardText>
            )}
            <CypherDataModal
                title="Update Cypher/Artifact"
                type={type}
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                initialData={cypher as APIUpdateInventory}
                onSubmit={(data) => {
                    changeCypher(cypher.id, data);
                    setIsModalOpen(false);
                }}
            />
        </S.CypherCardRoot>
    );
}
