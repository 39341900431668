import React from 'react';
import { interval } from 'rxjs';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useResolvedPath } from 'react-router-dom';

import { EventType } from '../../types';
import { SessionLog } from './SessionLog';
import { AppState, selectors } from '../../state';
import { DiceRollActions } from './DiceRollActions';
import { DiceRollResults } from './DiceRollResults';
import { SessionResources } from './SessionResources';
import { SessionCharacter } from './SessionCharacter';
import { LogInLock } from '../../components/LogInLock';
import { CharacterQuickView } from './CharacterQuickView';
import { useActiveCharacter, useActivePlaySession } from '../../hooks/dataHooks';
import { LoadingWrapper } from '../../components/LoadingWrapper';
import { ActiveCharacterLock } from '../../components/ActiveCharacterLock';
import { CharacterSelectModal } from '../../components/Character/CharacterSelectModal';

import * as S from './ActivePlaySession.style';
import { SessionInventory } from './SessionInventory';

function CharacterSelect() {
    const { activeCharacter, isGameMaster, fetchLatestCampaignCharacters } = useActiveCharacter();
    const [isCharselectOpen, setIsCharSelectOpen] = React.useState(false);
    const prefix = isGameMaster ? 'Viewing' : 'Playing as';

    const handleRefresh = (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        fetchLatestCampaignCharacters();
    };

    return (
        <S.CharacterSelect onClick={() => setIsCharSelectOpen(true)}>
            <S.TextContainer>
                {activeCharacter ? `${prefix} ${activeCharacter.name}` : 'No Character Selected'}
            </S.TextContainer>
            {isGameMaster && (
                <S.RefreshButton onClick={handleRefresh}>
                    <S.RefreshIcon />
                </S.RefreshButton>
            )}
            <CharacterSelectModal isOpen={isCharselectOpen} onClickAway={() => setIsCharSelectOpen(false)} />
        </S.CharacterSelect>
    );
}

export function ActivePlaySession() {
    const {
        activeUser,
        activeSession,
        dataLoading,
        loadSessionData,
        isGameMaster,
        activeCharacter,
        joinActivePlaySession
    } = useActivePlaySession();
    const location = useLocation();

    const latestLogEvent = useSelector((state: AppState) =>
        selectors.getLatestEventID(state, activeSession?.id ?? '', EventType.LOG)
    );
    const latestResourceEvent = useSelector((state: AppState) =>
        selectors.getLatestEventID(state, activeSession?.id ?? '', EventType.RESOURCE)
    );
    const localLatestLogEvent = useSelector(selectors.selectLocalLatestLogEvent);
    const localLatestResEvent = useSelector(selectors.selectLocalLatestResourceEvent);
    const isNewLogs = latestLogEvent !== localLatestLogEvent;
    const isNewResources = latestResourceEvent !== localLatestResEvent;

    const memberName = isGameMaster ? 'Game Master' : activeCharacter?.name ?? '';

    const isLogPage = location.pathname === useResolvedPath('').pathname;
    const isResourcesPage = location.pathname === useResolvedPath('resources').pathname;
    const isCharacterPage = location.pathname === useResolvedPath('character').pathname;
    const isInventoryPage = location.pathname === useResolvedPath('inventory').pathname;

    React.useEffect(() => {
        const subscription = interval(2500).subscribe((_) => {
            loadSessionData();
        });
        return () => subscription.unsubscribe();
    }, [loadSessionData]);

    React.useEffect(() => {
        if (!isGameMaster) {
            joinActivePlaySession();
        }
    }, [activeSession, activeUser]);

    return (
        <LogInLock>
            <S.PageRoot>
                <LoadingWrapper isLoading={dataLoading}>
                    <S.TitleBar>{activeSession?.name ?? ''}</S.TitleBar>
                    <S.Content>
                        <S.DiceContent>
                            <ActiveCharacterLock>
                                <S.DiceRollsPanel>
                                    <DiceRollResults />
                                </S.DiceRollsPanel>
                                <S.DiceRollActions>
                                    <DiceRollActions memberName={memberName} />
                                </S.DiceRollActions>
                            </ActiveCharacterLock>
                        </S.DiceContent>
                        <S.CharacterContent>
                            <CharacterSelect />
                            <S.CharacterPreviewPanel>
                                <CharacterQuickView />
                            </S.CharacterPreviewPanel>
                        </S.CharacterContent>
                        <S.ActionContent>
                            <S.ActionsBar>
                                <S.ActionsBarLink $isActive={isLogPage} to="">
                                    Log
                                    {isNewLogs && !isLogPage && <S.UnreadIndicator />}
                                </S.ActionsBarLink>
                                <S.ActionsBarLink $isActive={isResourcesPage} to="resources">
                                    Resources
                                    {isNewResources && !isResourcesPage && <S.UnreadIndicator />}
                                </S.ActionsBarLink>
                                <S.ActionsBarLink $isActive={isCharacterPage} to="character">
                                    Character
                                </S.ActionsBarLink>
                                <S.ActionsBarLink $isActive={isInventoryPage} to="inventory">
                                    Inventory
                                </S.ActionsBarLink>
                            </S.ActionsBar>
                            <S.ActionsPanel>
                                <ActiveCharacterLock>
                                    <Routes>
                                        <Route
                                            index
                                            element={
                                                <SessionLog loadSessionData={loadSessionData} memberName={memberName} />
                                            }
                                        />
                                        <Route
                                            path="resources"
                                            element={
                                                <SessionResources
                                                    loadSessionData={loadSessionData}
                                                    isGameMaster={isGameMaster}
                                                />
                                            }
                                        />
                                        <Route path="character" element={<SessionCharacter />} />
                                        <Route path="inventory" element={<SessionInventory />} />
                                    </Routes>
                                </ActiveCharacterLock>
                            </S.ActionsPanel>
                        </S.ActionContent>
                    </S.Content>
                </LoadingWrapper>
            </S.PageRoot>
        </LogInLock>
    );
}
