import React from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ToggleColourPreference } from '../ToggleColourPreference';

import { ToggleTheme } from '../ToggleTheme';
import { UserProfile } from '../UserProfile';

import * as S from './AppContent.style';

export function AppContent() {
    const navigate = useNavigate();
    const { campaignID = '' } = useParams();

    return (
        <S.PageRoot>
            <S.NavBarRoot>
                <S.TitleText onClick={() => navigate('')}>Cloth Rock</S.TitleText>
                <Link style={{ color: 'white', fontSize: '30px' }} to="character">
                    Character
                </Link>
                <Link style={{ color: 'white', fontSize: '30px' }} to="campaigns">
                    Campaigns
                </Link>
                {campaignID !== '' && (
                    <Link style={{ color: 'white', fontSize: '30px' }} to={`campaigns/${campaignID}/sessions`}>
                        Sessions
                    </Link>
                )}
                <div style={{ flexGrow: 1 }}></div>
                <ToggleTheme />
                <UserProfile />
            </S.NavBarRoot>
            <S.ContentRoot>
                <Outlet />
            </S.ContentRoot>
        </S.PageRoot>
    );
}
