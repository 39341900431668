import styled from 'styled-components';

import { transparentize } from 'polished';

export const Root = styled.div`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    flex-direction: column;

    &::after {
        top: 0;
        width: 100%;
        height: 100%;
        padding: 32px;
        display: flex;
        position: absolute;
        text-align: center;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        color: ${(p) => p.theme.colours.heading};
        background-color: ${(p) => transparentize(0.1, p.theme.colours.card)};
        content: 'You need to select a campaign character to access this content';
    }
`;
