import { APIPreferences, APIUpdatePreferences, DiscordUser } from '../types';
import { apiHost, authHeaders } from './api';

async function getDiscordUser(access_token: string): Promise<DiscordUser | null> {
    const result = await fetch('https://discord.com/api/oauth2/@me', {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    const jsonData = await result.json();
    return {
        userID: jsonData.user.id,
        username: jsonData.user.username,
        avatarID: jsonData.user.avatar
    };
}

async function getPreferences(): Promise<APIPreferences | null> {
    const result = await fetch(`${apiHost()}/api/v1/preferences`, {
        method: 'GET',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function updatePreferences(prefs: APIUpdatePreferences): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/preferences`, {
        method: 'PUT',
        headers: {
            ...authHeaders(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(prefs)
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function getAccessToken(code: string): Promise<string | null> {
    const result = await fetch(`${apiHost()}/api/v1/oauth/token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code, redirect_uri: `${window.location.origin}/authn` })
    });

    if (result.status !== 201) {
        console.error(await result.text());
        return null;
    }

    return (await result.json())?.access_token ?? '';
}

export const account = {
    getDiscordUser,
    getPreferences,
    updatePreferences,
    getAccessToken
};
