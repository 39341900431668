import React, { FC } from 'react';

import * as S from './CharactersIndexPage.style';
import { useCharacters } from '../../../hooks/dataHooks';
import CharacterCard from '../CharacterCard/CharacterCard';
import CreateCharacterCard from '../CharacterCard/CreateCharacterCard';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../../components/ConfirmDialog';

export interface ICharactersIndexPageProps {}

const CharactersIndexPage: FC<ICharactersIndexPageProps> = () => {
    const { activeUser, characters, removeCharacter } = useCharacters();
    const { openDialog, dialogProps } = useConfirmDialog(removeCharacter);

    const renderedCharacterCards = characters.map((character, index) => {
        return (
            <CharacterCard
                name={character.name}
                descriptor={character.metadata.descriptor}
                type={character.metadata.type}
                focus={character.metadata.focus}
                key={`${character.name}-${index}`}
                id={character.id}
                onRemove={openDialog}
            />
        );
    });

    return (
        <S.CharacterPageWrapper>
            <S.WelcomeBanner>
                <S.WelcomePrimaryText>Welcome {activeUser?.discordUsername ?? 'Fuckwit'}</S.WelcomePrimaryText>
            </S.WelcomeBanner>
            <S.CharacterInstructions>Please select a character or create a new one:</S.CharacterInstructions>
            <S.CharacterSlideContainer>
                <S.CharacterSlideWrapper>
                    {characters.length ? (
                        <S.CharacterSlider>{renderedCharacterCards}</S.CharacterSlider>
                    ) : (
                        <S.EmptySliderMessage>
                            No characters have been created, please create one now to start adventuring
                        </S.EmptySliderMessage>
                    )}
                </S.CharacterSlideWrapper>
                <CreateCharacterCard />
            </S.CharacterSlideContainer>
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
        </S.CharacterPageWrapper>
    );
};

export default CharactersIndexPage;
