import styled from 'styled-components';
import { TextStyles } from '../../../../theme/fonts';

export const PanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    gap: 50px;
    overflow: hidden;
`;

export const SkillsPanel = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    flex-basis: 0;
`;

export const ControlBar = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    justify-content: flex-end;
`;

export const SkillsTableWrapper = styled.div`
    padding: 50px;
    background: ${(props) => props.theme.colours.card};
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
`;

export const SkillLineWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    margin: 20px 0;
    border-radius: 8px;
    align-items: center;

    &:hover {
        background-color: ${(p) => p.theme.colours.background};
    }
`;

export const SkillDescription = styled.div`
    ${TextStyles.Display.Medium65}
    font-size: 16pt;
    width: 100%;
`;

export const SkillIndicators = styled.div`
    display: flex;
    gap: 15px;
`;

export const RadioWrapper = styled.div`
    display: flex;
    width: 80px;
    align-items: center;
    justify-content: center;
`;

export const AbbilitiesPanel = styled.div`
    width: 100%;
    background: orange;
`;

export const SkillsHeaders = styled.div`
    display: flex;
    flex-grow: 1;
    padding-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid #cccccc;
`;

export const SkillsHeadersTitle = styled.div`
    ${TextStyles.Display.Roman55};
    font-size: 16px;
    color: #cccccc;
    flex-grow: 1;
`;

export const SkillsHeaderSelectors = styled.div`
    display: flex;
    gap: 15px;
`;

export const SkillLevel = styled.div`
    ${TextStyles.Display.Roman55};
    font-size: 16px;
    color: #cccccc;
    width: 80px;
    text-align: center;
`;

export const CssReset = styled.div`
    * > a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        text-decoration: revert;
        font-size: revert;
        font-family: revert;
        color: revert;
    }
`;
