import { campaigns } from './campaigns';
import { character } from './character';
import { playsessions } from './playsessions';
import { events } from './events';
import { resources } from './resources';
import { inventory } from './inventory';
import { account } from './account';

export const apis = {
    campaigns,
    character,
    playsessions,
    events,
    resources,
    inventory,
    account
};
