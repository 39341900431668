import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '../../state';
import { ThemeType } from '../../types';
import { CashmereSwitch } from '../Cashmere/CashmereSwitch';

const SmallSwitch = styled(CashmereSwitch)`
    --switch-height: 40px;
`;

export function ToggleTheme() {
    const dispatch = useDispatch();
    const themePreference = useSelector(selectors.selectActiveTheme);

    const onChange = (value: boolean) => {
        dispatch(actions.preferences.setTheme(value ? ThemeType.DARK : ThemeType.LIGHT));
    };

    return <SmallSwitch enabled={themePreference === ThemeType.DARK} onChange={onChange} />;
}
