import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { APICharacter } from '../../../types';
import { BackgroundAndNotes } from './BackgroundAndNotes';

import OverviewPanel from './CharacterOverview/OverviewPanel';
import { CyphersAndArtifacts } from './CyphersAndArtifacts';
import { Inventory } from './Inventory';
import SkillsAndAbilitiesPanel from './SkillsAndAbilities/SkillsAndAbilitiesPanel';

export interface ICharacterDetailsPanelProps {
    character: APICharacter;
    loadCharacters: () => void;
}

const CharacterDetailsPanel: FC<ICharacterDetailsPanelProps> = ({ character, loadCharacters }) => {
    return (
        <Routes>
            <Route index element={<OverviewPanel character={character} />} />
            <Route
                path="skills"
                element={<SkillsAndAbilitiesPanel character={character} loadCharacters={loadCharacters} />}
            />
            <Route path="inventory" element={<Inventory />} />
            <Route path="cyphers" element={<CyphersAndArtifacts />} />
            <Route path="notes" element={<BackgroundAndNotes />} />
        </Routes>
    );
};

export default CharacterDetailsPanel;
