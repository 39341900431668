import React from 'react';
import { APICharacter, APIUpdateCharacter } from '../../../../types';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { apis } from '../../../../network';
import { Modal } from '../../../../components/Modal';
import { selectors } from '../../../../state';
import * as S from './EditCharacterModal.style';

export interface EditCharacterModalProps {
    show: boolean;
    character: APICharacter;
    onHide: () => void;
}

export interface EditCharacterFormProps {
    might_edge: number;
    might_current: number;
    might_max: number;
    speed_edge: number;
    speed_current: number;
    speed_max: number;
    intellect_edge: number;
    intellect_current: number;
    intellect_max: number;
    tier: number;
    effort: number;
    experience: number;
    armour: number;
}

function EditCharacterModal({ show, character, onHide }: EditCharacterModalProps) {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<EditCharacterFormProps>();

    const activeUser = useSelector(selectors.selectAuthenticatedUser);

    const onSubmit = async (data: EditCharacterFormProps) => {
        const updateData: APIUpdateCharacter = {
            stats: {
                might: {
                    max: data.might_max,
                    current: data.might_current,
                    edge: data.might_edge
                },
                intellect: {
                    max: data.intellect_max,
                    current: data.intellect_current,
                    edge: data.intellect_edge
                },
                speed: {
                    max: data.speed_max,
                    current: data.speed_current,
                    edge: data.speed_edge
                },
                armour: data.armour
            },
            metadata: {
                tier: data.tier,
                effort: data.effort,
                experience: data.experience
            }
        };

        if (activeUser) {
            await apis.character.updateCharacter(character?.id, updateData);
            onHide();
        }
    };

    return (
        <Modal isActive={show} onClickAway={onHide}>
            <S.ModalWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <S.ModalTitle>Update character</S.ModalTitle>
                    <S.FormWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Might current</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.might?.current}
                                type="number"
                                {...register('might_current', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Might Maximum</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.might?.max}
                                type="number"
                                {...register('might_max', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Might Edge</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.might?.edge}
                                type="number"
                                {...register('might_edge', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Speed current</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.speed?.current}
                                type="number"
                                {...register('speed_current', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Speed Maximum</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.speed?.max}
                                type="number"
                                {...register('speed_max', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Speed Edge</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.speed?.edge}
                                type="number"
                                {...register('speed_edge', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Intellect current</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.intellect?.current}
                                type="number"
                                {...register('intellect_current', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Intellect Maximum</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.intellect?.max}
                                type="number"
                                {...register('intellect_max', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Intellect Edge</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.intellect?.edge}
                                type="number"
                                {...register('intellect_edge', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Tier</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.metadata?.tier}
                                type="number"
                                {...register('tier', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Effort</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.metadata?.effort}
                                type="number"
                                {...register('effort', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Experience</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.metadata?.experience}
                                type="number"
                                {...register('experience', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                        <S.InputWrapper>
                            <S.InputLabel>Armour</S.InputLabel>
                            <S.InputField
                                defaultValue={character?.stats?.armour}
                                type="number"
                                {...register('armour', { required: true, valueAsNumber: true })}
                            />
                        </S.InputWrapper>
                    </S.FormWrapper>
                    <S.ModalFooter>
                        <S.SubmitInput type="submit" />
                    </S.ModalFooter>
                </form>
            </S.ModalWrapper>
        </Modal>
    );
}

export default EditCharacterModal;
