import styled from 'styled-components';

import { ReactComponent as Bin } from '../../icons/svgs/Bin.svg';

export const PageRoot = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 32px;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.colours.background};
`;

export const SessionPanel = styled.div`
    margin: 2px;
    padding: 16px;
    display: flex;
    background: white;
    border-radius: 16px;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 100px;
    margin-bottom: 16px;
    cursor: pointer;
    background-color: ${(p) => p.theme.colours.card};

    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
`;

export const SessionContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
    ${(p) => p.theme.scrollbar}
`;

export const AddSessionContainer = styled.div`
    margin: 2px;
    padding: 16px;
    display: flex;
    background: white;
    border-radius: 16px;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 100px;
    margin: 16px;
    background-color: ${(p) => p.theme.colours.card};
`;

export const SessionPanelText = styled.div`
    flex-grow: 1;
    font-size: 2rem;
    ${(props) => props.theme.text.Text.Roman55}
    color: ${(p) => p.theme.colours.subHeading};
`;

export const NoSessionsText = styled.div`
    font-size: 32px;
    color: #757575;
    text-align: left;
    margin-bottom: 16px;
    ${(props) => props.theme.text.Display.Roman55}
`;

export const Button = styled.button`
    height: 50px;
    width: 200px;
    color: white;
    border: none;
    border-radius: 8px;
    margin-left: 16px;
    background-color: ${(props) => props.theme.colours.button};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colours.buttonHover};
    }

    &:active {
        transform: translateY(2px);
    }
`;

export const SessionInput = styled.input`
    flex-grow: 1;
    height: 50px;
    font-size: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    color: ${(p) => p.theme.colours.body};
    ${(props) => props.theme.text.Text.Medium65}
    background-color: ${(p) => p.theme.colours.background};

    &::placeholder {
        color: ${(p) => p.theme.colours.subHeadingAlt};
    }
`;

export const BinIcon = styled(Bin)`
    fill: white;
    width: 100%;
    height: 100%;
`;

export const PanelButton = styled.div`
    width: 30px;
    height: 30px;
    padding: 16px;
    cursor: pointer;
    margin-left: 16px;
    border-radius: 25%;
    background-color: ${(props) => props.theme.colours.button};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    &:hover {
        background-color: ${(props) => props.theme.colours.buttonHover};
    }

    &:active {
        transform: translateY(2px);
    }
`;

export const TitleText = styled.div`
    flex-grow: 1;
    font-size: 50px;
    color: ${(p) => p.theme.colours.subHeading};
    ${(props) => props.theme.text.Display.Bold75}
`;

export const TitleBar = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

export const ActiveCharacterPanel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    font-size: 24px;
    min-width: 300px;
    cursor: pointer;
    color: ${(p) => p.theme.colours.buttonTextFull};
    background-color: ${(p) => p.theme.colours.primary};
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    ${(props) => props.theme.text.Text.Roman55}

    &:hover {
        background-color: ${(p) => p.theme.colours.primaryT1};
    }

    &:active {
        transform: translateY(2px);
    }
`;
