import styled, { css } from 'styled-components';

const scrollBarStyle = css`
    /* width */
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 4px;
        background: grey;
    }
`;

const buttonStyles = css`
    padding: 8px;
    display: flex;
    height: 50px;
    color: white;
    border: none;
    font-size: 20px;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    ${(p) => p.theme.text.Display.Medium65};
    background-color: ${(props) => props.theme.colours.button};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colours.buttonHover};
    }

    &:active {
        transform: translateY(2px);
    }
`;

export const RootStyles = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .clothrock-scrollbar {
        ${scrollBarStyle}
    }

    .clothrock-button {
        ${buttonStyles}
    }
`;
