import { Button } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectors } from '../../state';
import { LoadingWrapper } from '../LoadingWrapper';
import { signInWithDiscord } from '../UserProfile';
import * as S from './LogInLock.style';

export function LogInLock(props: React.PropsWithChildren<{}>) {
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const isValidating = useSelector(selectors.selectIsDiscordLoading);

    if (!activeUser) {
        return (
            <S.PageRoot>
                <LoadingWrapper isLoading={isValidating}>
                    <S.Text>You must be logged in to access this page</S.Text>
                    <Button backgroundColor="#7289da" onClick={signInWithDiscord} color="white">
                        Sign in with discord
                    </Button>
                </LoadingWrapper>
            </S.PageRoot>
        );
    }

    return <>{props.children}</>;
}
