import styled, { css } from 'styled-components';

export const ResourcesRoot = styled.div`
    gap: 16px;
    padding: 32px;
    display: flex;
    flex-grow: 1;
    font-size: 20px;
    overflow: hidden;
    flex-direction: column;
    ${(p) => p.theme.text.Text.Roman55}
`;

const dragoverStyles = css`
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    border: dashed 2px grey;
    background-color: #64646464;
`;

export const ResourcesContent = styled.div<{ isDragging: boolean }>`
    gap: 32px;
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
    flex-direction: column-reverse;
    ${(p) => p.theme.scrollbar}

    &::after {
        ${(p) => p.isDragging && dragoverStyles}
        pointer-events: none;
    }
`;

export const ResourceUploadContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ResourceImage = styled.img`
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    object-fit: contain;
`;

export const UploadButton = styled.label<{ disabled?: boolean }>`
    ${p => p.theme.button}
    height: 50px;
    flex-grow: 1;
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    background-color: ${(p) => (p.disabled ? p.theme.colours.buttonDisabled : p.theme.colours.button)};

    &:hover {
        background-color: ${(p) => (p.disabled ? p.theme.colours.buttonDisabled : p.theme.colours.buttonHover)};
    }

    &:active {
        transform: ${(p) => (p.disabled ? 'translateY(0px);' : 'translateY(2px);')};
    }
`;

export const ResourceContainerRoot = styled.div`
    max: 100%;
    display: flex;
    position: relative;
`;

export const RemoveButton = styled.div`
    top: 8px;
    right: 8px;
    width: 60px;
    height: 60px;
    color: white;
    display: flex;
    cursor: pointer;
    position: absolute;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: ${(p) => p.theme.colours.disabledSecondary};

    &:hover {
        background-color: ${(p) => p.theme.colours.buttonDisabled};
    }

    &:active {
        transform: translateY(2px);
    }
`;
