import React, { FC } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Modal } from '../../../../components/Modal';
import { selectors } from '../../../../state';

import { skillOptions, skillLevels } from './SkillsAndAbilities.constants';

import { ReactComponent as CrossIcon } from '../../../../icons/svgs/Cross.svg';

import * as S from './AddSkillModal.style';
import CashmereButton from '../../../../components/Cashmere/CashmereButton/CashmereButton';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { apis } from '../../../../network';
import { APICharacter } from '../../../../types';

export interface IAddSkillModalProps {
    show: boolean;
    onHide: () => void;
    character: APICharacter;
}

const AddSkillModal: FC<IAddSkillModalProps> = ({ show, onHide, character }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        control
    } = useForm();

    const activeUser = useSelector(selectors.selectAuthenticatedUser);

    const onSubmit = async () => {
        const data = getValues();
        console.log(character);
        const skillAdded = {
            name: data.description.value,
            level: data.level.value
        };

        const updatedCharacter = {
            ...character,
            skills: [...(character?.skills ?? []), skillAdded]
        };

        if (activeUser) {
            await apis.character.updateCharacter(character.id, updatedCharacter);
        }
        onHide();
    };

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            width: 300
        })
    };

    return (
        <Modal isActive={show} onClickAway={onHide}>
            <S.ModalWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <S.ModalHeader>
                        Add Skill <CrossIcon onClick={onHide} />
                    </S.ModalHeader>
                    <S.ModalBody style={{ minWidth: '300px' }}>
                        <S.FormField>
                            <S.Label>Skill</S.Label>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }: any) => (
                                    <Creatable isSearchable styles={customStyles} {...field} options={skillOptions} />
                                )}
                            />
                        </S.FormField>
                        <S.FormField>
                            <S.Label>Ability</S.Label>
                            <Controller
                                name="level"
                                control={control}
                                render={({ field }: any) => (
                                    <Select isSearchable styles={customStyles} {...field} options={skillLevels} />
                                )}
                            />
                        </S.FormField>
                    </S.ModalBody>
                    <S.ModalFooter>
                        <CashmereButton text="Add" onClick={() => null} />
                    </S.ModalFooter>
                </form>
            </S.ModalWrapper>
        </Modal>
    );
};

export default AddSkillModal;
