import React from 'react';

import * as S from './CashmereRadioV2.style';

interface IRadioProps {
    name?: string;
    size?: number;
    thickness?: number;
    checked?: boolean;
    checkColour?: string;
    value?: string | number;
    backgroundColour?: string;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CashmereRadioV2(props: IRadioProps) {
    const { size = 40, thickness = 8, checkColour = 'black', backgroundColour = 'white', ...radioProps } = props;
    return (
        <S.Container backgroundColour={backgroundColour} size={size} thickness={thickness}>
            <S.Radio type="radio" {...radioProps} />
            <S.Checkmark checkColour={checkColour} />
        </S.Container>
    );
}
