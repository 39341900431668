import { css } from 'styled-components';

export const NeueHaasGroteskText = `'neue-haas-grotesk-text', sans-serif`;
export const NeueHaasGroteskDisplay = `'neue-haas-grotesk-display', sans-serif`;

export const FontSmoothingCSS = css`
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

//Text Font Weights
const TextProRoman55 = css`
    font-family: ${NeueHaasGroteskText};
    font-weight: 400;
    ${FontSmoothingCSS};
`;

const TextProMedium65 = css`
    font-family: ${NeueHaasGroteskText};
    font-weight: 500;
    ${FontSmoothingCSS};
`;

const TextProBold75 = css`
    font-family: ${NeueHaasGroteskText};
    font-weight: 700;
    ${FontSmoothingCSS};
`;

//Display Font Weights
const DisplayLight45 = css`
    font-family: ${NeueHaasGroteskDisplay};
    font-weight: 400;
    ${FontSmoothingCSS};
`;

const DisplayRoman55 = css`
    font-family: ${NeueHaasGroteskDisplay};
    font-weight: 500;
    ${FontSmoothingCSS};
`;

const DisplayMedium65 = css`
    font-family: ${NeueHaasGroteskDisplay};
    font-weight: 600;
    ${FontSmoothingCSS};
`;

const DisplayBold75 = css`
    font-family: ${NeueHaasGroteskDisplay};
    font-weight: 700;
    ${FontSmoothingCSS};
`;

export const TextStyles = {
    Text: {
        Roman55: TextProRoman55,
        Medium65: TextProMedium65,
        Bold75: TextProBold75
    },
    Display: {
        Light45: DisplayLight45,
        Roman55: DisplayRoman55,
        Medium65: DisplayMedium65,
        Bold75: DisplayBold75
    }
};
