import styled from 'styled-components';

export const Container = styled.label<{ backgroundColour: string; size: number; thickness: number }>`
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    width: ${(p) => p.size}px;
    height: ${(p) => p.size}px;
    padding: ${(p) => p.thickness}px;
    background-color: ${(p) => p.backgroundColour};
`;

export const Radio = styled.input`
    opacity: 0;
    cursor: pointer;
    position: absolute;
`;

export const Checkmark = styled.div<{ checkColour: string }>`
    width: 100%;
    height: 100%;
    border-radius: 50%;

    ${Radio}:checked + && {
        background-color: ${(p) => p.checkColour};
    }
`;
