import { APICreateResource, APINewUserResource, APIUserResource } from '../types';
import { apiHost, authHeaders } from './api';

async function getResources(userID: string): Promise<APIUserResource[] | null> {
    const result = await fetch(`${apiHost()}/api/v1/resources`, {
        method: 'GET',
        headers: {
            ...authHeaders(),
            'X-USER-ID': userID
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function getSessionResources(sessionUUID: string): Promise<APIUserResource[] | null> {
    const result = await fetch(`${apiHost()}/api/v1/sessions/${sessionUUID}/resources`, {
        method: 'GET',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function createResource(data: APICreateResource): Promise<APINewUserResource | null> {
    const result = await fetch(`${apiHost()}/api/v1/resources`, {
        method: 'POST',
        headers: {
            ...authHeaders(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if (result.status !== 201) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function assignSessionResource(sessionID: string, resourceID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/sessions/${sessionID}/resources/${resourceID}`, {
        method: 'PUT',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function removeSessionResource(sessionID: string, resourceID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/sessions/${sessionID}/resources/${resourceID}`, {
        method: 'DELETE',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function updateResource(resourceID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/resources/${resourceID}`, {
        method: 'PUT',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return false;
    }

    return true;
}

export const resources = {
    getResources,
    getSessionResources,
    createResource,
    assignSessionResource,
    removeSessionResource,
    updateResource
};
