import React from 'react';
import { APIUpdateInventory, InventoryItemType } from '../../../types';
import { DataModal, DataModalInput, DataModalTextArea } from '../../DataModal';

interface IModalProps {
    title: string;
    isOpen: boolean;
    close: () => void;
    type: InventoryItemType;
    initialData?: APIUpdateInventory;
    onSubmit: (data: APIUpdateInventory) => void;
}

export function CypherDataModal(props: IModalProps) {
    const { title, isOpen, close, onSubmit, initialData, type } = props;
    const [name, setName] = React.useState(initialData?.name ?? '');
    const [level, setLevel] = React.useState(`${initialData?.level ?? ''}`);
    const [description, setDescription] = React.useState(initialData?.description ?? '');

    const handleSubmit = () => {
        if (name) {
            const data: APIUpdateInventory = {
                name: name,
                level: parseInt(level),
                description: description,
                type: type
            };
            onSubmit(data);
        }
    };

    return (
        <DataModal isOpen={isOpen} onClose={close} onAction={handleSubmit} title={title}>
            <DataModalInput
                title="Name"
                value={name}
                placeholder="(required)"
                onChange={(evt) => setName(evt.target.value)}
            />
            <DataModalInput title="Level" type="number" value={level} onChange={(evt) => setLevel(evt.target.value)} />
            <DataModalTextArea
                title="Description"
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
            />
        </DataModal>
    );
}
