import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables);

export interface DonughtChartProps {
    dataSet: any;
    chartOptions?: any;
}

const defaultOptions = {}

function DonughtChart({dataSet, chartOptions = defaultOptions}: DonughtChartProps) {

    return <Doughnut data={dataSet} options={chartOptions} />

}

export default DonughtChart;