import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIUserCampaign } from '../../types';

export interface CampaignsSliceState {
    campaigns: APIUserCampaign[];
}

const initialCampaignsState: CampaignsSliceState = {
    campaigns: []
};

export const campaigns = createSlice({
    name: 'campaigns',
    initialState: initialCampaignsState,
    reducers: {
        add: (state, action: PayloadAction<APIUserCampaign>) => {
            state.campaigns.push(action.payload);
        },
        save: (state, action: PayloadAction<APIUserCampaign[]>) => {
            state.campaigns = action.payload;
        }
    }
});
