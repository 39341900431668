import React, { FC } from 'react';
import DonughtChart from '../Charts/DonughtChart';
import * as S from './CircularMeter.styled'
import { useTheme } from 'styled-components';
import { SubTitle } from 'chart.js';
export interface CircularMeterProps {
    totalValue: number;
    remainingValue: number;
    statTitle: string;
    edgeValue?: number;
}

function CircularMeter({totalValue, remainingValue, statTitle, edgeValue = 0}: CircularMeterProps) {

    const usedData = totalValue - remainingValue;

    const theme = useTheme();


    const dataSet = {
        labels: [
            'Remaining',
            'Used',
        ],
        datasets: [{
            label: statTitle,
            data: [
                remainingValue, 
                usedData,
            ],
            backgroundColor: [
                theme.colours.button,
                theme.colours.buttonDisabled,
            ]
    }]
    }

    const chartOptions = {
        cutout: '75%',
        circumference: 180,
        rotation: 270,
        aspectRatio: 2,
        borderWidth: 0,
        layout: {
            autoPadding: false,
            padding: 0,
        },
        plugins: { 
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            SubTitle: {
                display: false,
            },
        }
    } 

    return (
        <S.Wrapper>
            <S.Title>
                {statTitle}
            </S.Title>
            <DonughtChart dataSet={dataSet} chartOptions={chartOptions}/>
            <S.ValuesAsText>
                {remainingValue}/{totalValue}
            </S.ValuesAsText>
            <S.SubText>
                Edge: {edgeValue}
            </S.SubText>
        </S.Wrapper>
    )
}
export default CircularMeter;