import React, { FC } from 'react';
import * as S from './CashmereRadioButton.style';

import { ReactComponent as Cross } from '../../../icons/svgs/Cross.svg'
export interface CashmereRadioButtonProps {
    icon?: any;
    checked: boolean;
    checkedColor?: string;
    size?: 'sm' | 'md' | 'lg';
    onClick?: () => void;
}

const CashmereRadioButton: FC<CashmereRadioButtonProps> = ({icon = <Cross />, checked, size = 'md', onClick, checkedColor = "#B2CC3E"}) => {

    return(
        <S.RadioContainer onClick={onClick} size={size} checked={checked} checkedColor={checkedColor}>  
            {checked && icon}
        </S.RadioContainer>
    )

}

export default CashmereRadioButton;