export enum ThemeType {
    LIGHT = 'LIGHT',
    DARK = 'DARK'
}

export enum ColourPrefereneceType {
    OCEAN = 'OCEAN',
    FOREST = 'FOREST',
    ROYAL = 'ROYAL',
    FIRE = 'FIRE'
}
