import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APICharacter, APIInventoryGroup, APIInventoryItem } from '../../types';

export interface CharacterSliceState {
    characters: APICharacter[];
    inventory: APIInventoryItem[];
    inventoryGroups: APIInventoryGroup[];
    campaignCharacters: APICharacter[];
    observedCharacter: string | null;
}

const initialState: CharacterSliceState = {
    characters: [],
    inventory: [],
    inventoryGroups: [],
    campaignCharacters: [],
    observedCharacter: null
};

export const characters = createSlice({
    name: 'characters',
    initialState,
    reducers: {
        save: (state, action: PayloadAction<APICharacter[]>) => {
            state.characters = action.payload;
        },
        saveCampaign: (state, action: PayloadAction<APICharacter[]>) => {
            state.campaignCharacters = action.payload;
        },
        observe: (state, action: PayloadAction<string>) => {
            state.observedCharacter = action.payload;
        },
        saveInventory: (state, action: PayloadAction<APIInventoryItem[]>) => {
            state.inventory = action.payload;
        },
        saveInventoryGroups: (state, action: PayloadAction<APIInventoryGroup[]>) => {
            state.inventoryGroups = action.payload;
        }
    }
});
