import styled from 'styled-components';

export const CharacterRoot = styled.div`
    gap: 16px;
    display: flex;
    flex-grow: 1;
    font-size: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    ${(p) => p.theme.text.Text.Roman55}
`;

export const NoCharacterText = styled.div`
    color: gray;
`;

export const TitleText = styled.div`
    flex-grow: 1;
    font-size: 42px;
    text-decoration: underline;
    color: ${(p) => p.theme.colours.heading};
    ${(p) => p.theme.text.Display.Medium65};
`;

export const CypherTitleBar = styled.div`
    display: flex;
    padding: 16px;
    align-items: center;
`;

export const AddCypherButton = styled.div`
    ${(p) => p.theme.button};
    width: 82px;
    height: 40px;
`;

export const ItemList = styled.div`
    padding-left: 16px;
    padding-right: 16px;
    gap: 8px;
`;
