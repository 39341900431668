import { createSelector } from '@reduxjs/toolkit';
import { APICharacter, EventType } from '../types';

import { AppState } from './store';

const selectEventsSlice = (state: AppState) => state.events;
const selectAuthSlicestate = (state: AppState) => state.auth;
const selectCampaignSlice = (state: AppState) => state.campaigns;
const selectResourcesSlice = (state: AppState) => state.resources;
const selectPlaySessionSlice = (state: AppState) => state.sessions;
const selectCharactersSlice = (state: AppState) => state.characters;
const selectPreferencesSlice = (state: AppState) => state.preferences;

const selectCampaigns = createSelector(selectCampaignSlice, (state) => state.campaigns);
const selectCampaign = createSelector(
    (state: AppState, _: string) => selectCampaigns(state),
    (_: AppState, campaignID: string) => campaignID,
    (campaigns, campaignID) => campaigns.filter((data) => data.id === campaignID)?.[0] ?? null
);

const selectAuthenticatedUser = createSelector(selectAuthSlicestate, (state) => state.authenticatedUser);
const selectIsDiscordLoading = createSelector(selectAuthSlicestate, (state) => state.isLoading);

const selectCampaignSessions = createSelector(
    (state: AppState, _: string) => selectPlaySessionSlice(state),
    (_: AppState, campaignID: string) => campaignID,
    (state, campaignID) => state.playSessions?.[campaignID] ?? []
);
const selectPlaySession = createSelector(
    (state: AppState, campaignID: string, _: string) => selectCampaignSessions(state, campaignID),
    (_: AppState, __: string, sessionID: string) => sessionID,
    (playSessions, sessionID) => playSessions.filter((sesh) => sesh.id === sessionID)?.[0] ?? null
);
const selectPlaySessionData = createSelector(
    (state: AppState, _: string) => selectPlaySessionSlice(state),
    (_: AppState, sessionID: string) => sessionID,
    (state, sessionID) => state.sessionData?.[sessionID] ?? null
);

const selectDiceRollEvents = createSelector(selectEventsSlice, (state) => state.diceRollEvents);
const getLogEvents = createSelector(selectEventsSlice, (state) => state.logEvents);
const selectResourceEvents = createSelector(selectEventsSlice, (state) => state.resourceEvents);

function getLatestEventID(state: AppState, playSessionUUID: string, eventType: EventType): string | null {
    const sessionSlice = selectPlaySessionSlice(state);
    const playSession = sessionSlice.sessionData?.[playSessionUUID];
    return playSession?.latest_events?.filter((evt) => evt.event_type === eventType)?.[0]?.event_id ?? null;
}

const selectCharacters = createSelector(selectCharactersSlice, (state) => state.characters);

function selectCharacter(state: AppState, characterID: string): APICharacter | null {
    const characters = selectCharacters(state);
    return characters.filter((char) => char.id === characterID)?.[0] ?? null;
}

const selectActiveTheme = createSelector(selectPreferencesSlice, (state) => state.activeTheme);
const selectActiveColourPref = createSelector(selectPreferencesSlice, (state) => state.activeColourPref);

const selectResources = createSelector(selectResourcesSlice, (state) => state.resources);

const selectLocalLatestLogEvent = createSelector(getLogEvents, (logEvents) => logEvents?.[0]?.id ?? null);
const selectLocalLatestResourceEvent = createSelector(selectResourceEvents, (resEvents) => resEvents?.[0]?.id ?? null);

const selectInventory = createSelector(selectCharactersSlice, (slice) => slice.inventory);
const selectInventoryGroups = createSelector(selectCharactersSlice, (slice) => slice.inventoryGroups);

const selectCampaignCharacters = createSelector(selectCharactersSlice, (slice) => slice.campaignCharacters);
const selectObservedCharacter = createSelector(
    selectCharactersSlice,
    (slice) => slice.campaignCharacters.filter((camp) => camp.id === slice.observedCharacter)?.[0] ?? null
);

export const selectors = {
    selectCampaigns,
    selectAuthenticatedUser,
    selectCampaignSessions,
    selectIsDiscordLoading,
    selectCampaign,
    selectPlaySession,
    selectPlaySessionData,
    selectDiceRollEvents,
    getLatestEventID,
    getLogEvents,
    selectCharacters,
    selectCharacter,
    selectActiveTheme,
    selectActiveColourPref,
    selectResources,
    selectResourceEvents,
    selectLocalLatestLogEvent,
    selectLocalLatestResourceEvent,
    selectObservedCharacter,
    selectCampaignCharacters,
    selectInventory,
    selectInventoryGroups
};
