import { Link } from 'react-router-dom';
import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as Refresh } from '../../icons/svgs/Refresh.svg';

export const PageRoot = styled.div`
    gap: 32px;
    flex-grow: 1;
    display: flex;
    padding: 32px;
    font-size: 20px;
    overflow: hidden;
    flex-direction: column;
    ${(p) => p.theme.text.Text.Roman55}
    background-color: ${(props) => props.theme.colours.background};
`;

export const TitleBar = styled.div`
    font-size: 40px;
    color: ${(p) => p.theme.colours.subHeading};
    ${(props) => props.theme.text.Display.Bold75}
`;

export const Content = styled.div`
    gap: 16px;
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    flex-direction: row;
`;

export const DiceContent = styled.div`
    gap: 16px;
    display: flex;
    min-width: 20%;
    max-width: 20%;
    overflow: hidden;
    flex-direction: column;
`;

export const CharacterContent = styled.div`
    gap: 16px;
    display: flex;
    min-width: 25%;
    max-width: 25%;
    flex-direction: column;
`;

export const ActionContent = styled.div`
    gap: 16px;
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
`;

export const DiceRollsPanel = styled.div`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colours.card};
`;

export const DiceRollActions = styled.div``;

export const CharacterSelect = styled.div`
    display: flex;
    cursor: pointer;
    min-height: 60px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: ${(p) => p.theme.colours.subHeading};
    background-color: ${(props) => props.theme.colours.card};
`;

export const CharacterPreviewPanel = styled.div`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

export const ActionsBar = styled.div`
    gap: 16px;
    display: flex;
    justify-content: space-evenly;
`;

export const ActionsPanel = styled.div`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colours.card};
`;

export const ActionsBarLink = styled(Link)<{ $isActive: boolean }>`
    position: relative;
    flex-grow: 1;
    text-decoration: none;
    height: 60px;
    box-sizing: border-box;
    border-radius: 8px;
    flex-basis: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: ${(p) => p.theme.colours.subHeading};
    border: solid 2px ${(p) => (p.$isActive ? p.theme.colours.primary : p.theme.colours.subHeadingAlt)};
`;

export const PreviewText = styled.div`
    font-size: 16px;
    text-align: center;
    color: ${(p) => p.theme.colours.subHeadingAlt};
`;

const pulse = (colour: string) => keyframes`
    0% {
		box-shadow: 0 0 0 0 ${transparentize(0.3, colour)};
	}

	70% {
		box-shadow: 0 0 0 8px ${transparentize(1, colour)};
	}

	100% {
		box-shadow: 0 0 0 0 ${transparentize(1, colour)};
	}
`;

export const UnreadIndicator = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    background-color: ${(p) => p.theme.colours.primary};
    animation: ${(p) => pulse(p.theme.colours.primaryT2)} 1.5s infinite;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
`;

export const RefreshIcon = styled(Refresh)`
    width: 40px;
    height: 40px;
    fill: ${(p) => p.theme.colours.heading};
`;

export const RefreshButton = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-self: flex-end;
    justify-content: center;

    &:active {
        transform: translateY(2px);
    }
`;
