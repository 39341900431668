import styled from 'styled-components';

export const Root = styled.div`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    ${(p) => p.theme.text.Display.Medium65}
`;

export const InvList = styled.div`
    gap: 8px;
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    ${(p) => p.theme.scrollbar}
`;

export const AddItemButton = styled.div`
    ${(p) => p.theme.button}
`;
