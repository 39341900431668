import { Box, Flex, Link } from '@chakra-ui/react';
import React from 'react';
import { useTheme } from 'styled-components';
import { useActiveCampaign, useActiveCharacter } from '../../../hooks/dataHooks';
import { APICharacter } from '../../../types';
import { DataModal } from '../../DataModal';
import { CheckCircleIcon } from '@chakra-ui/icons';

// ========== Item ==========

interface ICharacterItem {
    isSelected: boolean;
    character: APICharacter;
    onClick: (charID: string) => void;
}

function CharacterItem(props: ICharacterItem) {
    const { character, onClick, isSelected } = props;
    const theme = useTheme();
    return (
        <Flex
            padding="8px"
            cursor="pointer"
            direction="column"
            borderWidth="1px"
            borderRadius="8px"
            position="relative"
            boxSizing="border-box"
            backgroundColor={theme.colours.card}
            onClick={() => onClick(character.id)}
        >
            <Box color={theme.colours.body} fontSize="25px">
                {character.name}
            </Box>
            <Box
                fontSize="18px"
                color={theme.colours.buttonTextDisabled}
            >{`A ${character.metadata.descriptor} ${character.metadata.type} who ${character.metadata.focus}`}</Box>
            {isSelected && (
                <CheckCircleIcon position="absolute" top="2" right="2" w="6" h="6" color={theme.colours.primary} />
            )}
        </Flex>
    );
}

// ========== Modal ==========

interface ICharacterSelectModalProps {
    isOpen: boolean;
    onClickAway: () => void;
}

export function CharacterSelectModal(props: ICharacterSelectModalProps) {
    const { isOpen, onClickAway } = props;
    const theme = useTheme();
    const { setCampaignCharacter } = useActiveCampaign();
    const {
        characters,
        isGameMaster,
        activeCharacter,
        observeCharacter,
        campaignCharacters,
        fetchLatestCampaignCharacters
    } = useActiveCharacter();

    const chars = isGameMaster ? campaignCharacters : characters;
    const [selectedChar, setSelectedChar] = React.useState(activeCharacter?.id ?? '');

    const onSelect = (characterID: string) => {
        if (characterID) {
            if (isGameMaster) {
                observeCharacter(characterID);
                fetchLatestCampaignCharacters();
            } else {
                setCampaignCharacter(characterID);
            }
        }
        onClickAway();
    };

    React.useEffect(() => {
        setSelectedChar(activeCharacter?.id ?? '');
    }, [isOpen]);

    return (
        <DataModal
            isOpen={isOpen}
            onClose={onClickAway}
            title="Select Character"
            onAction={() => onSelect(selectedChar)}
        >
            <Flex direction="column" gap="16px" overflowX="hidden" overflowY="auto" maxHeight="600px">
                {chars.length === 0 ? (
                    <Flex
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        color={theme.colours.buttonTextDisabled}
                    >
                        <Box>Could not find any characters</Box>
                        {!isGameMaster && <Link href="/character">Click here to create one</Link>}
                    </Flex>
                ) : (
                    chars.map((char) => (
                        <CharacterItem
                            key={char.id}
                            character={char}
                            onClick={setSelectedChar}
                            isSelected={char.id === selectedChar}
                        />
                    ))
                )}
            </Flex>
        </DataModal>
    );
}
