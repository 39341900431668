import React from 'react';

import * as S from './CashmereSwitch.style';

interface CashmereSwitchProps {
    enabled?: boolean; // Controll the value. Leave unset to be self controlled.
    className?: string;
    initiallyEnabled?: boolean;
    onChange?: (value: boolean) => void;
}

export function CashmereSwitch(props: CashmereSwitchProps) {
    const { className, onChange = () => null, initiallyEnabled = false, enabled = null } = props;
    const [isEnabled, setIsEnabled] = React.useState(initiallyEnabled);

    const handleToggle = () => {
        onChange(!(enabled === null ? isEnabled : enabled));
        setIsEnabled(!isEnabled);
    };

    return (
        <S.SwitchRoot className={className} onClick={handleToggle} enabled={enabled === null ? isEnabled : enabled}>
            <S.SwitchThumb enabled={enabled === null ? isEnabled : enabled} />
        </S.SwitchRoot>
    );
}
