import styled, { keyframes } from 'styled-components';

import { ReactComponent as Spinner } from '../../../icons/svgs/Spinner.svg';

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const SpinnerRoot = styled(Spinner)`
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
    animation: 2s ${rotate} linear infinite;
    fill: ${(p) => p.theme.colours.buttonTextFull};
`;
