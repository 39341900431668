import React from 'react';

import * as S from './Modal.style';

interface ModalProps {
    isActive: boolean;
    onClickAway: () => void;
}

export function Modal(props: React.PropsWithChildren<ModalProps>) {
    const { isActive, onClickAway, children } = props;

    const onRootClick = (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        onClickAway();
    };

    return (
        <>
            {isActive && (
                <S.ModalRoot onClick={onRootClick}>
                    <div onClick={(evt) => evt.stopPropagation()}>{children}</div>
                </S.ModalRoot>
            )}
        </>
    );
}
