import styled from 'styled-components';
import { TextStyles } from '../../../theme/fonts';
import { ICashmereButtonProps } from './CashmereButton';

export interface WrapperProps {
}

type ButtonWrapperType = {
    size: 'sm' | 'md' | 'lg'
    disabled: boolean;
};

type ButtonIconType = {
    size: 'sm' | 'md' | 'lg',
    disabled: boolean,
};

enum ButtonSizeEnum {
    sm = 32,
    md = 40,
    lg = 48,
}

enum ButtonIconPadding {
    sm = '8px 16px' as any,
    md = '12px 24px' as any,
    lg = '16px 32px' as any,
}

export const ButtonWrapper = styled.button<ButtonWrapperType>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => ButtonIconPadding[props.size]};
    border-radius: ${props => ButtonSizeEnum[props.size] / 6}px;
    background: ${props => props.disabled ? props.theme.colours.buttonDisabled : props.theme.colours.button};
    border: none;

    &:hover {
        cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
        background: ${props => props.disabled ? props.theme.colours.buttonDisabled : props.theme.colours.buttonHover};
    }
`;

export const ButtonIcon = styled.div<ButtonIconType>`
    width: 100%;
    height: 100%;

    svg {
        height: ${props =>
            ButtonSizeEnum[props.size] - 2 * ButtonIconPadding[props.size]}px;
        width: ${props =>
            ButtonSizeEnum[props.size] - 2 * ButtonIconPadding[props.size]}px;

        path {
            ${props =>
                props.disabled &&
                `
            `};
        }
    }
`;

export const ButtonText = styled.div`
    ${TextStyles.Display.Medium65};
    font-size: 18px;
    white-space: nowrap;
    color: white;
`;