import { APIEvent, APIUpdateLogEvent, EventType } from '../types';
import { apiHost, authHeaders } from './api';

async function getEvents<T>(sessionID: string, eventType: EventType): Promise<APIEvent<T>[]> {
    const result = await fetch(`${apiHost()}/api/v1/sessions/${sessionID}/events?event_type=${eventType}`, {
        method: 'GET',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return [];
    }

    return await result.json();
}

async function createEvent<T>(sessionID: string, eventType: EventType, eventData: T): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/sessions/${sessionID}/events`, {
        method: 'POST',
        headers: {
            ...authHeaders(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ event_type: eventType, event_data: eventData })
    });

    if (result.status !== 201) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function deleteEvent(eventID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/events/${eventID}`, {
        method: 'DELETE',
        headers: {
            ...authHeaders(),
            'Content-Type': 'application/json'
        }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function updateEvent(eventID: string, eventData: APIUpdateLogEvent): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/events/${eventID}`, {
        method: 'PUT',
        headers: {
            ...authHeaders(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ event_data: eventData })
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return false;
    }

    return true;
}

export const events = {
    getEvents,
    createEvent,
    deleteEvent,
    updateEvent
};
