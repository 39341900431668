import React from 'react';

import { APICreateInventory, InventoryItemType } from '../../../../types';
import { useInventory } from '../../../../hooks/dataHooks';
import { CypherCard } from '../../../../components/Character/CypherCard';
import { CypherDataModal } from '../../../../components/Character/CypherDataModal';

import * as S from './CyphersAndArtifacts.style';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../../../components/ConfirmDialog';

export function CyphersAndArtifacts() {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isAModalOpen, setIsAModalOpen] = React.useState(false);
    const { cyphers, artifacts, addInventory, removeInventory, changeInventory } = useInventory();
    const { openDialog: openCypherDialog, dialogProps: cypherDialogProps } = useConfirmDialog(removeInventory);
    const { openDialog: openArtifactDialog, dialogProps: artifactDialogProps } = useConfirmDialog(removeInventory);

    return (
        <S.Root>
            <S.CypherList>
                <S.TitleText>Cyphers</S.TitleText>
                {cyphers.length === 0 && <S.NoCyphersText>You do not have any cyphers</S.NoCyphersText>}
                {cyphers.map((cypher) => (
                    <CypherCard
                        key={cypher.id}
                        cypher={cypher}
                        type={InventoryItemType.CYPHER}
                        changeCypher={changeInventory}
                        removeCypher={openCypherDialog}
                    />
                ))}
                <S.TitleText>Artifacts</S.TitleText>
                {artifacts.length === 0 && <S.NoCyphersText>You do not have any artifacts</S.NoCyphersText>}
                {artifacts.map((artifact) => (
                    <CypherCard
                        key={artifact.id}
                        cypher={artifact}
                        type={InventoryItemType.ARTIFACT}
                        changeCypher={changeInventory}
                        removeCypher={openArtifactDialog}
                    />
                ))}
            </S.CypherList>
            <S.ButtonWrapper>
                <S.AddCypherButton onClick={() => setIsModalOpen(true)}>Add Cypher</S.AddCypherButton>
                <S.AddCypherButton onClick={() => setIsAModalOpen(true)}>Add Artifact</S.AddCypherButton>
            </S.ButtonWrapper>
            <ConfirmDialog {...cypherDialogProps} alertType={AlertType.DELETE} />
            <ConfirmDialog {...artifactDialogProps} alertType={AlertType.DELETE} />
            <CypherDataModal
                title="Add Cypher"
                type={InventoryItemType.CYPHER}
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                onSubmit={(data) => {
                    addInventory(data as APICreateInventory);
                    setIsModalOpen(false);
                }}
            />
            <CypherDataModal
                title="Add Artifact"
                type={InventoryItemType.ARTIFACT}
                isOpen={isAModalOpen}
                close={() => setIsAModalOpen(false)}
                onSubmit={(data) => {
                    addInventory(data as APICreateInventory);
                    setIsAModalOpen(false);
                }}
            />
        </S.Root>
    );
}
