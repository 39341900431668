import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColourPrefereneceType, ThemeType } from '../../types';

export interface PreferencesSliceState {
    activeTheme: ThemeType;
    activeColourPref: ColourPrefereneceType;
}

const initialState: PreferencesSliceState = {
    activeTheme: ThemeType.DARK,
    activeColourPref: ColourPrefereneceType.OCEAN
};

export const preferences = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<ThemeType>) => {
            state.activeTheme = action.payload;
        },
        setColourPref: (state, action: PayloadAction<ColourPrefereneceType>) => {
            state.activeColourPref = action.payload;
        }
    }
});
