import React from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    useDisclosure
} from '@chakra-ui/react';
import { useTheme } from 'styled-components';
import { useActiveCharacter, useInventory } from '../../../hooks/dataHooks';
import { APICreateInventory, APIInventoryGroup, APIInventoryItem, APIUpdateInventory } from '../../../types';
import { GroupDataModal } from '../../Character/CharacterDetailsPanel/Inventory';
import { InventoryDataModal } from '../../../components/Character/InventoryDataModal';
import { ItemCard } from '../../../components/Character/ItemCard';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../../components/ConfirmDialog';
import { Menu, MenuItem } from '../../../components/Menu';

interface IInventorySectionProps {
    title: string;
    groupID: string | null;
    items: APIInventoryItem[];
    groups: APIInventoryGroup[];
    onDelete: (itemID: string) => void;
    onChange: (itemID: string, itemData: APIUpdateInventory) => void;
    onDeleteGroup: (groupID: string) => void;
    onChangeGroup: (groupID: string, newTitle: string) => void;
}

function InventorySection(props: IInventorySectionProps) {
    const theme = useTheme();
    const { title, items, onChange, onDelete, groups, groupID, onDeleteGroup, onChangeGroup } = props;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { openDialog, dialogProps } = useConfirmDialog(onDeleteGroup);
    return (
        <AccordionItem>
            <h2>
                <AccordionButton fontSize="40px">
                    <Flex direction="row" flexGrow={1} alignItems="center">
                        <Box as="span" flex="1" textAlign="left">
                            {title}
                        </Box>
                        {groupID && groupID.length > 0 && (
                            <Menu>
                                <MenuItem icon={<EditIcon />} onClick={onOpen}>
                                    Edit
                                </MenuItem>
                                <MenuItem icon={<DeleteIcon />} onClick={() => openDialog(groupID ?? '')}>
                                    Delete
                                </MenuItem>
                            </Menu>
                        )}
                        <AccordionIcon />
                    </Flex>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                {items.map((item) => (
                    <ItemCard
                        key={item.id}
                        item={item}
                        onDelete={onDelete}
                        onChange={onChange}
                        inventoryGroups={groups}
                    />
                ))}
                {items.length === 0 && (
                    <Flex justifyContent="center">
                        <Box color={theme.colours.buttonDisabled}>This group contains no items</Box>
                    </Flex>
                )}
            </AccordionPanel>
            <GroupDataModal
                isOpen={isOpen}
                title="Edit Inventory Group"
                initialValue={title}
                onClose={onClose}
                handleSave={(newTitle) => onChangeGroup(groupID ?? '', newTitle)}
            />
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
        </AccordionItem>
    );
}

export function SessionInventory() {
    const theme = useTheme();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { activeCharacter } = useActiveCharacter();
    const {
        inventory,
        changeInventory,
        removeInventory,
        addInventory,
        inventoryGroups,
        changeInventoryGroup,
        removeInventoryGroup,
        groupFilteredInventory,
        addInventoryGroup
    } = useInventory(activeCharacter?.id);
    const { isOpen: gIsOpen, onClose, onOpen } = useDisclosure();
    const { openDialog, dialogProps } = useConfirmDialog(removeInventory);
    return (
        <Flex direction="column" flexGrow={1} color={theme.colours.body} overflow="hidden">
            <Flex padding="8px" gap="8px" alignItems="center">
                <Box flexGrow={1} fontSize="40px" textDecoration="underline" fontStyle="italic">
                    Character Inventory
                </Box>
                <Button size="md" colorScheme={theme.colours.buttonScheme} width="200px" onClick={onOpen}>
                    New Group
                </Button>
                <Button
                    size="md"
                    colorScheme={theme.colours.buttonScheme}
                    width="200px"
                    onClick={() => setIsModalOpen(true)}
                >
                    New Item
                </Button>
            </Flex>
            <Flex direction="column" flexGrow={1} overflowY="auto">
                <Accordion defaultIndex={[]} allowMultiple size="md">
                    <InventorySection
                        title="All"
                        items={inventory}
                        groups={inventoryGroups}
                        onDelete={openDialog}
                        onChange={changeInventory}
                        groupID={null}
                        onChangeGroup={changeInventoryGroup}
                        onDeleteGroup={removeInventoryGroup}
                    />
                    {inventoryGroups.map((grp) => (
                        <InventorySection
                            key={grp.id}
                            title={grp.title}
                            items={groupFilteredInventory(grp.id)}
                            groups={inventoryGroups}
                            onDelete={openDialog}
                            onChange={changeInventory}
                            groupID={grp.id}
                            onChangeGroup={changeInventoryGroup}
                            onDeleteGroup={removeInventoryGroup}
                        />
                    ))}
                    <InventorySection
                        title="Unassigned"
                        items={groupFilteredInventory(null)}
                        groups={inventoryGroups}
                        onDelete={openDialog}
                        onChange={changeInventory}
                        groupID={''}
                        onChangeGroup={changeInventoryGroup}
                        onDeleteGroup={removeInventoryGroup}
                    />
                </Accordion>
            </Flex>
            <GroupDataModal
                title="Add Inventory Group"
                isOpen={gIsOpen}
                onClose={onClose}
                handleSave={addInventoryGroup}
            />
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
            <InventoryDataModal
                title="Add Inventory Item"
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                groupOptions={inventoryGroups.map((grp) => ({ value: grp.id, label: grp.title }))}
                onSubmit={(data) => {
                    addInventory(data as APICreateInventory);
                    setIsModalOpen(false);
                }}
            />
        </Flex>
    );
}
