import styled from 'styled-components';
import { TextStyles } from '../../theme/fonts';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    & canvas {
        height: auto !important;
        width: 100% !important;
    }
`;

export const Title = styled.div`
    ${TextStyles.Display.Bold75};
    color: ${(props) => props.theme.colours.heading};
    transition: color 0.5s ease;
    font-size: 32px;
    margin-bottom: 10px;
    text-align: center;
`;

export const ValuesAsText = styled.div`
    ${TextStyles.Display.Medium65};
    color: ${(props) => props.theme.colours.body};
    transition: color 0.5s ease;
    font-size: 64px;
    line-height: 64px;
    text-align: center;
    margin-top: -58px;
`;

export const SubText = styled.div`
    ${TextStyles.Display.Medium65};
    color: ${(props) => props.theme.colours.subHeadingAlt};
    transition: color 0.5s ease;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
`;
