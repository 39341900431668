import Markdown from 'react-markdown';

import * as S from './MarkdownText.styles';

interface Props {
    value: string;
}

export function MarkdownText({ value }: Props) {
    let formatted = value.replace(/\\n/g, '\n');
    formatted = formatted.replace(/^\n/gm, '&nbsp;\n');
    return (
        <S.CssReset>
            <Markdown>{formatted}</Markdown>
        </S.CssReset>
    );
}
