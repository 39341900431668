import Cookies from 'js-cookie';

export function apiHost() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://localhost:8000';
    }
    return 'https://carbon.kiwipolarbear.com';
}

export function authHeaders() {
    return { Authorization: `Bearer ${Cookies.get('access_token') ?? ''}` };
}
