import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { CampaignCard } from './CampaignCard';
import { apis } from '../../network';
import { useCamaigns } from '../../hooks/dataHooks';
import { LogInLock } from '../../components/LogInLock';

import * as S from './Campaigns.styled';
import { Box, Button, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import styled, { useTheme } from 'styled-components';
import { DataModal, DataModalInput } from '../../components/DataModal';

const StyledStack = styled(Stack)`
    ${(p) => p.theme.scrollbar}
`;

// ===== AddCampaignModal =====

interface IAddCampaignModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (campaignName: string) => void;
}

function AddCampaignModal(props: IAddCampaignModalProps) {
    const { isOpen, onClose, onCreate } = props;
    const [newCampaignName, setNewCampaignName] = React.useState('');

    React.useEffect(() => {
        setNewCampaignName('');
    }, [isOpen]);

    return (
        <DataModal title="Add Campaign" isOpen={isOpen} onClose={onClose} onAction={() => onCreate(newCampaignName)}>
            <DataModalInput
                value={newCampaignName}
                placeholder="Enter campaign name..."
                onChange={(e) => setNewCampaignName(e.currentTarget.value)}
            />
        </DataModal>
    );
}

// ===== Campaigns =====

async function handleJoin(navigate: NavigateFunction, loadCampaigns: () => any) {
    const params = new URLSearchParams(window.location.search);

    // Handle code param.
    if (params.has('join')) {
        const joinID = params.get('join') ?? '';
        await apis.campaigns.joinCampaign(joinID);

        // Navigate to current path to remove join code.
        loadCampaigns();
        return navigate(document.location.pathname, { replace: true });
    }
}

export function CampaignsPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { activeUser, campaigns, loadCampaigns, createNewCampaign, removeCampaign } = useCamaigns();

    const handleCreateCampaign = (campaignName: string) => {
        createNewCampaign(campaignName);
        onClose();
    };

    React.useEffect(() => {
        if (activeUser) {
            handleJoin(navigate, loadCampaigns);
        }
    }, [window.location.search.length, activeUser]);

    return (
        <LogInLock>
            <S.PageRoot>
                <Flex direction="column" flexGrow={1} maxWidth="1440px" width="100%" overflow="hidden">
                    <Flex direction="row" justifyContent="space-between" alignItems="center">
                        <S.TitleText>Campaigns</S.TitleText>
                        <Button size="md" colorScheme={theme.colours.buttonScheme} onClick={onOpen}>
                            Add
                        </Button>
                    </Flex>
                    <Box height="4px" borderRadius="8px" bg={theme.colours.body} />
                    <Flex direction="column" flexGrow={1} paddingTop="16px" overflow="hidden">
                        <StyledStack spacing="16px" direction="column" overflowY="auto">
                            {campaigns.map((data) => (
                                <CampaignCard key={data.id} campaign={data} removeCampaign={removeCampaign} />
                            ))}
                            <S.CampaignContainer>
                                {campaigns.length === 0 && (
                                    <S.NoCampaignsText>
                                        You are not a member of any campaigns.
                                        <br />
                                        Create your own campaign below, or ask a Game Master for an invite link!
                                    </S.NoCampaignsText>
                                )}
                            </S.CampaignContainer>
                        </StyledStack>
                    </Flex>
                </Flex>
                <AddCampaignModal isOpen={isOpen} onClose={onClose} onCreate={handleCreateCampaign} />
            </S.PageRoot>
        </LogInLock>
    );
}
