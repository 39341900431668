import React from 'react';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Box, Flex, useToast } from '@chakra-ui/react';

import { APIUserCampaign, MemberType } from '../../../types';

import { useTheme } from 'styled-components';
import { CopyIcon, DeleteIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../../components/ConfirmDialog';
import { Menu, MenuItem } from '../../../components/Menu';

interface ICampaignCardProps {
    campaign: APIUserCampaign;
    removeCampaign: (campaignUUID: string) => void;
}

const MEMBER_TITLES = {
    [MemberType.GAME_MASTER]: 'Game Master',
    [MemberType.PLAYER]: 'Player'
};

export function CampaignCard(props: ICampaignCardProps) {
    const theme = useTheme();
    const toast = useToast();
    const { campaign, removeCampaign } = props;
    const navigate = useNavigate();
    const { openDialog, dialogProps } = useConfirmDialog(removeCampaign);

    // Copy invite link vars.
    const defaultButtonText = 'Copy Invite Link';
    const [buttonText, setButtonText] = React.useState(defaultButtonText);
    const joinURL = new URL(`${document.location.origin}/campaigns`);
    joinURL.searchParams.append('join', campaign.id);

    React.useEffect(() => {
        if (buttonText !== defaultButtonText) {
            setTimeout(() => setButtonText(defaultButtonText), 2000);
        }
    }, [buttonText]);

    return (
        <Flex
            direction="column"
            bg={theme.colours.card}
            color={theme.colours.body}
            borderRadius="16px"
            padding="16px"
            gap="16px"
            fontSize="25px"
            cursor="pointer"
            onClick={() => navigate(`/campaigns/${campaign.id}/sessions`)}
        >
            <Flex justifyContent="space-between" direction="row" alignItems="center">
                <Box fontSize="40px" fontWeight="400">
                    {campaign.name}
                </Box>
                {campaign.member_type === MemberType.GAME_MASTER && (
                    <Flex onClick={(evt) => evt.stopPropagation()}>
                        <Menu>
                            <CopyToClipboard
                                text={joinURL.href}
                                onCopy={() =>
                                    toast({
                                        title: 'Link Copied!',
                                        description: 'The campaign join link has been copied to your clipboard.',
                                        status: 'success',
                                        duration: 5000,
                                        isClosable: true
                                    })
                                }
                            >
                                <MenuItem icon={<CopyIcon />} onClick={() => null}>
                                    Copy Link
                                </MenuItem>
                            </CopyToClipboard>
                            <MenuItem icon={<DeleteIcon />} onClick={() => openDialog(campaign.id)}>
                                Delete
                            </MenuItem>
                        </Menu>
                    </Flex>
                )}
            </Flex>
            <Flex fontSize="20px" direction="column">
                <Box>{MEMBER_TITLES[campaign.member_type]}</Box>
                <Box>
                    {campaign.member_count} Player{campaign.member_count === 1 ? '' : 's'}
                </Box>
                <Box>Created {format(new Date(campaign.created_at), 'dd/MM/yyyy')}</Box>
                {campaign.last_active && <Box>Last Active {format(new Date(campaign.last_active), 'dd/MM/yyyy')}</Box>}
            </Flex>
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
        </Flex>
    );
}
