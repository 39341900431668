import styled, { keyframes, css } from 'styled-components';

const Gradient = keyframes`
    0% {
       background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
`;

const backgroundGradient = css`
    background: linear-gradient(
        90deg,
        red,
        orange,
        yellow,
        green,
        blue,
        indigo,
        violet,
        indigo,
        blue,
        green,
        yellow,
        orange,
        red,
        orange,
        yellow,
        green,
        blue,
        indigo,
        violet
    );
`;

export const PageRoot = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
`;

export const NavBarRoot = styled.div`
    gap: 32px;
    padding-left: 32px;
    padding-right: 4px;
    min-height: 60px;
    display: flex;
    z-index: 100;
    color: white;
    align-items: center;
    flex-direction: row;
    ${backgroundGradient}
    box-sizing: border-box;
    justify-content: space-around;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-size: 300% 100%;
    animation: ${Gradient} 5s infinite linear;
    ${(p) => p.theme.text.Text.Roman55}
`;

export const ContentRoot = styled.div`
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    background-color: ${(p) => p.theme.colours.background};
    ${(p) => p.theme.scrollbar}
`;

export const TitleText = styled.div`
    font-size: 40px;
    cursor: pointer;
    ${(p) => p.theme.text.Display.Medium65}
`;
