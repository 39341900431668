import React from 'react';

import Selectable from 'react-select';

import { APIUpdateInventory, InventoryItemType } from '../../../types';
import { DataModal, DataModalInput, DataModalSelect, DataModalTextArea } from '../../DataModal';

interface GroupOption {
    value: string;
    label: string;
}

interface IModalProps {
    title: string;
    isOpen: boolean;
    close: () => void;
    groupOptions?: GroupOption[];
    initialData?: APIUpdateInventory;
    onSubmit: (data: APIUpdateInventory) => void;
}

export function InventoryDataModal(props: IModalProps) {
    const { title, isOpen, close, onSubmit, initialData, groupOptions } = props;
    const [activeGroup, setActiveGroup] = React.useState(initialData?.group_id ?? null);

    const [name, setName] = React.useState(initialData?.name);
    const [quantity, setQuantity] = React.useState(`${initialData?.quantity ?? ''}`);
    const [description, setDescription] = React.useState(initialData?.description);

    const handleSubmit = () => {
        if (name) {
            const data: APIUpdateInventory = {
                group_id: activeGroup,
                name: name,
                description: description,
                quantity: parseInt(quantity),
                type: initialData?.type ?? InventoryItemType.GENERIC
            };
            onSubmit(data);
        }
    };

    const allOptions = [{ value: null, label: 'Unassigned' }, ...(groupOptions ?? [])];
    const defaultVal = allOptions.filter((val) => val.value == activeGroup)[0];

    return (
        <DataModal isOpen={isOpen} onClose={close} onAction={handleSubmit} title={title}>
            <DataModalInput
                title="Name"
                value={name}
                placeholder="(required)"
                onChange={(evt) => setName(evt.target.value)}
            />
            <DataModalInput title="Quantity" value={quantity} onChange={(evt) => setQuantity(evt.target.value)} />
            {(groupOptions?.length ?? 0) > 0 && (
                <DataModalSelect
                    title="Group"
                    options={allOptions}
                    defaultValue={defaultVal}
                    onChange={(val: any) => setActiveGroup(val?.value ?? null)}
                />
            )}
            <DataModalTextArea
                title="Description"
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
            />
        </DataModal>
    );
}
