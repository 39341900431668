import { APIEditCampaignMembership, APIUserCampaign } from '../types';
import { apiHost, authHeaders } from './api';

async function getCampaigns(): Promise<APIUserCampaign[] | null> {
    const result = await fetch(`${apiHost()}/api/v1/campaigns`, {
        method: 'GET',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function createCampaign(campaignName: string): Promise<APIUserCampaign | null> {
    const result = await fetch(`${apiHost()}/api/v1/campaigns`, {
        method: 'POST',
        headers: {
            ...authHeaders(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: campaignName
        })
    });

    if (result.status !== 201) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function joinCampaign(campaignUUID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/campaigns/${campaignUUID}/join`, {
        method: 'POST',
        headers: { ...authHeaders() }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function deleteCampaign(campaignID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/campaigns/${campaignID}`, {
        method: 'DELETE',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function editCampaignMembership(campaignID: string, data: APIEditCampaignMembership): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/campaigns/${campaignID}/membership`, {
        method: 'PUT',
        headers: {
            ...authHeaders(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

export const campaigns = {
    getCampaigns,
    createCampaign,
    joinCampaign,
    deleteCampaign,
    editCampaignMembership
};
