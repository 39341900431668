import React from 'react';

import * as S from './NotFound.style';

export function NotFound() {
    return (
        <S.NotFoundRoot>
            <S.Text>Not Fucking Found</S.Text>
            <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/dQw4w9WgXcQ?mute=1&autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </S.NotFoundRoot>
    );
}
