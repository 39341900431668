import styled from "styled-components";
import { TextStyles } from "../../theme/fonts";

export interface IndicatorProps {
    active: boolean;
}

export const DamageBarWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;

    & * {
        width: 100%;
    }

    & :first-child div {
        border-radius: 10px 0px 0px 10px;
    }
    & :last-child div {
        border-radius: 0px 10px 10px 0px;
    }

`;

export const DamageBarLevel = styled.div``;

export const DamageBarLabel = styled.h2`
    ${TextStyles.Text.Medium65};
    font-size: 18px;
    color: ${p => p.theme.colours.body};
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
`;

export const DamageBarIndicator = styled.div<IndicatorProps>`
    height: 50px;
    width: 100%;
    background: ${p => (p.active ? p.theme.colours.primary : p.theme.colours.buttonDisabled)};
`;
