import { APIPlaySession, APIPlaySessionData } from '../types';
import { apiHost, authHeaders } from './api';

async function getPlaySessions(campaignID: string): Promise<APIPlaySession[] | null> {
    const result = await fetch(`${apiHost()}/api/v1/campaigns/${campaignID}/sessions`, {
        method: 'GET',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function createPlaySession(campaignID: string, sessionName: string): Promise<APIPlaySession | null> {
    const result = await fetch(`${apiHost()}/api/v1/campaigns/${campaignID}/sessions`, {
        method: 'POST',
        headers: {
            ...authHeaders(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: sessionName
        })
    });

    if (result.status !== 201) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function deletePlaySession(sessionID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/sessions/${sessionID}`, {
        method: 'DELETE',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

async function getPlaySessionData(sessionID: string): Promise<APIPlaySessionData | null> {
    const result = await fetch(`${apiHost()}/api/v1/sessions/${sessionID}`, {
        method: 'GET',
        headers: {
            ...authHeaders()
        }
    });

    if (result.status !== 200) {
        console.error(await result.text());
        return null;
    }

    return await result.json();
}

async function joinPlaySession(playSessionUUID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/sessions/${playSessionUUID}/join`, {
        method: 'POST',
        headers: { ...authHeaders() }
    });

    if (result.status !== 204) {
        console.error(await result.text());
        return false;
    }

    return true;
}

export const playsessions = {
    getPlaySessions,
    createPlaySession,
    deletePlaySession,
    getPlaySessionData,
    joinPlaySession
};
