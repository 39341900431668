import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import { composeTheme, RootStyles } from './theme';
import { CampaignsPage } from './pages/Campaigns';
import CharactersIndexPage from './pages/Character/CharactersIndexPage.tsx/CharactersIndexPage';
import { actions, selectors, store } from './state';
import { AppContent } from './components/AppContent';
import { NotFound } from './pages/NotFound';
import { PlaySessions } from './pages/PlaySessions';
import { AuthenticatingPage } from './pages/Authenticating';
import CharacterPage from './pages/Character/CharacterPage';
import { ActivePlaySession } from './pages/ActivePlaySession';
import { apis } from './network';
import { Home } from './pages/Home';

function CustomThemeProvider(props: React.PropsWithChildren<{}>) {
    const dispatch = useDispatch();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);
    const themePreference = useSelector(selectors.selectActiveTheme);
    const colourPreference = useSelector(selectors.selectActiveColourPref);

    React.useEffect(() => {
        if (activeUser) {
            apis.account.updatePreferences({
                theme_preference: themePreference,
                colour_preference: colourPreference
            });
        }
    }, [themePreference, colourPreference]);

    React.useEffect(() => {
        if (activeUser) {
            const loadPrefs = async () => {
                const prefs = await apis.account.getPreferences();
                if (prefs) {
                    dispatch(actions.preferences.setTheme(prefs.theme_preference));
                    dispatch(actions.preferences.setColourPref(prefs.colour_preference));
                }
            };
            loadPrefs();
        }
    }, [activeUser]);

    return (
        <ThemeProvider theme={composeTheme(themePreference, colourPreference)}>
            <RootStyles>{props.children}</RootStyles>
        </ThemeProvider>
    );
}

export function App() {
    return (
        <Provider store={store}>
            <CustomThemeProvider>
                <ChakraProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<AppContent />}>
                                <Route index element={<Home />} />
                                <Route path="authn" element={<AuthenticatingPage />} />
                                <Route path="campaigns" element={<CampaignsPage />} />
                                <Route path="campaigns/:campaignID/sessions" element={<PlaySessions />} />
                                <Route
                                    path="campaigns/:campaignID/sessions/:sessionID/*"
                                    element={<ActivePlaySession />}
                                />
                                <Route path="character" element={<CharactersIndexPage />} />
                                <Route path="character/:characterID/*" element={<CharacterPage />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ChakraProvider>
            </CustomThemeProvider>
        </Provider>
    );
}
