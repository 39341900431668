import styled from 'styled-components';

import { CashmereTextArea as InputComponent } from '../../../components/Cashmere/CashmereTextArea';

export const LogRoot = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 20px;
    max-width: 100%;
    ${(p) => p.theme.text.Text.Roman55}
`;

export const LogMessagesContainer = styled.div`
    gap: 16px;
    flex-grow: 1;
    display: flex;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: flex-start;
    flex-direction: column-reverse;
    ${(p) => p.theme.scrollbar}
`;

export const SubmitLogContainer = styled.div`
    gap: 16px;
    padding: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
`;

export const Input = styled.input`
    flex-grow: 1;
    height: 50px;
    font-size: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    color: ${(p) => p.theme.colours.body};
    ${(props) => props.theme.text.Text.Medium65}
    background-color: ${(p) => p.theme.colours.background};

    &::placeholder {
        color: ${(p) => p.theme.colours.subHeadingAlt};
    }
`;

export const Button = styled.button`
    ${(p) => p.theme.button};
    font-size: 16px;
    height: 50px;
    width: 200px;
`;

export const LogRowRoot = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LogHeader = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    ${(p) => p.theme.text.Text.Roman55}
`;

export const LogMessage = styled.div`
    white-space: pre-wrap;
    color: ${(p) => p.theme.colours.subHeading};
    ${(p) => p.theme.text.Text.Roman55}
`;

export const MemberNameText = styled.div`
    color: ${(p) => p.theme.colours.subHeadingAlt};
    font-size: 16px;
    font-style: italic;
    flex-grow: 1;
`;

export const LogTimeText = styled.div`
    color: ${(p) => p.theme.colours.subHeadingAlt};
    font-size: 12px;
`;

export const NoLogevents = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
`;

export const CashmereTextArea = styled(InputComponent)`
    & > textarea {
        height: 50px;
        line-height: 50px;
    }
`;
