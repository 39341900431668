import styled, { css } from 'styled-components';

interface ContainerProps {
    size: 'sm' | 'md' | 'lg';
    checked: boolean;
    checkedColor: string;
}

enum SizeEnum {
    sm = 12,
    md = 18,
    lg = 24,
}

export const RadioContainer = styled.div<ContainerProps>`
    background: orange;
    width: ${props => SizeEnum[props.size]}px;
    height: ${props => SizeEnum[props.size]}px;
    min-width: ${props => SizeEnum[props.size]}px;
    min-height: ${props => SizeEnum[props.size]}px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    ${props => props.checked ? css`
        background: ${props.checkedColor};
    ` : css`
        background: #CCCCCC;
    `}
    & svg {
        position: absolute;
        top: 25%;
        left: 25%;
        width: ${props => `calc(${SizeEnum[props.size]}px - ${SizeEnum[props.size] / 2}px)`};
        height: ${props => `calc(${SizeEnum[props.size]}px - ${SizeEnum[props.size] / 2}px)`};
        & path {
            width: ${props => `calc(${SizeEnum[props.size]}px - ${SizeEnum[props.size] / 2}px)`};
            height: ${props => `calc(${SizeEnum[props.size]}px - ${SizeEnum[props.size] / 2}px)`};
            fill: white;
        }
    }
`;

