import React from 'react';

import * as S from './Home.style';

export function Home() {
    return (
        <S.Root>
            <S.Heading>Welcome to Cloth Rock</S.Heading>
            <S.SubHeading>Rhys is a whore</S.SubHeading>
        </S.Root>
    );
}
