import React from 'react';

import * as S from './LoadingWrapper.styled';

interface ILoadingWrapperProps {
    isLoading: boolean;
}

export function LoadingWrapper(props: React.PropsWithChildren<ILoadingWrapperProps>) {
    const { isLoading, children } = props;

    if (isLoading) {
        return (
            <S.LoadingRoot>
                <S.LoadingText>Loading some shit...</S.LoadingText>
            </S.LoadingRoot>
        );
    }

    return <>{children}</>;
}
