import styled from 'styled-components';

import { ReactComponent as Cross } from '../../../icons/svgs/Cross.svg';

export const ItemCard = styled.div`
    padding: 8px;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    color: ${(p) => p.theme.colours.heading};
    background-color: ${(p) => p.theme.colours.card};

    &:hover {
        background-color: ${(p) => p.theme.colours.background};
    }
`;

export const CardTitle = styled.div`
    font-size: 20px;
    flex-grow: 1;
`;

export const CardDesc = styled.div`
    font-size: 16px;
`;

export const CardQuantity = styled.div`
    font-size: 16px;
`;

export const CardHeader = styled.div`
    gap: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const RemoveButton = styled(Cross)`
    width: 16px;
    min-width: 16px;
    cursor: pointer;
`;
