import React, { FC } from 'react';
import * as S from './CreateCharacterModal.style';
import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { selectors } from '../../../state';

import { ReactComponent as CrossIcon } from '../../../icons/svgs/Cross.svg';
import { apiHost, authHeaders } from '../../../network/api';
export interface ICreateCharacterModalProps {
    show: boolean;
    onHide: () => void;
}

export interface ICreateCharacterFormData {
    name: string;
    descriptor: string;
    type: string;
    focus: string;
}

const CreateCharacterModal: FC<ICreateCharacterModalProps> = ({ show, onHide }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<ICreateCharacterFormData>();
    const activeUser = useSelector(selectors.selectAuthenticatedUser);

    async function saveCharacter(data: ICreateCharacterFormData) {
        if (activeUser) {
            await fetch(`${apiHost()}/api/v1/characters`, {
                method: 'POST',
                headers: { ...authHeaders(), 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: data.name,
                    metadata: { descriptor: data.descriptor, type: data.type, focus: data.focus }
                })
            });
            onHide();
        }
    }

    const onSubmit = (data: ICreateCharacterFormData) => {
        saveCharacter(data);
    };

    return show ? (
        <S.ModalBaseContainer>
            <S.ModalWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <S.ModalHeader>
                        Create new character <CrossIcon onClick={onHide} />
                    </S.ModalHeader>
                    <S.ModalBody>
                        Fill in this form to get started with creating a new character...
                        {/* register your input into the hook by invoking the "register" function */}
                        <S.FormFields>
                            <S.FormField>
                                <S.Label>Character name:</S.Label>
                                <S.Input {...register('name', { required: true })} />
                            </S.FormField>
                            <S.FormField>
                                <S.Label>Descriptor:</S.Label>
                                <S.Input {...register('descriptor', { required: true })} />
                            </S.FormField>
                            <S.FormField>
                                <S.Label>Type:</S.Label>
                                <S.Input {...register('type', { required: true })} />
                            </S.FormField>
                            <S.FormField>
                                <S.Label>Focus:</S.Label>
                                <S.Input {...register('focus', { required: true })} />
                            </S.FormField>
                        </S.FormFields>
                        {/* include validation with required or other standard HTML validation rules */}
                    </S.ModalBody>
                    <S.ModalFooter>
                        <S.SubmitButton type="submit" />
                    </S.ModalFooter>
                </form>
            </S.ModalWrapper>
        </S.ModalBaseContainer>
    ) : null;
};

export default CreateCharacterModal;
