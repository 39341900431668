import styled from 'styled-components';

export const LoadingRoot = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
`;

export const LoadingText = styled.div`
    font-size: 16px;
    color: #757575;
    text-align: center;
    ${(props) => props.theme.text.Display.Medium65}
`;
