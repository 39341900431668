import styled from 'styled-components';

export const PageRoot = styled.div`
    display: flex;
    padding: 32px;
    flex-grow: 1;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    background-color: ${(props) => props.theme.colours.background};
`;

export const CampaignContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
    ${(p) => p.theme.scrollbar}
`;

export const NoCampaignsText = styled.div`
    font-size: 32px;
    color: #757575;
    text-align: left;
    margin-bottom: 16px;
    ${(props) => props.theme.text.Display.Roman55}
`;

export const Button = styled.button`
    height: 50px;
    width: 200px;
    color: white;
    border: none;
    border-radius: 8px;
    margin-left: 16px;
    background-color: ${(props) => props.theme.colours.button};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colours.buttonHover};
    }

    &:active {
        transform: translateY(2px);
    }
`;

export const CampaignInput = styled.input`
    flex-grow: 1;
    height: 50px;
    font-size: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    color: ${(p) => p.theme.colours.body};
    ${(props) => props.theme.text.Text.Medium65}
    background-color: ${(p) => p.theme.colours.background};

    &::placeholder {
        color: ${(p) => p.theme.colours.subHeadingAlt};
    }
`;

export const CreateCampaignPanel = styled.div`
    margin: 2px;
    padding: 16px;
    display: flex;
    border-radius: 16px;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 100px;
    margin: 16px;
    background-color: ${(p) => p.theme.colours.card};
`;

export const TitleText = styled.div`
    font-size: 50px;
    ${(props) => props.theme.text.Display.Bold75}
    color: ${(p) => p.theme.colours.subHeading};
`;
