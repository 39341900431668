import { HamburgerIcon } from '@chakra-ui/icons';
import {
    Flex,
    IconButton,
    Menu as ChakraMenu,
    MenuButton,
    MenuItem as ChakraMenuItem,
    MenuList
} from '@chakra-ui/react';
import React from 'react';
import { useTheme } from 'styled-components';

// ===== MenuItem =====

interface IMenuItemProps {
    onClick: () => void;
    icon?: React.ReactElement<any>;
}

export function MenuItem(props: React.PropsWithChildren<IMenuItemProps>) {
    const { icon, onClick, children } = props;
    const theme = useTheme();
    return (
        <ChakraMenuItem
            fontSize="25px"
            icon={icon}
            onClick={onClick}
            bg={theme.colours.card}
            color={theme.colours.body}
            _hover={{ bg: theme.colours.background }}
        >
            {children}
        </ChakraMenuItem>
    );
}

// ===== Menu =====

interface IMenuProps {
    size?: string;
}

export function Menu(props: React.PropsWithChildren<IMenuProps>) {
    const theme = useTheme();
    const { children, size = 'md' } = props;
    return (
        <Flex onClick={(evt) => evt.stopPropagation()}>
            <ChakraMenu>
                <MenuButton
                    size={size}
                    as={IconButton}
                    variant="ghost"
                    aria-label="Options"
                    icon={<HamburgerIcon />}
                    color={theme.colours.body}
                    colorScheme={theme.colours.buttonScheme}
                    _hover={{ bg: theme.colours.buttonDisabled }}
                    _expanded={{ bg: theme.colours.buttonDisabled }}
                />
                <MenuList bg={theme.colours.card} borderColor={theme.colours.body}>
                    {children}
                </MenuList>
            </ChakraMenu>
        </Flex>
    );
}
