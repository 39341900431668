import React, { FC } from 'react';
import { resolvePath, useLocation, useResolvedPath } from 'react-router-dom';

import * as S from './CharacterLeftNav.style';

const CharacterLeftNav: FC<{}> = () => {
    const location = useLocation();

    const overviewActive = location.pathname === useResolvedPath('').pathname;
    const skillsActive = location.pathname === useResolvedPath('skills').pathname;
    const inventoryActive = location.pathname === useResolvedPath('inventory').pathname;
    const cyphersActive = location.pathname === useResolvedPath('cyphers').pathname;
    const notesActive = location.pathname === useResolvedPath('notes').pathname;

    return (
        <S.Wrapper>
            <S.Tab $isActive={overviewActive} to="">
                {'Overview'}
            </S.Tab>
            <S.Tab $isActive={skillsActive} to="skills">
                {'Skills & Abilities'}
            </S.Tab>
            <S.Tab $isActive={inventoryActive} to="inventory">
                {'Inventory'}
            </S.Tab>
            <S.Tab $isActive={cyphersActive} to="cyphers">
                {'Cyphers & Artifacts'}
            </S.Tab>
            <S.Tab $isActive={notesActive} to="notes">
                {'Background & Notes'}
            </S.Tab>
        </S.Wrapper>
    );
};

export default CharacterLeftNav;
