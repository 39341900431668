import React from 'react';

export function useEnterKeyRef(onEnter: () => void) {
    const elementRef = React.useRef<any>(null);
    React.useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
                event.preventDefault();
                onEnter();
            }
        };

        if (elementRef && elementRef.current) {
            elementRef.current.addEventListener('keydown', listener, false);
            return () => {
                elementRef.current && elementRef.current.removeEventListener('keydown', listener, false);
            };
        }
    }, [elementRef, onEnter]);

    return { elementRef };
}

export function useScrollToRef() {
    const scrollRef = React.useRef<any>(null);
    const scrollToElement = () => {
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return { scrollRef, scrollToElement };
}

export function useDragAndDrop(onDrop: (file: File) => void) {
    const [isDragging, setIsDragging] = React.useState(false);
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files.length === 1) {
            onDrop(event.dataTransfer.files[0]);
        }
        setIsDragging(false);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    };

    return {
        isDragging,
        setIsDragging,
        dragProps: {
            onDrop: handleDrop,
            onDragOver: handleDragOver,
            onDragLeave: handleDragLeave
        }
    };
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
