import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    position: relative;
    border-radius: 8px;
    ${(p) => p.theme.text.Display.Roman55}
    background-color: ${(p) => p.theme.colours.background};
`;

export const TextArea = styled.textarea<{ hasTitle?: boolean }>`
    width: 100%;
    padding: 0px 4px;
    border: none;
    resize: none;
    outline: none;
    display: block;
    font-size: 20px;
    background: none;
    padding-left: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    ${(p) => p.theme.text.Display.Roman55}
    color: ${(p) => p.theme.colours.heading};
    ${(p) => p.hasTitle && 'padding-top: 18px;'}

    &::placeholder {
        ${(p) => p.theme.text.Display.Roman55}
    }
`;

export const Label = styled.div`
    top: 0px;
    left: 8px;
    color: gray;
    bottom: 0px;
    display: flex;
    font-size: 16px;
    position: absolute;
    align-items: center;
    pointer-events: none;
    transition: 0.2s ease;
    box-sizing: border-box;
    transform-origin: left top;

    ${TextArea}:focus ~ & ,
    ${TextArea}:not(:focus):valid ~ & {
        transform: translate(-2px, -8px) scale(0.75);
    }
`;
