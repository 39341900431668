import React from 'react';

import * as S from './CashmereTextArea.style';

interface ITextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    name?: string;
    className?: string;
}

export const CashmereTextArea = React.forwardRef(
    (props: ITextAreaProps, ref: React.ForwardedRef<HTMLTextAreaElement>) => {
        const { name, className, ...inputProps } = props;
        return (
            <S.Root className={className}>
                <S.TextArea required ref={ref} hasTitle={Boolean(name)} {...inputProps} />
                {name && <S.Label>{name}</S.Label>}
            </S.Root>
        );
    }
);
