import styled from 'styled-components';

export const ModalRoot = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: rgba(91, 92, 94, 0.5);
`;
