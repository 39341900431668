import React from 'react';
import { format } from 'date-fns';

import { APIEvent, DiceRollEvent } from '../../../types';
import { useScrollToRef } from '../../../hooks/utilHooks';
import { useDiceRollEvents } from '../../../hooks/dataHooks';

import * as S from './DiceRollResults.style';

interface IRollResultProps {
    diceEvent: APIEvent<DiceRollEvent>;
}

function RollResult(props: IRollResultProps) {
    const { diceEvent } = props;
    return (
        <S.RollResultRoot>
            <S.RollTitle>
                <S.RollTitleText>{diceEvent.event_data.member_name}</S.RollTitleText>
                <S.RollTimeText>{format(new Date(`${diceEvent.created_at}`), 'h:mm a')}</S.RollTimeText>
            </S.RollTitle>
            <S.RollResultContent>
                <S.RollResultPanel>
                    <div>{diceEvent.event_data.dice_type}</div>
                </S.RollResultPanel>
                <S.RollResultPanel>
                    <S.RollFieldText>Result</S.RollFieldText>
                    <div>{diceEvent.event_data.roll_result}</div>
                </S.RollResultPanel>
                <S.RollResultPanel>
                    <S.RollFieldText>Bonus</S.RollFieldText>
                    <div>{diceEvent.event_data.bonus}</div>
                </S.RollResultPanel>
                <S.RollResultPanel>
                    <S.RollFieldText>Total</S.RollFieldText>
                    <S.TotalRollText>{diceEvent.event_data.roll_result + diceEvent.event_data.bonus}</S.TotalRollText>
                </S.RollResultPanel>
            </S.RollResultContent>
        </S.RollResultRoot>
    );
}

export function DiceRollResults() {
    const { diceEvents, loadDiceEvents, latestEventID } = useDiceRollEvents();
    const { scrollRef, scrollToElement } = useScrollToRef();

    React.useEffect(() => {
        if (latestEventID !== diceEvents?.[0]?.id ?? '') {
            loadDiceEvents();
        }
    }, [latestEventID]);

    React.useEffect(() => {
        scrollToElement();
    }, [diceEvents]);

    return (
        <S.ResultsRoot>
            <div ref={scrollRef} />
            {diceEvents.length === 0 && <S.NoDiceRolls>No one has rolled the dice yet!</S.NoDiceRolls>}
            {diceEvents.map((evt, i) => (
                <React.Fragment key={i}>
                    <S.Spacer key={i} />
                    <RollResult key={evt.id} diceEvent={evt} />
                </React.Fragment>
            ))}
        </S.ResultsRoot>
    );
}
