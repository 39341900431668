import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Flex,
    Button
} from '@chakra-ui/react';

import { APICharacter, APICharacterSkill, APICreateInventory, InventoryItemType } from '../../../types';
import { CypherCard } from '../../../components/Character/CypherCard';
import { useActiveCharacter, useInventory, useSkills } from '../../../hooks/dataHooks';
import { CypherDataModal } from '../../../components/Character/CypherDataModal';

import * as S from './SessionCharacter.style';
import { useTheme } from 'styled-components';
import {
    AbilityCard,
    AbilityModal,
    SkillLine,
    useAbilityModal
} from '../../Character/CharacterDetailsPanel/SkillsAndAbilities/SkillsAndAbilitiesPanel';
import AddSkillModal from '../../Character/CharacterDetailsPanel/SkillsAndAbilities/AddSkillModal';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../../components/ConfirmDialog';

// ===== Cyphers =====

interface ICyphersPanelProps {
    isGameMaster: boolean;
    activeCharacter: APICharacter | null;
}

function CyphersPanel(props: ICyphersPanelProps) {
    const theme = useTheme();
    const { isGameMaster, activeCharacter } = props;
    const { cyphers, removeInventory, changeInventory, addInventory } = useInventory(activeCharacter?.id);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { openDialog, dialogProps } = useConfirmDialog(removeInventory);

    return (
        <AccordionItem>
            <h2>
                <AccordionButton fontSize="40px">
                    <Flex direction="row" gap="16px" flexGrow={1} alignItems="center">
                        <Box as="span" flex="1" textAlign="left" textDecoration="underline" fontSize="40px">
                            Cyphers
                        </Box>
                        {!isGameMaster && (
                            <Flex onClick={(evt) => evt.stopPropagation()}>
                                <Button
                                    size="md"
                                    colorScheme={theme.colours.buttonScheme}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    New
                                </Button>
                            </Flex>
                        )}
                        <AccordionIcon />
                    </Flex>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                {cyphers.map((cypher) => (
                    <CypherCard
                        key={cypher.id}
                        cypher={cypher}
                        type={InventoryItemType.CYPHER}
                        isGameMaster={isGameMaster}
                        changeCypher={changeInventory}
                        removeCypher={openDialog}
                    />
                ))}
            </AccordionPanel>
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
            <CypherDataModal
                title="Add Cypher"
                type={InventoryItemType.CYPHER}
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                onSubmit={(data) => {
                    addInventory(data as APICreateInventory);
                    setIsModalOpen(false);
                }}
            />
        </AccordionItem>
    );
}

// ===== Artifacts =====

interface IArtifactsPanelProps {
    isGameMaster: boolean;
    activeCharacter: APICharacter | null;
}

function ArtifactsPanel(props: IArtifactsPanelProps) {
    const theme = useTheme();
    const { isGameMaster, activeCharacter } = props;
    const { artifacts, removeInventory, changeInventory, addInventory } = useInventory(activeCharacter?.id);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { openDialog, dialogProps } = useConfirmDialog(removeInventory);

    return (
        <AccordionItem>
            <h2>
                <AccordionButton fontSize="40px">
                    <Flex direction="row" gap="16px" flexGrow={1} alignItems="center">
                        <Box as="span" flex="1" textAlign="left" textDecoration="underline" fontSize="40px">
                            Artifacts
                        </Box>
                        {!isGameMaster && (
                            <Flex onClick={(evt) => evt.stopPropagation()}>
                                <Button
                                    size="md"
                                    colorScheme={theme.colours.buttonScheme}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    New
                                </Button>
                            </Flex>
                        )}
                        <AccordionIcon />
                    </Flex>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                {artifacts.map((artifact) => (
                    <CypherCard
                        key={artifact.id}
                        cypher={artifact}
                        type={InventoryItemType.ARTIFACT}
                        isGameMaster={isGameMaster}
                        changeCypher={changeInventory}
                        removeCypher={openDialog}
                    />
                ))}
            </AccordionPanel>
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
            <CypherDataModal
                title="Add Artifact"
                type={InventoryItemType.ARTIFACT}
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                onSubmit={(data) => {
                    addInventory(data as APICreateInventory);
                    setIsModalOpen(false);
                }}
            />
        </AccordionItem>
    );
}

// ===== Abilities =====

interface IAbilitiesPanelProps {
    isGameMaster: boolean;
    activeCharacter: APICharacter | null;
}

function AbilitiesPanel(props: IAbilitiesPanelProps) {
    const theme = useTheme();
    const { isGameMaster, activeCharacter } = props;
    const { runAddAbility, runEditAbility, deleteAbility, ...otherProps } = useAbilityModal(activeCharacter);
    return (
        <AccordionItem>
            <h2>
                <AccordionButton fontSize="40px">
                    <Flex direction="row" gap="16px" flexGrow={1} alignItems="center">
                        <Box as="span" flex="1" textAlign="left" textDecoration="underline" fontSize="40px">
                            Abilities
                        </Box>
                        {!isGameMaster && (
                            <Flex onClick={(evt) => evt.stopPropagation()}>
                                <Button size="md" colorScheme={theme.colours.buttonScheme} onClick={runAddAbility}>
                                    New
                                </Button>
                            </Flex>
                        )}
                        <AccordionIcon />
                    </Flex>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                {activeCharacter?.abilities.map((val, i) => (
                    <AbilityCard
                        key={i}
                        ability={val}
                        isGameMaster={isGameMaster}
                        editAbility={runEditAbility}
                        deleteAbility={deleteAbility}
                    />
                ))}
            </AccordionPanel>
            <AbilityModal {...otherProps} />
        </AccordionItem>
    );
}

// ===== Skills =====

interface ISkillsPanelProps {
    isGameMaster: boolean;
    activeCharacter: APICharacter | null;
}

function SkillsPanel(props: ISkillsPanelProps) {
    const theme = useTheme();
    const { isGameMaster, activeCharacter } = props;
    const { removeSkill, loadCharacters } = useSkills(activeCharacter?.id);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    return (
        <AccordionItem>
            <h2>
                <AccordionButton fontSize="40px">
                    <Flex direction="row" gap="16px" flexGrow={1} alignItems="center">
                        <Box as="span" flex="1" textAlign="left" textDecoration="underline" fontSize="40px">
                            Skills
                        </Box>
                        {!isGameMaster && (
                            <Flex onClick={(evt) => evt.stopPropagation()}>
                                <Button
                                    size="md"
                                    colorScheme={theme.colours.buttonScheme}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    New
                                </Button>
                            </Flex>
                        )}
                        <AccordionIcon />
                    </Flex>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                {activeCharacter?.skills?.map((skill: APICharacterSkill, index: number) => (
                    <SkillLine
                        key={`${skill.name}-${index}`}
                        character={activeCharacter}
                        skill={skill}
                        removeSkill={removeSkill}
                    />
                ))}
            </AccordionPanel>
            <AddSkillModal
                show={isModalOpen}
                character={activeCharacter as any}
                onHide={() => {
                    setIsModalOpen(false);
                    loadCharacters();
                }}
            />
        </AccordionItem>
    );
}

// ===== Root Component =====

export function SessionCharacter() {
    const theme = useTheme();
    const { activeCharacter, isGameMaster } = useActiveCharacter();

    return (
        <S.CharacterRoot>
            <Accordion defaultIndex={[]} allowMultiple color={theme.colours.body}>
                <CyphersPanel isGameMaster={isGameMaster} activeCharacter={activeCharacter} />
                <ArtifactsPanel isGameMaster={isGameMaster} activeCharacter={activeCharacter} />
                <AbilitiesPanel isGameMaster={isGameMaster} activeCharacter={activeCharacter} />
                <SkillsPanel isGameMaster={isGameMaster} activeCharacter={activeCharacter} />
            </Accordion>
        </S.CharacterRoot>
    );
}
