import React, { FC } from 'react';
import CashmereRadioButton from '../../../../components/Cashmere/CashmereRadio/CashmereRadioButton';
import { APICharacter, APICharacterAbility, APICharacterSkill } from '../../../../types';
import AddSkillModal from './AddSkillModal';
import * as S from './SkillsAndAbilitiesPanel.style';
import Markdown from 'react-markdown';

import { ReactComponent as CrossIcon } from '../../../../icons/svgs/SoftCross.svg';
import { ReactComponent as CheckIcon } from '../../../../icons/svgs/SoftCheck.svg';
import { apis } from '../../../../network';

import { useTheme } from 'styled-components';
import { Button, Flex, useDisclosure, Box, IconButton } from '@chakra-ui/react';
import { useCharacters } from '../../../../hooks/dataHooks';
import { EditIcon, DeleteIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../../../components/ConfirmDialog';
import { Menu, MenuItem } from '../../../../components/Menu';
import { DataModal, DataModalInput, DataModalTextArea } from '../../../../components/DataModal';
import { MarkdownText } from '../../../../components/MarkdownText/MarkdownText';

export interface SkillsAndAbilitiesPanelProps {
    character: APICharacter;
    loadCharacters: () => void;
}

export interface SkillLineProps {
    character: APICharacter;
    skill: APICharacterSkill;
    removeSkill: (skill: APICharacterSkill) => void;
}

export const SkillLine: FC<SkillLineProps> = ({ character, skill, removeSkill }) => {
    const [currentLevel, setCurrentLevel] = React.useState(skill.level);
    const { openDialog, dialogProps } = useConfirmDialog(removeSkill);

    const updateSkill = React.useCallback(async () => {
        const updatedSkills = character.skills.map((indexSkill: APICharacterSkill) => {
            var newSkill = { ...indexSkill };
            if (indexSkill.id === skill.id) {
                newSkill.level = currentLevel;
            }
            return newSkill;
        });

        const updatedChar = { skills: updatedSkills };

        apis.character.updateCharacter(character.id, updatedChar);
    }, [currentLevel]);

    React.useEffect(() => {
        updateSkill();
    }, [updateSkill]);

    return (
        <S.SkillLineWrapper>
            <S.SkillDescription>{skill.name}</S.SkillDescription>
            <S.SkillIndicators>
                <S.RadioWrapper>
                    <CashmereRadioButton
                        size="lg"
                        checked={currentLevel === 'Inability'}
                        onClick={() => setCurrentLevel('Inability')}
                        icon={<CrossIcon />}
                        checkedColor="#E14414"
                    />
                </S.RadioWrapper>
                <S.RadioWrapper>
                    <CashmereRadioButton
                        size="lg"
                        checked={currentLevel === 'Trained'}
                        onClick={() => setCurrentLevel('Trained')}
                        icon={<CheckIcon />}
                    />
                </S.RadioWrapper>
                <S.RadioWrapper>
                    <CashmereRadioButton
                        size="lg"
                        checked={currentLevel === 'Skilled'}
                        onClick={() => setCurrentLevel('Skilled')}
                        icon={<CheckIcon />}
                    />
                </S.RadioWrapper>
            </S.SkillIndicators>
            <IconButton
                size="sm"
                variant="ghost"
                icon={<SmallCloseIcon />}
                aria-label="close"
                onClick={() => openDialog(skill)}
            />
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
        </S.SkillLineWrapper>
    );
};

// ===== Ability Modal =====

enum ActionType {
    ADD = 'Add',
    EDIT = 'Edit'
}

export function useAbilityModal(character?: APICharacter | null) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { loadCharacters } = useCharacters();
    let [actionType, setActionType] = React.useState(ActionType.ADD);
    let [titleValue, setTitleValue] = React.useState('');
    let [abilityID, setabilityID] = React.useState('');
    let [descriptionValue, setDescValue] = React.useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        setTitleValue(inputValue);
    };

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputValue = e.target.value;
        setDescValue(inputValue);
    };

    const handleSave = async () => {
        onClose();
        if (titleValue && actionType == ActionType.ADD) {
            const newAbilites = [
                ...(character?.abilities ?? []),
                {
                    name: titleValue,
                    description: descriptionValue
                }
            ];
            await apis.character.updateCharacter(character?.id ?? '', { abilities: newAbilites });
        } else if (titleValue && actionType == ActionType.EDIT) {
            const currentAbilites = character?.abilities?.filter((val) => val.id != abilityID);
            const newAbilites = [
                ...(currentAbilites ?? []),
                {
                    id: abilityID,
                    name: titleValue,
                    description: descriptionValue
                }
            ];
            await apis.character.updateCharacter(character?.id ?? '', { abilities: newAbilites });
        }
        setTitleValue('');
        setDescValue('');
        await loadCharacters();
    };

    const handleCancel = async () => {
        onClose();
        setTitleValue('');
        setDescValue('');
    };

    const runAddAbility = async () => {
        setActionType(ActionType.ADD);
        onOpen();
    };

    const runEditAbility = async (ability: APICharacterAbility) => {
        setActionType(ActionType.EDIT);
        setTitleValue(ability.name);
        setDescValue(ability.description ?? '');
        setabilityID(ability.id);
        onOpen();
    };

    const deleteAbility = async (abilityID: string) => {
        const currentAbilites = character?.abilities?.filter((val) => val.id != abilityID);
        await apis.character.updateCharacter(character?.id ?? '', { abilities: currentAbilites });
        loadCharacters();
    };

    return {
        isOpen,
        actionType,
        titleValue,
        handleInputChange,
        descriptionValue,
        handleTextAreaChange,
        handleCancel,
        handleSave,
        runAddAbility,
        runEditAbility,
        deleteAbility
    };
}

interface IFunctionAbilityModalProps {
    isOpen: boolean;
    actionType: ActionType;
    titleValue: string;
    descriptionValue?: string;
    handleInputChange: (e: any) => void;
    handleTextAreaChange: (e: any) => void;
    handleCancel: () => void;
    handleSave: () => void;
}

export function AbilityModal(props: IFunctionAbilityModalProps) {
    const {
        isOpen,
        actionType,
        titleValue,
        handleInputChange,
        descriptionValue,
        handleTextAreaChange,
        handleCancel,
        handleSave
    } = props;
    return (
        <DataModal title={`${actionType} Ability`} isOpen={isOpen} onClose={handleCancel} onAction={handleSave}>
            <DataModalInput title="Title" value={titleValue} onChange={handleInputChange} placeholder="(required)" />
            <DataModalTextArea title="Description" value={descriptionValue} onChange={handleTextAreaChange} />
        </DataModal>
    );
}

// ===== Ability Panel =====

interface IAbilitiesPanelProps {
    character: APICharacter;
}

function AbilitiesPanel(props: IAbilitiesPanelProps) {
    const theme = useTheme();
    const { runAddAbility, runEditAbility, deleteAbility, ...otherProps } = useAbilityModal(props.character);

    return (
        <Flex color={theme.colours.body} flexGrow={1} flexBasis={0} direction="column" gap="8px">
            <Flex justifyContent="space-between" direction="row" borderRadius="8px" padding="8px" alignItems="center">
                <Flex fontSize="40px" fontWeight="500">
                    Abilities
                </Flex>
                <Button size="sm" colorScheme={theme.colours.buttonScheme} height="50px" onClick={runAddAbility}>
                    Add
                </Button>
            </Flex>
            <Box height="4px" bg={theme.colours.body} borderRadius="8px" />
            <Flex direction="column" gap="8px" paddingTop="8px" flexGrow={1} overflowY="auto">
                {props.character.abilities.map((val, i) => (
                    <AbilityCard key={i} ability={val} editAbility={runEditAbility} deleteAbility={deleteAbility} />
                ))}
                {props.character.abilities.length == 0 && (
                    <Flex flexGrow={1} alignItems="center" justifyContent="center">
                        <Flex>You have no abilities...</Flex>
                    </Flex>
                )}
            </Flex>
            <AbilityModal {...otherProps} />
        </Flex>
    );
}

// ===== AbilityCard =====

interface IAbilityCardProps {
    isGameMaster?: boolean;
    ability: APICharacterAbility;
    editAbility: (ability: APICharacterAbility) => void;
    deleteAbility: (abilityName: string) => void;
}

export function AbilityCard(props: IAbilityCardProps) {
    const theme = useTheme();
    const { ability, deleteAbility, editAbility, isGameMaster = false } = props;
    const { openDialog, dialogProps } = useConfirmDialog(deleteAbility);

    return (
        <Flex
            bg={theme.colours.card}
            borderRadius="8px"
            padding="8px"
            direction="column"
            _hover={{ bg: theme.colours.background }}
        >
            <Flex direction="row" justifyContent="space-between">
                <Flex fontSize="30px">{ability.name}</Flex>
                {!isGameMaster && (
                    <Menu>
                        <MenuItem icon={<EditIcon />} onClick={() => editAbility(ability)}>
                            Edit
                        </MenuItem>
                        <MenuItem icon={<DeleteIcon />} onClick={() => openDialog(ability.id)}>
                            Delete
                        </MenuItem>
                    </Menu>
                )}
            </Flex>
            <Flex whiteSpace="preserve">
                <MarkdownText value={ability.description} />
            </Flex>
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
        </Flex>
    );
}

// ===== Skills Panel =====

interface ISkillsPanelProps {
    character: APICharacter;
    loadCharacters: () => void;
}

function SkillsPanel(props: ISkillsPanelProps) {
    const theme = useTheme();
    const { character, loadCharacters } = props;
    const [addSkillModalShow, setAddSkillModalShow] = React.useState(false);

    const removeSkill = async (skill: APICharacterSkill) => {
        var newSkills = character?.skills.filter((val: APICharacterSkill) => val.id !== skill.id);
        const updatedChar = { skills: newSkills };
        await apis.character.updateCharacter(character.id, updatedChar);
        loadCharacters();
    };

    return (
        <Flex color={theme.colours.body} flexGrow={1} flexBasis={0} direction="column" gap="8px">
            <Flex justifyContent="space-between" direction="row" borderRadius="8px" padding="8px" alignItems="center">
                <Flex fontSize="40px" fontWeight="500">
                    Skills
                </Flex>
                <Button
                    size="sm"
                    height="50px"
                    colorScheme={theme.colours.buttonScheme}
                    onClick={() => setAddSkillModalShow(true)}
                >
                    Add
                </Button>
            </Flex>
            <Box height="4px" bg={theme.colours.body} borderRadius="8px" />
            <Flex direction="column" gap="8px" paddingTop="8px" flexGrow={1} overflowY="auto">
                <S.SkillsTableWrapper>
                    <S.SkillsHeaders>
                        <S.SkillsHeadersTitle>Skill</S.SkillsHeadersTitle>
                        <S.SkillsHeaderSelectors>
                            <S.SkillLevel>Inability</S.SkillLevel>
                            <S.SkillLevel>Trained</S.SkillLevel>
                            <S.SkillLevel>Skilled</S.SkillLevel>
                        </S.SkillsHeaderSelectors>
                        <IconButton
                            size="xs"
                            variant="ghost"
                            icon={<SmallCloseIcon />}
                            aria-label="close"
                            disabled
                            visibility="hidden"
                        />
                    </S.SkillsHeaders>
                    {character?.skills?.map((skill: APICharacterSkill, index: number) => (
                        <SkillLine
                            key={`${skill.id}-${index}`}
                            character={character}
                            skill={skill}
                            removeSkill={removeSkill}
                        />
                    ))}
                </S.SkillsTableWrapper>
            </Flex>
            <AddSkillModal
                show={addSkillModalShow}
                character={character}
                onHide={() => {
                    setAddSkillModalShow(false);
                    loadCharacters();
                }}
            />
        </Flex>
    );
}

// ===== Root =====

const SkillsAndAbilitiesPanel: FC<SkillsAndAbilitiesPanelProps> = ({ character, loadCharacters }) => {
    return (
        <S.PanelWrapper>
            <SkillsPanel character={character} loadCharacters={loadCharacters} />
            <AbilitiesPanel character={character} />
        </S.PanelWrapper>
    );
};

export default SkillsAndAbilitiesPanel;
