import styled from 'styled-components';

export const Root = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

export const Heading = styled.div`
    font-size: 54px;
    ${(p) => p.theme.text.Text.Roman55}
    color: ${(p) => p.theme.colours.heading};
`;

export const SubHeading = styled.div`
    font-size: 34px;
    ${(p) => p.theme.text.Text.Roman55}
    color: ${(p) => p.theme.colours.subHeadingAlt};
`;
