import React, { FC } from 'react';
import { APICharacter } from '../../../../types';
import CashmereButton from '../../../../components/Cashmere/CashmereButton/CashmereButton';

import * as S from './OverviewPanel.style';
import CircularMeter from '../../../../components/CircularMeter/CircularMeter';
import EditCharacterModal from './EditCharacterModal';
import { DamageBar } from '../../../../components/DamageBar/DamageBar';

export interface IOverviewPanelProps {
    character: APICharacter;
}

const OverviewPanel: FC<IOverviewPanelProps> = ({ character }) => {
    const [editMode, setEditMode] = React.useState(false);

    return (
        <>
            <S.OverviewPanelWrapper>
                <S.ControlBar>
                    <CashmereButton text="Edit" onClick={() => setEditMode(true)} />
                </S.ControlBar>
                <S.OverviewPanelContainer>
                    <S.LargePanel>
                        <S.KeyPanelWrapper>
                            <CircularMeter
                                totalValue={character?.stats?.might?.max}
                                remainingValue={character?.stats?.might?.current}
                                statTitle={'Might'}
                                edgeValue={character?.stats?.might?.edge}
                            />
                            <CircularMeter
                                totalValue={character?.stats?.speed?.max}
                                remainingValue={character?.stats?.speed?.current}
                                statTitle={'Speed'}
                                edgeValue={character?.stats?.speed?.edge}
                            />
                            <CircularMeter
                                totalValue={character?.stats?.intellect?.max}
                                remainingValue={character?.stats?.intellect?.current}
                                statTitle={'Intelect'}
                                edgeValue={character?.stats?.intellect?.edge}
                            />
                        </S.KeyPanelWrapper>
                    </S.LargePanel>
                    <S.SmallPanel>
                        <S.SmallPannelInner>
                            <S.StatLine>
                                <S.StatKey>Tier</S.StatKey>
                                <S.StatValue>{character?.metadata?.tier}</S.StatValue>
                            </S.StatLine>
                            <S.StatLine>
                                <S.StatKey>Effort</S.StatKey>
                                <S.StatValue>{character?.metadata?.effort}</S.StatValue>
                            </S.StatLine>
                            <S.StatLine>
                                <S.StatKey>XP</S.StatKey>
                                <S.StatValue>{character?.metadata?.experience}</S.StatValue>
                            </S.StatLine>
                        </S.SmallPannelInner>
                    </S.SmallPanel>
                    <S.SmallPanel>
                        <S.SmallPannelInner>
                            <S.StatLine>
                                <S.StatKey>Armour</S.StatKey>
                                <S.StatValue>{character?.stats?.armour}</S.StatValue>
                            </S.StatLine>
                            <DamageBar />
                        </S.SmallPannelInner>
                    </S.SmallPanel>
                    <S.LargePanel></S.LargePanel>
                </S.OverviewPanelContainer>
            </S.OverviewPanelWrapper>
            <EditCharacterModal show={editMode} character={character} onHide={() => setEditMode(false)} />
        </>
    );
};

export default OverviewPanel;
