import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserAuthData } from '../../types';

export interface AuthSliceState {
    isLoading: boolean;
    authenticatedUser: UserAuthData | null;
}

const initialAuthState: AuthSliceState = {
    authenticatedUser: null,
    isLoading: false
};

export const auth = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setUser: (state, action: PayloadAction<UserAuthData>) => {
            state.authenticatedUser = action.payload;
        },
        logout: (state) => {
            state.authenticatedUser = null;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
});
