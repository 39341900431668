import styled from 'styled-components';
import { TextStyles } from '../../../theme/fonts';

export const ModalBaseContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(100, 100, 100, 0.5);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const ModalWrapper = styled.div`
    background: white;
    border-radius: 15px;
`;

export const ModalHeader = styled.div`
    ${TextStyles.Display.Medium65}
    font-size: 24px;
    margin: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > svg {
        width: 22px;
        &:hover {
            cursor: pointer;
        }
    }
`;

export const ModalBody = styled.div`
    ${TextStyles.Text.Roman55}
    font-size: 16px;
    margin: 40px;
`;

export const ModalFooter = styled.div`
    ${TextStyles.Display.Light45}
    margin: 40px;
    display: flex;
    justify-content: flex-end;
`;

export const FormFields = styled.div``;

export const FormField = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0px;
`;

export const Label = styled.label`
    ${TextStyles.Text.Bold75};
    font-size: 16px;
`;

export const Input = styled.input`
    ${TextStyles.Text.Medium65};
    font-size: 16px;
    padding: 8px;
    background: #f1f1f1;
    border: none;
    outline: none;
`;

export const SubmitButton = styled.input`
    background: #171616;
    color: white;
    padding: 16px 32px;
    border-radius: 5px;
    border: none;
    ${TextStyles.Display.Medium65};
    font-size: 18px;
    cursor: pointer;
    user-select: none;
`;

