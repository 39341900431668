import React from 'react';

import * as S from './AuthenticatingPage.style';

export function AuthenticatingPage() {
    return (
        <S.PageRoot>
            <S.Text>Authenticating...</S.Text>
        </S.PageRoot>
    );
}
