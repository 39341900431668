import React, { FC, ReactElement } from 'react';

import * as S from './CashmereButton.style'

export interface ICashmereButtonProps {
    text: string;
    onClick: () => void;
    icon?: ReactElement;
    iconPosition?: 'leading' | 'trailing'
    disabled?: boolean;
    size?: 'sm' | 'md' | 'lg';
    style?: 'textOnly' | 'outlined' | 'solid';
}

export const CashmereButton: FC<ICashmereButtonProps> = (
    {
        text, 
        onClick, 
        icon, 
        iconPosition = 'trailing', 
        disabled = false, 
        size = 'md',
        style = 'solid',
    }) => {

    return(

        <S.ButtonWrapper onClick={(e) => {
            e.stopPropagation();
            onClick();
        }} size={size} disabled={disabled}>
            {iconPosition === 'leading' && <S.ButtonIcon size={size} disabled={disabled}>{icon}</S.ButtonIcon>}
            <S.ButtonText>{text}</S.ButtonText>
            {iconPosition === 'trailing' && <S.ButtonIcon size={size} disabled={disabled}>{icon}</S.ButtonIcon>}
        </S.ButtonWrapper>

    )
}

export default CashmereButton;