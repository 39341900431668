import { css, FlattenInterpolation } from 'styled-components';
import { ColourPrefereneceType, ThemeType } from '../types';
import { TextStyles } from './fonts';
import { theme as chakraTheme, Theme as ChakraTheme } from '@chakra-ui/theme';

// ===== Theme Types =====
interface PreferenceColours {
    button: string;
    buttonHover: string;
    primaryS2: string;
    primaryS1: string;
    primary: string;
    primaryT1: string;
    primaryT2: string;
    buttonScheme: string;
}

interface ThemeColours extends PreferenceColours {
    background: string;
    card: string;
    heading: string;
    subHeading: string;
    subHeadingAlt: string;
    body: string;
    buttonDisabled: string;
    buttonTextFull: string;
    buttonTextOutline: string;
    buttonTextDisabled: string;
    confirm: string;
    error: string;
    unchecked: string;
    disabledSecondary: string;
}

enum ClothrockClassnames {
    SCROLLBAR = 'SCROLLBAR',
    BUTTON = 'BUTTON'
}

interface ThemeBase {
    text: typeof TextStyles;
    classes: Record<ClothrockClassnames, string>;
    scrollbar: FlattenInterpolation<any>;
    button: FlattenInterpolation<any>;
    chakra: ChakraTheme;
}

export interface AppTheme extends ThemeBase {
    colours: ThemeColours;
}

// ===== Preference Partials =====

export const ocean: PreferenceColours = {
    button: '#007aff',
    buttonHover: '#68adfe',
    primaryS2: '#005cbf',
    primaryS1: '#007aff',
    primary: '#3395ff',
    primaryT1: '#66afff',
    primaryT2: '#a3cfff',
    buttonScheme: 'twitter'
};

export const forest: PreferenceColours = {
    button: '#b2cc3e',
    buttonHover: '#d1e08b',
    primaryS2: '#59661f',
    primaryS1: '#85992f',
    primary: '#b2cc3e',
    primaryT1: '#d1e08b',
    primaryT2: '#e0ebb2',
    buttonScheme: 'green'
};

export const royal: PreferenceColours = {
    button: '#802dc9',
    buttonHover: '#b381df',
    primaryS2: '#401765',
    primaryS1: '#602297',
    primary: '#805ad5',
    primaryT1: '#b381df',
    primaryT2: '#ccabe9',
    buttonScheme: 'purple'
};

export const fire: PreferenceColours = {
    button: '#ff6600',
    buttonHover: '#ffa366',
    primaryS2: '#803300',
    primaryS1: '#bf4d00',
    primary: '#ff6600',
    primaryT1: '#ffa366',
    primaryT2: '#ffc299',
    buttonScheme: 'orange'
};

// ===== Theme Partials =====

const lightThemeColours: ThemeColours = {
    ...ocean,
    background: '#f4f4f4',
    card: '#ffffff',
    heading: '#161616',
    subHeading: '#161616',
    subHeadingAlt: '#999999',
    body: '#161616',
    buttonDisabled: '#cccccc',
    buttonTextFull: '#f4f4f4',
    buttonTextOutline: '#161616',
    buttonTextDisabled: '#cccccc',
    confirm: '#b2cc3e',
    error: '#e14414',
    unchecked: '#cccccc',
    disabledSecondary: '#666666'
};

const darkThemeColours: ThemeColours = {
    ...ocean,
    background: '#161616',
    card: '#070707',
    heading: '#f4f4f4',
    subHeading: '#f4f4f4',
    subHeadingAlt: '#999999',
    body: '#f4f4f4',
    buttonDisabled: '#666666',
    buttonTextFull: '#f4f4f4',
    buttonTextOutline: '#f4f4f4',
    buttonTextDisabled: '#666666',
    confirm: '#b2cc3e',
    error: '#e14414',
    unchecked: '#cccccc',
    disabledSecondary: '#4d4d4d'
};

const scrollBarStyle = css`
    /* width */
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 4px;
        background: grey;
    }
`;

const buttonStyles = css`
    padding: 8px;
    display: flex;
    height: 50px;
    color: white;
    border: none;
    font-size: 20px;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    ${(p) => p.theme.text.Display.Medium65};
    background-color: ${(props) => props.theme.colours.button};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.colours.buttonHover};
    }

    &:active {
        transform: translateY(2px);
    }
`;

const classes: Record<ClothrockClassnames, string> = {
    [ClothrockClassnames.SCROLLBAR]: 'clothrock-scrollbar',
    [ClothrockClassnames.BUTTON]: 'clothrock-button'
};

const themeBase: ThemeBase = {
    classes,
    text: TextStyles,
    chakra: chakraTheme,
    button: buttonStyles,
    scrollbar: scrollBarStyle
};

// ===== Compose Theme =====

const THEME_MAPPING = {
    [ThemeType.LIGHT]: lightThemeColours,
    [ThemeType.DARK]: darkThemeColours
};

const COL_PREF_MAPPING = {
    [ColourPrefereneceType.OCEAN]: ocean,
    [ColourPrefereneceType.FOREST]: forest,
    [ColourPrefereneceType.ROYAL]: royal,
    [ColourPrefereneceType.FIRE]: fire
};

export function composeTheme(themeType: ThemeType, colourPreferenece: ColourPrefereneceType): AppTheme {
    return { ...themeBase, colours: { ...THEME_MAPPING[themeType], ...COL_PREF_MAPPING[colourPreferenece] } };
}
