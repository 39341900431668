import React from 'react';

import { APIUserResource } from '../../../types';
import { CashmereSpinner } from '../../../components/Cashmere/CashmereSpinner';
import { useDragAndDrop, useScrollToRef, useWindowDimensions } from '../../../hooks/utilHooks';
import { useResourceEvents, useSessionResources } from '../../../hooks/dataHooks';

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, useDisclosure, Image, Flex } from '@chakra-ui/react';

import * as S from './SessionResources.style';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../../components/ConfirmDialog';
import { useTheme } from 'styled-components';

interface IResourceContainerProps {
    discordID: string;
    profileID: string;
    isGameMaster: boolean;
    resource: APIUserResource;
    onRemove: (resourceID: string) => void;
    onPreview: (resourceID: string) => void;
}

function ResourceContainer(props: IResourceContainerProps) {
    const { resource, onRemove, discordID, profileID, isGameMaster, onPreview } = props;
    const is_adam = discordID.startsWith('152599');
    return (
        <S.ResourceContainerRoot>
            <S.ResourceImage src={resource.resource_url} onClick={() => onPreview(resource.id)} />
            {(profileID === resource.profile_id || is_adam || isGameMaster) && (
                <S.RemoveButton
                    onClick={(evt) => {
                        evt.stopPropagation();
                        onRemove(resource.id);
                    }}
                >
                    X
                </S.RemoveButton>
            )}
        </S.ResourceContainerRoot>
    );
}

interface IResourcePreviewProps {
    isOpen: boolean;
    onClose: () => void;
    resource: APIUserResource;
}

function ResourcePreview(props: IResourcePreviewProps) {
    const theme = useTheme();
    const { isOpen, onClose, resource } = props;
    const { height, width } = useWindowDimensions();
    const [dimensions, setDimensions] = React.useState({ height: 0, width: 0 });

    const test = ({ target: img }: any) => {
        setDimensions({ height: img.naturalHeight, width: img.naturalWidth });
    };

    let useWidth = 'auto';
    let useHeight = '80vh';
    // Yucky hack to determine if should switch between set width or height.
    if (height - dimensions.height > width - dimensions.width) {
        useWidth = '80vw';
        useHeight = 'auto';
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent
                overflow="hidden"
                padding="8px"
                maxWidth="none"
                width="none"
                backgroundColor={theme.colours.background}
            >
                <ModalCloseButton />
                <Flex flexGrow={1} justifyContent="center">
                    <Flex>
                        <Image
                            width={useWidth}
                            height={useHeight}
                            borderRadius="8px"
                            alt="resource preview"
                            onLoad={test}
                            src={resource?.resource_url ?? null}
                        />
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    );
}

interface ISessionResourcesProps {
    isGameMaster: boolean;
    loadSessionData: () => void;
}

export function SessionResources(props: ISessionResourcesProps) {
    const { isGameMaster, loadSessionData } = props;
    const { scrollRef, scrollToElement } = useScrollToRef();
    const { latestEventID, resourceEvents, loadResourceEvents, activeUser } = useResourceEvents();
    const { activeResources, setSelectedFile, loadSessionResources, deleteSessionResource, isUploading } =
        useSessionResources();
    const { isDragging, dragProps } = useDragAndDrop(setSelectedFile);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [previewID, setPreviewID] = React.useState('');

    const { openDialog, dialogProps } = useConfirmDialog(async (val) => {
        await deleteSessionResource(val);
        await Promise.all([loadResourceEvents(), loadSessionData()]);
    });

    React.useEffect(() => {
        // If our local events are out of sync with the server events, load the latest data.
        if (latestEventID !== resourceEvents?.[0]?.id ?? '') {
            loadSessionResources();
            loadResourceEvents();
        }
    }, [latestEventID]);

    React.useEffect(() => {
        // If we recently finished uploading, load the latest resources and resource events.
        if (!isUploading) {
            loadResourceEvents();
            loadSessionData();
        }
    }, [isUploading]);

    React.useEffect(() => {
        // Whenever there is a change to the active resources, scroll to the bottom of the list.
        scrollToElement();
    }, [activeResources]);

    const onPreview = (resourceID: string) => {
        setPreviewID(resourceID);
        onOpen();
    };

    return (
        <S.ResourcesRoot>
            <S.ResourcesContent {...dragProps} isDragging={isDragging}>
                <div ref={scrollRef} />
                {activeUser &&
                    (activeResources ?? []).map((res) => (
                        <ResourceContainer
                            key={res.id}
                            resource={res}
                            profileID={activeUser.profile_id}
                            discordID={activeUser.discordUserID}
                            onPreview={onPreview}
                            onRemove={openDialog}
                            isGameMaster={isGameMaster}
                        />
                    ))}
            </S.ResourcesContent>
            <S.ResourceUploadContainer>
                <S.UploadButton disabled={isUploading}>
                    {isUploading ? <CashmereSpinner /> : 'Upload Resource'}
                    <input
                        type="file"
                        name="file"
                        accept="image/*"
                        disabled={isUploading}
                        style={{ display: 'none' }}
                        onChange={(event) => setSelectedFile(event?.target?.files?.[0] ?? null)}
                    />
                </S.UploadButton>
            </S.ResourceUploadContainer>
            <ResourcePreview
                isOpen={isOpen}
                onClose={onClose}
                resource={activeResources.filter((res) => res.id === previewID)?.[0]}
            />
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
        </S.ResourcesRoot>
    );
}
