import styled from 'styled-components';

export const ResultsRoot = styled.div`
    gap: 16px;
    flex-grow: 1;
    padding: 16px;
    display: flex;
    font-size: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column-reverse;
    ${(p) => p.theme.text.Text.Roman55}
    ${(p) => p.theme.scrollbar}
`;

export const RollResultRoot = styled.div`
    gap: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const RollResultContent = styled.div`
    gap: 16px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    color: ${(p) => p.theme.colours.subHeading};
`;

export const RollResultPanel = styled.div`
    gap: 8px;
    display: flex;
    flex-basis: 25%;
    align-items: center;
    flex-direction: row;
`;

export const Spacer = styled.div`
    border-bottom: 1px solid #808080;
`;

export const RollTitle = styled.div`
    display: flex;
    flex-direction: row;
`;

export const RollTitleText = styled.div`
    font-size: 16px;
    flex-grow: 1;
    font-style: italic;
    color: ${(p) => p.theme.colours.subHeadingAlt};
`;

export const RollTimeText = styled.div`
    font-size: 12px;
    color: ${(p) => p.theme.colours.subHeadingAlt};
`;

export const NoDiceRolls = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
`;

export const RollFieldText = styled.div`
    font-size: 16px;
    color: ${(p) => p.theme.colours.subHeadingAlt};
`;

export const TotalRollText = styled.div`
    font-size: 20px;
    font-weight: 800;
    color: ${(p) => p.theme.colours.primary};
`;
