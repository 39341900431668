import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actions } from '../../state';
import * as S from './PlaySessions.style';
import { LogInLock } from '../../components/LogInLock';
import { usePlaySessions } from '../../hooks/dataHooks';
import { LoadingWrapper } from '../../components/LoadingWrapper';
import { CharacterSelectModal } from '../../components/Character/CharacterSelectModal';
import { Box, Button, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { APIPlaySession, APIUserCampaign } from '../../types';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';

import styled from 'styled-components';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../components/ConfirmDialog';
import { Menu, MenuItem } from '../../components/Menu';
import { DataModal, DataModalInput } from '../../components/DataModal';

const StyledStack = styled(Stack)`
    ${(p) => p.theme.scrollbar}
`;

// ===== PlaySessionCard =====

interface IPlaySessionCardProps {
    isGameMaster: boolean;
    playSession: APIPlaySession;
    activeCampaign: APIUserCampaign;
    removeSession: (sessionID: string) => void;
}

function PlaySessionCard(props: IPlaySessionCardProps) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { playSession, removeSession, isGameMaster, activeCampaign } = props;
    const { openDialog, dialogProps } = useConfirmDialog(removeSession);
    return (
        <Flex
            direction="column"
            bg={theme.colours.card}
            color={theme.colours.body}
            borderRadius="16px"
            padding="16px"
            gap="16px"
            fontSize="25px"
            cursor="pointer"
            onClick={() => navigate(`/campaigns/${activeCampaign.id}/sessions/${playSession.id}`)}
        >
            <Flex direction="row" justifyContent="space-between" alignItems="center">
                <Box fontSize="40px">{playSession.name}</Box>
                {isGameMaster && (
                    <Menu>
                        <MenuItem icon={<DeleteIcon />} onClick={() => openDialog(playSession.id)}>
                            Delete
                        </MenuItem>
                    </Menu>
                )}
            </Flex>
            <Box fontSize="20px">Created {format(new Date(playSession.created_at), 'dd/MM/yyyy')}</Box>
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
        </Flex>
    );
}

// ===== AddSessionModal =====

interface IAddSessionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onCreate: (sessionName: string) => void;
}

function AddSessionModal(props: IAddSessionModalProps) {
    const { isOpen, onClose, onCreate } = props;
    const [newSessionName, setNewSessionName] = React.useState('');

    React.useEffect(() => {
        setNewSessionName('');
    }, [isOpen]);

    return (
        <DataModal title="Add Session" isOpen={isOpen} onClose={onClose} onAction={() => onCreate(newSessionName)}>
            <DataModalInput
                value={newSessionName}
                placeholder="Enter session name..."
                onChange={(e) => setNewSessionName(e.currentTarget.value)}
            />
        </DataModal>
    );
}

// ===== PlaySessions =====

export function PlaySessions() {
    const theme = useTheme();
    const { activeCampaign, sessions, addSession, removeSession, dataLoading, activeCharacter, isGameMaster } =
        usePlaySessions();
    const dispatch = useDispatch();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCharselectOpen, setIsCharSelectOpen] = React.useState(false);

    const handleNewSession = (newSessionName: string) => {
        if (newSessionName) {
            addSession(newSessionName);
        }
        onClose();
    };

    React.useEffect(() => {
        dispatch(actions.events.clear());
    }, []);

    return (
        <LogInLock>
            <S.PageRoot>
                <LoadingWrapper isLoading={dataLoading}>
                    <Flex direction="column" flexGrow={1} maxWidth="1440px" width="100%" overflow="hidden">
                        <Flex direction="row" justifyContent="space-between" alignItems="center">
                            <S.TitleText>Play Sessions</S.TitleText>
                            {!isGameMaster ? (
                                <Button
                                    size="md"
                                    colorScheme={theme.colours.buttonScheme}
                                    onClick={() => setIsCharSelectOpen(true)}
                                >
                                    {activeCharacter ? `Playing as ${activeCharacter.name}` : 'No Character Selected'}
                                </Button>
                            ) : (
                                <Button size="md" colorScheme={theme.colours.buttonScheme} onClick={onOpen}>
                                    Add
                                </Button>
                            )}
                        </Flex>
                        <Box height="4px" borderRadius="8px" bg={theme.colours.body} />
                        <Flex direction="column" flexGrow={1} paddingTop="16px" overflow="hidden">
                            <StyledStack spacing="16px" direction="column" overflowY="auto">
                                {sessions.map((sesh, i) => (
                                    <PlaySessionCard
                                        key={sesh.id}
                                        playSession={sesh}
                                        isGameMaster={isGameMaster}
                                        removeSession={removeSession}
                                        activeCampaign={activeCampaign}
                                    />
                                ))}
                            </StyledStack>
                            {sessions.length === 0 && isGameMaster && (
                                <S.NoSessionsText>
                                    This campaign has no play sessions <br />
                                    Create a new play session with the 'Add' button.
                                </S.NoSessionsText>
                            )}
                            {sessions.length === 0 && !isGameMaster && (
                                <S.NoSessionsText>The game master has not created any play sessions</S.NoSessionsText>
                            )}
                        </Flex>
                        <CharacterSelectModal
                            isOpen={isCharselectOpen}
                            onClickAway={() => setIsCharSelectOpen(false)}
                        />
                        <AddSessionModal isOpen={isOpen} onClose={onClose} onCreate={handleNewSession} />
                    </Flex>
                </LoadingWrapper>
            </S.PageRoot>
        </LogInLock>
    );
}
