import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react';
import { Title } from 'chart.js';
import React from 'react';
import { useTheme } from 'styled-components';
import { InventoryDataModal } from '../../../../components/Character/InventoryDataModal';
import { ItemCard } from '../../../../components/Character/ItemCard';
import { AlertType, ConfirmDialog, useConfirmDialog } from '../../../../components/ConfirmDialog';
import { DataModal, DataModalInput } from '../../../../components/DataModal';
import { Menu, MenuItem } from '../../../../components/Menu';
import { useInventory } from '../../../../hooks/dataHooks';
import { APICreateInventory } from '../../../../types';

import * as S from './Inventory.style';

// ===== Group Modal =====

interface IGroupDataModalProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    initialValue?: string;
    handleSave: (title: string) => void;
}

export function GroupDataModal(props: IGroupDataModalProps) {
    const { title, isOpen, onClose, handleSave, initialValue } = props;
    const [newTitle, setNewTitle] = React.useState(initialValue ?? '');

    const handleAdd = () => {
        if (newTitle) {
            handleSave(newTitle);
            setNewTitle('');
            onClose();
        }
    };

    const handleCancel = () => {
        setNewTitle('');
        onClose();
    };

    React.useEffect(() => {
        setNewTitle(initialValue ?? '');
    }, [initialValue]);

    return (
        <DataModal title={title} isOpen={isOpen} onClose={handleCancel} onAction={handleAdd}>
            <DataModalInput
                title="Name"
                value={newTitle}
                onEnter={handleAdd}
                onChange={(e) => setNewTitle(e.currentTarget.value)}
            />
        </DataModal>
    );
}

// ===== Group Card =====

interface IGroupCardProps {
    title: string;
    isActive: boolean;
    groupID: string | null;
    deleteGroup: (groupID: string) => void;
    onClick: (groupID: string | null) => void;
    editGroup: (groupID: string, title: string) => void;
}

function GroupCard(props: IGroupCardProps) {
    const theme = useTheme();
    const { title, isActive, onClick, groupID, deleteGroup, editGroup } = props;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { openDialog, dialogProps } = useConfirmDialog(deleteGroup);
    return (
        <Flex
            bg={theme.colours.card}
            padding="16px"
            fontSize="30px"
            cursor="pointer"
            borderWidth="2px"
            borderRadius="8px"
            alignItems="center"
            boxSizing="border-box"
            justifyContent="space-between"
            onClick={() => onClick(groupID)}
            borderColor={isActive ? theme.colours.primary : theme.colours.body}
        >
            <Box>{title}</Box>
            {groupID && groupID.length > 0 && (
                <Menu>
                    <MenuItem icon={<EditIcon />} onClick={onOpen}>
                        Edit
                    </MenuItem>
                    <MenuItem icon={<DeleteIcon />} onClick={() => openDialog(groupID ?? '')}>
                        Delete
                    </MenuItem>
                </Menu>
            )}
            <GroupDataModal
                isOpen={isOpen}
                title="Edit Inventory Group"
                initialValue={title}
                onClose={onClose}
                handleSave={(newTitle) => editGroup(groupID ?? '', newTitle)}
            />
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
        </Flex>
    );
}

// ===== Inventory Page =====

export function Inventory() {
    const theme = useTheme();
    const [activeGroup, setActiveGroup] = React.useState<string | null>('');
    const { isOpen: gIsOpen, onClose, onOpen } = useDisclosure();
    const {
        inventory,
        addInventory,
        removeInventory,
        changeInventory,
        inventoryGroups,
        groupFilteredInventory,
        addInventoryGroup,
        removeInventoryGroup,
        changeInventoryGroup
    } = useInventory();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { openDialog, dialogProps } = useConfirmDialog(removeInventory);
    const items = activeGroup === '' ? inventory : groupFilteredInventory(activeGroup);
    return (
        <S.Root>
            <Flex direction="row" gap="16px" overflow="hidden" color={theme.colours.body} flexGrow={1}>
                <Flex direction="column" flexGrow={1} flexBasis={1} overflow="hidden">
                    <Flex alignItems="center" justifyContent="space-between" padding="4px">
                        <Box color={theme.colours.body} fontSize="40px">
                            Groups
                        </Box>
                        <Button colorScheme={theme.colours.buttonScheme} size="xs" onClick={onOpen}>
                            New
                        </Button>
                    </Flex>
                    <Box bg={theme.colours.body} height="2px" borderRadius="8px"></Box>
                    <Flex
                        gap="8px"
                        padding="8px"
                        overflowY="auto"
                        direction="column"
                        className={theme.classes.SCROLLBAR}
                    >
                        <GroupCard
                            title="All"
                            isActive={activeGroup === ''}
                            onClick={setActiveGroup}
                            groupID={''}
                            editGroup={changeInventoryGroup}
                            deleteGroup={removeInventoryGroup}
                        />
                        {inventoryGroups.map((grp) => (
                            <GroupCard
                                key={grp.id}
                                groupID={grp.id}
                                title={grp.title}
                                onClick={setActiveGroup}
                                isActive={grp.id === activeGroup}
                                editGroup={changeInventoryGroup}
                                deleteGroup={removeInventoryGroup}
                            />
                        ))}
                        <GroupCard
                            groupID={null}
                            title="Unassigned"
                            isActive={activeGroup === null}
                            onClick={setActiveGroup}
                            editGroup={changeInventoryGroup}
                            deleteGroup={removeInventoryGroup}
                        />
                    </Flex>
                </Flex>
                <Flex direction="column" flexGrow={2} overflow="hidden" flexBasis={1}>
                    <Flex alignItems="center" justifyContent="space-between" padding="4px">
                        <Box color={theme.colours.body} fontSize="40px">
                            Items
                        </Box>
                        <Button colorScheme={theme.colours.buttonScheme} size="xs" onClick={() => setIsModalOpen(true)}>
                            New
                        </Button>
                    </Flex>
                    <Box bg={theme.colours.body} minHeight="2px" borderRadius="8px"></Box>
                    <Flex
                        gap="4px"
                        flexGrow={1}
                        padding="8px"
                        overflowY="auto"
                        direction="column"
                        className={theme.classes.SCROLLBAR}
                    >
                        {items.map((inv) => (
                            <ItemCard
                                key={inv.id}
                                item={inv}
                                inventoryGroups={inventoryGroups}
                                onDelete={openDialog}
                                onChange={changeInventory}
                            />
                        ))}
                        {items.length === 0 && (
                            <Flex justifyContent="center" alignItems="center" flexGrow={1}>
                                <Box color={theme.colours.buttonDisabled}>This group contains no items</Box>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>
            <GroupDataModal
                title="Add Inventory Group"
                isOpen={gIsOpen}
                onClose={onClose}
                handleSave={addInventoryGroup}
            />
            <ConfirmDialog {...dialogProps} alertType={AlertType.DELETE} />
            <InventoryDataModal
                title="Add Inventory Item"
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                groupOptions={inventoryGroups.map((grp) => ({ value: grp.id, label: grp.title }))}
                onSubmit={(data) => {
                    addInventory(data as APICreateInventory);
                    setIsModalOpen(false);
                }}
            />
        </S.Root>
    );
}
