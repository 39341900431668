import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './CharacterCard.style';

export interface ICharacterCardProps {
    name: string;
    descriptor: string;
    type: string;
    focus: string;
    id: string;
    onRemove: (characterID: string) => void;
}

const CharacterCard: FC<ICharacterCardProps> = ({ name, descriptor, type, focus, id, onRemove }) => {
    const navigate = useNavigate();

    return (
        <>
            <S.CardWrapper onClick={() => navigate(`/character/${id}`)}>
                <S.RemoveButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemove(id);
                    }}
                />
                <S.CardImage />
                <S.CardDetails>
                    <S.CardTitle>{name}</S.CardTitle>
                    <S.CardSubTitle>{`A ${descriptor} ${type} who ${focus}`}</S.CardSubTitle>
                </S.CardDetails>
            </S.CardWrapper>
        </>
    );
};

export default CharacterCard;
