import styled from 'styled-components';

export const NotFoundRoot = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Text = styled.div`
    ${(p) => p.theme.text.Display.Roman55}
    color: ${(p) => p.theme.colours.heading};
    font-size: 50px;
`;
