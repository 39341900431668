import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '../../state';
import { ColourPrefereneceType } from '../../types';
import { CashmereRadioV2 } from '../Cashmere/CashmereRadioV2';
import { fire, forest, ocean, royal } from '../../theme';

import * as S from './ToggleColourPreference.style';

export function ToggleColourPreference() {
    const dispatch = useDispatch();
    const colourPref = useSelector(selectors.selectActiveColourPref);

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actions.preferences.setColourPref(evt.currentTarget.value as ColourPrefereneceType));
    };

    return (
        <S.Root>
            <CashmereRadioV2
                name="colourPref"
                checkColour="white"
                onChange={handleChange}
                backgroundColour={ocean.primary}
                value={ColourPrefereneceType.OCEAN}
                checked={colourPref === ColourPrefereneceType.OCEAN}
            />
            <CashmereRadioV2
                name="colourPref"
                checkColour="white"
                onChange={handleChange}
                backgroundColour={forest.primary}
                value={ColourPrefereneceType.FOREST}
                checked={colourPref === ColourPrefereneceType.FOREST}
            />
            <CashmereRadioV2
                name="colourPref"
                checkColour="white"
                onChange={handleChange}
                backgroundColour={royal.primary}
                value={ColourPrefereneceType.ROYAL}
                checked={colourPref === ColourPrefereneceType.ROYAL}
            />
            <CashmereRadioV2
                name="colourPref"
                checkColour="white"
                onChange={handleChange}
                backgroundColour={fire.primary}
                value={ColourPrefereneceType.FIRE}
                checked={colourPref === ColourPrefereneceType.FIRE}
            />
        </S.Root>
    );
}
