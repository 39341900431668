import React from 'react';

import { Button, useDisclosure, Textarea, TextareaProps } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ResizeTextarea from 'react-textarea-autosize';

import { useActiveCharacter } from '../../../../hooks/dataHooks';

import * as S from './BackgroundAndNotes.style';
import { DataModal, DataModalTextArea } from '../../../../components/DataModal';

const AutoResizeTextarea = React.forwardRef((props: TextareaProps, ref: any) => {
    return (
        <Textarea
            minH="unset"
            overflow="hidden"
            w="100%"
            resize="none"
            ref={ref}
            minRows={1}
            as={ResizeTextarea}
            {...props}
        />
    );
});

export function BackgroundAndNotes() {
    const theme = useTheme();
    const { characterID = '' } = useParams();
    const { updateCharacter, activeCharacter } = useActiveCharacter(characterID);
    const { isOpen, onOpen, onClose } = useDisclosure();
    let [value, setValue] = React.useState(activeCharacter?.metadata?.background ?? '');

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputValue = e.target.value;
        setValue(inputValue);
    };

    const handleSave = async () => {
        onClose();
        await updateCharacter({ metadata: { background: value } });
    };

    const handleCancel = async () => {
        onClose();
        setValue(activeCharacter?.metadata?.background ?? '');
    };

    React.useEffect(() => {
        setValue(activeCharacter?.metadata?.background ?? '');
    }, [activeCharacter?.metadata?.background]);

    return (
        <S.Root>
            <S.TitleBar>
                <S.TitleText>Background</S.TitleText>
                <Button colorScheme={theme.colours.buttonScheme} size="sm" onClick={onOpen}>
                    Edit
                </Button>
            </S.TitleBar>
            <AutoResizeTextarea
                mt="8px"
                isReadOnly
                fontSize="sm"
                resize="none"
                variant="unstyled"
                color={theme.colours.body}
                value={activeCharacter?.metadata?.background}
            />
            <DataModal title="Character Background" isOpen={isOpen} onClose={handleCancel} onAction={handleSave}>
                <DataModalTextArea
                    value={value}
                    onChange={handleTextAreaChange}
                    placeholder="Enter your character's background..."
                />
            </DataModal>
        </S.Root>
    );
}
