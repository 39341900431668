import styled from 'styled-components';

export const Root = styled.div`
    gap: 8px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
`;

export const Radio = styled.input`
    height: 30px;
    width: 30px;
    color: green;
`;
