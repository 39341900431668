import styled from 'styled-components';

export const CssReset = styled.div`
    white-space: preserve;
    * > a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        text-decoration: revert;
        font-size: revert;
        font-family: revert;
        font-weight: revert;
        color: revert;
    }
`;
