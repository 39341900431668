import styled from 'styled-components';

export const CypherCardRoot = styled.div<{ disabled?: boolean }>`
    gap: 16px;
    display: flex;
    padding: 16px;
    border-radius: 8px;
    flex-direction: column;
    background-color: ${(p) => p.theme.colours.card};

    &:hover {
        background-color: ${(p) => p.theme.colours.background};
    }
`;

export const CardText = styled.div`
    font-size: 20px;
    color: ${(p) => p.theme.colours.heading};
    ${(p) => p.theme.text.Display.Roman55};
`;

export const CardTitleText = styled(CardText)`
    font-size: 26px;
    flex-grow: 1;
`;

export const CardTitleBar = styled.div`
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
`;

export const DiscardButton = styled.div`
    ${(p) => p.theme.button}
    height: 40px;
`;
