import React from 'react';

import { APIInventoryGroup, APIInventoryItem, APIUpdateInventory } from '../../../types';
import { InventoryDataModal } from '../InventoryDataModal';

import * as S from './ItemCard.style';
import { Menu, MenuItem } from '../../Menu';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { MarkdownText } from '../../MarkdownText/MarkdownText';

interface ICardProps {
    item: APIInventoryItem;
    isGameMaster?: boolean;
    inventoryGroups: APIInventoryGroup[];
    onChange: (itemID: string, itemData: APIUpdateInventory) => void;
    onDelete: (itemID: string) => void;
}

export function ItemCard(props: ICardProps) {
    const { item, onDelete, onChange, inventoryGroups, isGameMaster = false } = props;
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    return (
        <S.ItemCard>
            <S.CardHeader>
                <S.CardQuantity>{item.quantity}x</S.CardQuantity>
                <S.CardTitle>{item.name}</S.CardTitle>
                {!isGameMaster && (
                    <Menu>
                        <MenuItem icon={<EditIcon />} onClick={() => setIsModalOpen(true)}>
                            Edit
                        </MenuItem>
                        <MenuItem icon={<DeleteIcon />} onClick={() => onDelete(item.id)}>
                            Delete
                        </MenuItem>
                    </Menu>
                )}
            </S.CardHeader>
            <S.CardDesc>
                <MarkdownText value={item.description ?? ''} />
            </S.CardDesc>
            <InventoryDataModal
                title="Update Inventory Item"
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                initialData={item}
                groupOptions={inventoryGroups.map((grp) => ({ value: grp.id, label: grp.title }))}
                onSubmit={(data) => {
                    onChange(item.id, data);
                    setIsModalOpen(false);
                }}
            />
        </S.ItemCard>
    );
}
