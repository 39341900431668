import React from 'react';

import { useActiveCharacter } from '../../../hooks/dataHooks';
import { APICharacter, APIUpdateCharacter } from '../../../types';

import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { useTheme } from 'styled-components';
import { DataModal, DataModalInput } from '../../../components/DataModal';

interface IQuickStatProps {
    title: string;
    value: number;
    charKey: string;
    updateStat: (charKey: string, newValue: number) => void;
}

function QuickStat(props: IQuickStatProps) {
    const { title, value, updateStat, charKey } = props;
    const [currentValue, setCurrentValue] = React.useState(value);
    const theme = useTheme();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onOpenModal = () => {
        setCurrentValue(value);
        onOpen();
    };

    const onModalAction = () => {
        updateStat(charKey, currentValue);
        onClose();
    };

    return (
        <Flex
            padding="8px"
            flexGrow="1"
            flexBasis="0"
            height="150px"
            cursor="pointer"
            direction="column"
            borderRadius="8px"
            alignItems="center"
            onClick={onOpenModal}
            justifyContent="center"
            color={theme.colours.body}
            backgroundColor={theme.colours.card}
        >
            <Box fontSize="20px">{title}</Box>
            <Flex flexGrow="1" alignItems="center" justifyContent="center">
                <Box fontSize="56px">{value}</Box>
            </Flex>
            <DataModal title={`Edit ${title}`} isOpen={isOpen} onClose={onClose} onAction={onModalAction}>
                <DataModalInput
                    value={currentValue}
                    onChange={(evt) => setCurrentValue(evt.target.value as any)}
                    type="number"
                />
            </DataModal>
        </Flex>
    );
}

interface IStatBarProps {
    max: number;
    min: number;
    edge: number;
    title: string;
    current: number;
    onClick: () => void;
}

function StatBar(props: IStatBarProps) {
    const { max, min, current, title, edge, onClick } = props;
    const theme = useTheme();

    let alignProps: any = { justifyContent: 'flex-end', left: 0 };
    let percentage = (current / (max - min)) * 100;
    let innerPercentage = percentage;
    if (percentage < 50) {
        innerPercentage = 100 - percentage;
        alignProps = { justifyContent: 'flex-start', right: 0 };
    }

    return (
        <Flex direction="column">
            <Flex direction="row" padding="4px">
                <Box flexGrow="1" fontSize="18px" color="white">
                    {title}
                </Box>
                <Box fontSize="16px" color={theme.colours.buttonTextDisabled}>
                    Edge: {edge}
                </Box>
            </Flex>
            <Flex direction="row" alignItems="center" gap="8px" cursor="pointer" onClick={onClick}>
                <Flex
                    width="100%"
                    height="50px"
                    overflow="clip"
                    borderRadius="25px 0px 0px 25px"
                    position="relative"
                    alignItems="flex-start"
                    backgroundColor={theme.colours.background}
                >
                    <Flex
                        padding="8px"
                        height="100%"
                        color="white"
                        fontSize="20px"
                        alignItems="center"
                        position="absolute"
                        justifyContent="center"
                        width={`${innerPercentage}%`}
                        {...alignProps}
                    >
                        <Box>{current}</Box>
                    </Flex>
                    <Flex
                        height="50px"
                        transition="width 0.3s"
                        width={`${percentage}%`}
                        backgroundColor={theme.colours.primary}
                    ></Flex>
                </Flex>
                <Flex
                    padding="8px"
                    color="white"
                    fontSize="20px"
                    minWidth="50px"
                    minHeight="50px"
                    alignItems="center"
                    borderRadius="0px 25px 25px 0px"
                    backgroundColor={theme.colours.primary}
                >
                    <Box>{max}</Box>
                </Flex>
            </Flex>
        </Flex>
    );
}

interface IUpdateStatModalProps {
    statType: string;
    onClose: () => void;
    activeCharacter: APICharacter | null;
    updateCharacter: (data: APIUpdateCharacter) => void;
}

function UpdateStatModal(props: IUpdateStatModalProps) {
    const { statType, onClose, activeCharacter, updateCharacter } = props;

    const statMax = parseInt((activeCharacter as any)?.stats?.[statType]?.max ?? 0);
    const statEdge = parseInt((activeCharacter as any)?.stats?.[statType]?.edge ?? 0);
    const statCurrent = parseInt((activeCharacter as any)?.stats?.[statType]?.current ?? 0);

    const [maxState, setMaxState] = React.useState(statMax);
    const [edgeState, setEdgeState] = React.useState(statEdge);
    const [currentState, setCurrentState] = React.useState(statCurrent);

    React.useEffect(() => {
        setMaxState(statMax);
        setEdgeState(statEdge);
        setCurrentState(statCurrent);
    }, [statType]);

    const onSubmit = () => {
        if (activeCharacter && statType) {
            updateCharacter({
                stats: {
                    [statType]: {
                        max: maxState,
                        edge: edgeState,
                        current: Math.min(currentState, maxState)
                    }
                }
            });
        }
        onClose();
    };

    return (
        <DataModal
            onClose={onClose}
            onAction={onSubmit}
            isOpen={Boolean(statType)}
            title={`Update ${(statType || ' ')[0].toUpperCase() + (statType || '  ').slice(1)}`}
        >
            <DataModalInput
                title="Max"
                type="number"
                value={maxState}
                onChange={(evt) => setMaxState(evt.target.value as any)}
            />
            <DataModalInput
                title="Current"
                type="number"
                value={currentState}
                onChange={(evt) => setCurrentState(evt.target.value as any)}
            />
            <DataModalInput
                title="Edge"
                type="number"
                value={edgeState}
                onChange={(evt) => setEdgeState(evt.target.value as any)}
            />
        </DataModal>
    );
}

export function CharacterQuickView() {
    const theme = useTheme();

    const { activeCharacter, updateCharacter, isGameMaster } = useActiveCharacter();
    const [statType, setStatType] = React.useState<string>('');

    const updateStat = (charKey: string, newValue: number) => {
        const charVals = charKey.split('.');
        charVals.reverse();
        const updateDict = charVals.reduce((value, key) => ({ [key]: value }), newValue as any);
        if (activeCharacter) {
            updateCharacter(updateDict);
        }
    };

    return (
        <Flex overflow="hidden" direction="column" justifyContent="flex-start" flexGrow="1" width="100%" gap="16px">
            <Flex direction="row" gap="16px" width="100%">
                <QuickStat
                    title="XP"
                    value={activeCharacter?.metadata?.experience ?? 0}
                    charKey="metadata.experience"
                    updateStat={updateStat}
                />
                <QuickStat
                    title="Armour"
                    value={activeCharacter?.stats?.armour ?? 0}
                    charKey="stats.armour"
                    updateStat={updateStat}
                />
            </Flex>
            <Flex direction="row" gap="16px">
                <QuickStat
                    title="Shins"
                    value={activeCharacter?.metadata?.shins ?? 0}
                    charKey="metadata.shins"
                    updateStat={updateStat}
                />
                <QuickStat
                    title="Tier"
                    value={activeCharacter?.metadata?.tier ?? 0}
                    charKey="metadata.tier"
                    updateStat={updateStat}
                />
            </Flex>
            <Flex
                direction="column"
                overflowY="auto"
                overflowX="hidden"
                backgroundColor={theme.colours.card}
                borderRadius="8px"
                flexGrow="1"
                padding="16px"
                gap="32px"
            >
                <StatBar
                    min={0}
                    title={'Might'}
                    max={activeCharacter?.stats?.might?.max ?? 0}
                    edge={activeCharacter?.stats?.might?.edge ?? 0}
                    current={activeCharacter?.stats?.might?.current ?? 0}
                    onClick={() => (!isGameMaster ? setStatType('might') : null)}
                />
                <StatBar
                    min={0}
                    title={'Speed'}
                    max={activeCharacter?.stats?.speed?.max ?? 0}
                    edge={activeCharacter?.stats?.speed?.edge ?? 0}
                    current={activeCharacter?.stats?.speed?.current ?? 0}
                    onClick={() => (!isGameMaster ? setStatType('speed') : null)}
                />
                <StatBar
                    min={0}
                    title={'Intellect'}
                    max={activeCharacter?.stats?.intellect?.max ?? 0}
                    edge={activeCharacter?.stats?.intellect?.edge ?? 0}
                    current={activeCharacter?.stats?.intellect?.current ?? 0}
                    onClick={() => (!isGameMaster ? setStatType('intellect') : null)}
                />
            </Flex>
            <UpdateStatModal
                statType={statType}
                onClose={() => setStatType('')}
                updateCharacter={updateCharacter}
                activeCharacter={activeCharacter}
            />
        </Flex>
    );
}
