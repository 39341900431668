import React from 'react';

import { useActiveCampaign } from '../../hooks/dataHooks';

import * as S from './ActiveCharacterLock.style';

export function ActiveCharacterLock(props: React.PropsWithChildren<{}>) {
    const { children } = props;
    const { activeCharacter, isGameMaster } = useActiveCampaign();

    if (activeCharacter || isGameMaster) {
        return <>{children}</>;
    }

    return <S.Root>{children}</S.Root>;
}
