import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { TextStyles } from '../../../theme/fonts';

const inactiveHover = css`
    color: ${(p) => p.theme.colours.disabledSecondary};
    transition: color 0s ease;
`;

const activeStyles = css`
    text-decoration: underline;
    color: ${(p) => p.theme.colours.heading};
`;

export const Tab = styled(Link)<{ $isActive: boolean }>`
    padding: 5px;
    cursor: pointer;
    font-size: 1.8rem;
    transition: color 0.5s ease;
    ${TextStyles.Display.Medium65};
    text-decoration: none;
    color: ${(p) => p.theme.colours.subHeadingAlt};

    ${(p) => p.$isActive && activeStyles}

    &:hover {
        ${(p) => !p.$isActive && inactiveHover}
    }
`;

export const Wrapper = styled.div`
    display: flex;
    min-width: 22%;
    flex-direction: column;
`;
