import styled from 'styled-components';

export const DiceActionsRoot = styled.div`
    gap: 8px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    ${(p) => p.theme.text.Text.Roman55}
`;

export const RollButton = styled.div`
    height: 50px;
    color: white;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    ${(p) => p.theme.text.Text.Roman55}
    background-color: ${(p) => p.theme.colours.button};

    &:hover {
        background-color: ${(p) => p.theme.colours.buttonHover};
    }

    &:active {
        transform: translateY(2px);
    }
`;

export const DiceRow = styled.div`
    gap: 8px;
    display: flex;
`;

export const DiceSelect = styled.div`
    height: 50px;
    display: flex;
    min-width: 100px;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    ${(p) => p.theme.text.Text.Roman55}
    color: ${(p) => p.theme.colours.subHeading};
    background-color: ${(p) => p.theme.colours.card};
`;

export const StatSelect = styled.div`
    flex-grow: 1;
    height: 50px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    ${(p) => p.theme.text.Text.Roman55}
    color: ${(p) => p.theme.colours.subHeading};
    background-color: ${(p) => p.theme.colours.card};
`;

export const SkillSelect = styled.div`
    flex-grow: 1;
    height: 50px;
    display: flex;
    padding: 4px;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    ${(p) => p.theme.text.Text.Roman55}
    color: ${(p) => p.theme.colours.subHeading};
    background-color: ${(p) => p.theme.colours.card};
`;

export const AssetSelect = styled.div`
    flex-grow: 1;
    height: 50px;
    padding: 4px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    ${(p) => p.theme.text.Text.Roman55}
    color: ${(p) => p.theme.colours.subHeading};
    background-color: ${(p) => p.theme.colours.card};
`;

export const EffortSelect = styled.div`
    height: 50px;
    padding: 4px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    background-color: ${(p) => p.theme.colours.card};
`;

export const EffortText = styled.div`
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid ${(p) => p.theme.colours.unchecked};
    border-right: 2px solid ${(p) => p.theme.colours.unchecked};
    color: ${(p) => p.theme.colours.subHeading};
`;

export const EffortButton = styled.div`
    width: 50px;
    height: 100%;
    display: flex;
    font-size: 25px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    ${(p) => p.theme.text.Text.Roman55}
    color: ${(p) => p.theme.colours.subHeading};
`;

export const LevelSelectButton = styled.div<{ isSelected?: boolean }>`
    width: 60px;
    height: 100%;
    display: flex;
    cursor: pointer;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    border-left: 2px ${(p) => p.theme.colours.unchecked} solid;
    ${(p) => p.isSelected && `background-color: ${p.theme.colours.primary};`}

    &:hover {
        ${(p) => !p.isSelected && `background-color: ${p.theme.colours.primaryT1};`}
    }
`;

export const SelectText = styled.div`
    flex-grow: 1;
    padding-left: 16px;
`;
