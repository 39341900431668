import React from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { LoadingWrapper } from '../LoadingWrapper';
import { apis } from '../../network';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '../../state';
import { Avatar, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Box, Flex } from '@chakra-ui/react';
import { useTheme } from 'styled-components';
import { ToggleColourPreference } from '../ToggleColourPreference';

// TODO The stuff in here needs some work.

// Checks for oauth query params and hands rest oauth flow.
async function handleOauthParams(navigate: NavigateFunction) {
    const params = new URLSearchParams(window.location.search);

    // Handle error param.
    if (params.has('error')) {
        console.error(params.get('error') ?? '');
        return navigate('/', { replace: true });
    }

    // Handle code param.
    if (params.has('code')) {
        const code = params.get('code') ?? '';

        const accessToken = await apis.account.getAccessToken(code);
        if (accessToken) {
            Cookies.set('access_token', accessToken, { sameSite: 'none', secure: true, expires: 7 });
        }

        // Navigate to saved path or default.
        const path = params.has('state') ? params.get('state') ?? '/' : '/';
        return navigate(path, { replace: true });
    }
}

// Hook to handle storing/updating discord user data.
function useDiscordUser() {
    const dispatch = useDispatch();
    const authenticatedUser = useSelector(selectors.selectAuthenticatedUser);
    const isValidating = useSelector(selectors.selectIsDiscordLoading);

    const validateAuthenticated = React.useCallback(async () => {
        if (Cookies.get('access_token')) {
            const token = Cookies.get('access_token');
            const decodedToken = jwt_decode<any>(token ?? '') ?? '';
            const profile_id = decodedToken?.['profile_id'];
            const discordAccessToken = decodedToken?.['access_token'];
            dispatch(actions.auth.setIsLoading(true));
            const result = await apis.account.getDiscordUser(discordAccessToken);

            if (!result) {
                dispatch(actions.auth.setIsLoading(false));
                dispatch(actions.auth.logout());
                return console.error('Could not retrieve user discord data.');
            }

            dispatch(
                actions.auth.setUser({
                    profile_id,
                    discordAvatarID: result.avatarID,
                    discordUserID: result.userID,
                    discordUsername: result.username
                })
            );
            dispatch(actions.auth.setIsLoading(false));
        } else {
            dispatch(actions.auth.logout());
        }
    }, []);

    React.useEffect(() => {
        validateAuthenticated();
    }, [Cookies.get('access_token'), validateAuthenticated]);

    return {
        authenticatedUser,
        isValidating,
        validateAuthenticated
    };
}

// ===== Components =====

export function signInWithDiscord() {
    const url = new URL('https://discord.com/api/oauth2/authorize');
    url.search = new URLSearchParams({
        scope: 'identify',
        response_type: 'code',
        client_id: '951673354279075870',
        state: `${window.location.pathname}${window.location.search}`,
        redirect_uri: `${window.location.origin}/authn`
    }).toString();
    window.location.href = url.href;
}

export function UserProfile() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { authenticatedUser, isValidating } = useDiscordUser();

    const onLogout = React.useCallback(() => {
        Cookies.remove('access_token');
        dispatch(actions.auth.logout());
    }, []);

    React.useEffect(() => {
        handleOauthParams(navigate);
    }, [window.location.search.length]);

    let src = undefined;
    if (authenticatedUser) {
        src = `https://cdn.discordapp.com/avatars/${authenticatedUser.discordUserID}/${authenticatedUser.discordAvatarID}.png`;
    }

    return (
        <Box paddingRight="8px">
            <LoadingWrapper isLoading={isValidating}>
                <Menu>
                    <MenuButton>
                        <Avatar src={src} />
                    </MenuButton>
                    <MenuList bg={theme.colours.card} borderColor={theme.colours.body}>
                        {authenticatedUser ? (
                            <>
                                <Flex direction="column" paddingLeft="8px">
                                    <Box fontSize="12px" color={theme.colours.buttonTextDisabled}>
                                        Logged in as
                                    </Box>
                                    <Box fontSize="16px" color={theme.colours.body}>
                                        {authenticatedUser.discordUsername}
                                    </Box>
                                </Flex>
                                <MenuDivider />
                                <MenuItem
                                    fontSize="25px"
                                    onClick={onLogout}
                                    bg={theme.colours.card}
                                    color={theme.colours.body}
                                    _hover={{ bg: theme.colours.background }}
                                >
                                    Sign Out
                                </MenuItem>
                            </>
                        ) : (
                            <MenuItem
                                fontSize="25px"
                                bg={theme.colours.card}
                                color={theme.colours.body}
                                onClick={signInWithDiscord}
                                _hover={{ bg: theme.colours.background }}
                            >
                                Sign In
                            </MenuItem>
                        )}
                        <MenuDivider />
                        <Box paddingLeft="8px" fontSize="16px" color={theme.colours.body}>
                            Colour Preference
                        </Box>
                        <Box padding="8px">
                            <ToggleColourPreference />
                        </Box>
                    </MenuList>
                </Menu>
            </LoadingWrapper>
        </Box>
    );
}
