export const skillOptions = [
    {value: 'Astronomy', label: 'Astronomy'},
    {value: 'Balancing', label: 'Balancing'},
    {value: 'Carrying', label: 'Carrying'},
    {value: 'Climing', label: 'Climing'},
    {value: 'Crafting numenara', label: 'Crafting numenara'},
    {value: 'Deceiving', label: 'Deceiving'},
    {value: 'Economics', label: 'Economics'},
    {value: 'Escaping', label: 'Escaping'},
    {value: 'Fucking', label: 'Fucking'},
    {value: 'Geography', label: 'Geography'},
    {value: 'Geology', label: 'Geology'},
    {value: 'Healing', label: 'Healing'},
    {value: 'Heavy Weapons', label: 'Heavy Weapons'},
    {value: 'History', label: 'History'},
    {value: 'Identifying', label: 'Identifying'},
    {value: 'Initiative', label: 'Initiative'},
    {value: 'Intimidation', label: 'Intimidation'},
    {value: 'Jumping', label: 'Jumping'},
    {value: 'Leatherworking', label: 'Leatherworking'},
    {value: 'Light Weapons', label: 'Light Weapons'},
    {value: 'Literature', label: 'Literature'},
    {value: 'Lockpicking', label: 'Lockpicking'},
    {value: 'Medium Weapons', label: 'Medium Weapons'},
    {value: 'Metalworking', label: 'Metalworking'},
    {value: 'Perception', label: 'Perception'},
    {value: 'Persuasion', label: 'Persuasion'},
    {value: 'Philosophy', label: 'Philosophy'},
    {value: 'Pickpocketing', label: 'Pickpocketing'},
    {value: 'Public Speaking', label: 'Public Speaking'},
    {value: 'Repairing', label: 'Repairing'},
    {value: 'Riding', label: 'Riding'},
    {value: 'Salvaging numenera', label: 'Salvaging numenera'},
    {value: 'Smashing', label: 'Smashing'},
    {value: 'Sneaking', label: 'Sneaking'},
    {value: 'Swimming', label: 'Swimming'},
    {value: 'Understanding numenera', label: 'Understanding numenera'},
    {value: 'Woodworking', label: 'Woodworking'},
] 

export const skillLevels = [
    {value: 'Inability', label: 'Inability'},
    {value: 'Trained', label: 'Trained'},
    {value: 'Skilled', label: 'Skilled'}
]