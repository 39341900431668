import styled from 'styled-components';

import { TextStyles } from '../../theme/fonts';

export const CharacterPageWrapper = styled.div`
    margin: 5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
`;

export const WelcomeBanner = styled.div`
    margin-bottom: 3rem;
`;

export const WelcomePrimaryText = styled.div`
    ${TextStyles.Display.Bold75}
    font-size:3.645835rem;
    color: ${(p) => p.theme.colours.heading};
    transition: color 0.5s ease;
`;

export const WelcomeSubText = styled.div`
    ${TextStyles.Display.Light45}
    font-style: italic;
    font-size: 1.5rem;
    color: ${(p) => p.theme.colours.subHeadingAlt};
    transition: color 0.5s ease;
`;

export const CharacterBodyWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    gap: 24px;
    height: 100%;
    overflow: hidden;
`;
