import { ColourPrefereneceType, ThemeType } from './theme';

export enum MemberType {
    GAME_MASTER = 1,
    PLAYER = 2
}

export enum EventType {
    DICE_ROLL = 'dice-roll',
    LOG = 'log',
    RESOURCE = 'resource'
}

export enum ResourceEventAction {
    ASSIGN = 1,
    UNASSIGN = 2
}

export interface APIUserCampaign {
    id: string;
    name: string;
    created_by: string;
    created_at: string;
    member_type: MemberType;
    member_count: number;
    active_character: string | null;
    last_active: string | null;
}

export interface APIPlaySession {
    id: string;
    name: string;
    created_by: string;
    created_at: string;
    campaign_id: string;
}

export interface APISessionMember {
    profile_id: string;
    member_type: MemberType;
    is_active: boolean;
}

export interface APILatestEventData {
    event_id: string;
    event_type: EventType;
}

export interface APIPlaySessionData {
    campaign_id: string;
    session_id: string;
    members: APISessionMember[];
    latest_events: APILatestEventData[];
}

export interface APICharacterMetadata {
    descriptor: string;
    type: string;
    focus: string;
    background: string;
    tier: number;
    experience: number;
    effort: number;
    shins: number;
}

export interface APICharacterStatPool {
    max: number;
    current: number;
    edge: number;
}

export interface APICharacterStats {
    might: APICharacterStatPool;
    speed: APICharacterStatPool;
    intellect: APICharacterStatPool;
    armour: number;
    damage: number;
}

export interface APICharacterSkill {
    id: string;
    name: string;
    level: string;
}
export interface APICharacterAbility {
    id: string;
    name: string;
    description: string;
}

export interface APICharacter {
    id: string;
    profile_id: string;
    name: string;
    created_at: string;
    metadata: APICharacterMetadata;
    stats: APICharacterStats;
    skills: APICharacterSkill[];
    abilities: APICharacterAbility[];
}

type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export interface APIUpdateCharacter {
    name?: string;
    metadata?: DeepPartial<APICharacterMetadata>;
    stats?: DeepPartial<APICharacterStats>;
    skills?: DeepPartial<APICharacterSkill[]>;
    abilities?: DeepPartial<APICharacterAbility[]>;
}

export interface APIEvent<T> {
    id: string;
    profile_id: string;
    event_data: T;
    created_at: string;
    event_type: EventType;
    session_id: string;
    character_id: string | null;
}

export interface DiceRollEvent {
    bonus: number;
    member_name: string;
    dice_type: string;
    roll_result: number;
}

export interface LogEvent {
    log_message: string;
    member_name: string;
}

export interface APIUpdateLogEvent {
    log_message?: string;
    member_name?: string;
}

export interface ResourceEvent {
    resource_id: string;
    action: ResourceEventAction;
}

export interface APIEditCampaignMembership {
    active_character: string;
}

export interface APIPreferences {
    id: string;
    user_id: string;
    theme_preference: ThemeType;
    colour_preference: ColourPrefereneceType;
}

export interface APIUpdatePreferences {
    theme_preference?: ThemeType;
    colour_preference?: ColourPrefereneceType;
}

export interface APICreateResource {
    name: string;
    file_size: number;
}

export interface APINewUserResource {
    id: string;
    name: string;
    upload_url: string;
}

export interface APIUserResource {
    id: string;
    name: string;
    profile_id: string;
    created_at: string;
    resource_url: string;
}

export interface APIInventoryGroup {
    id: string;
    title: string;
}

export enum InventoryItemType {
    GENERIC = 'generic',
    CYPHER = 'cypher',
    ARTIFACT = 'artifact'
}

export interface APICreateInventory {
    name: string;
    type: InventoryItemType;
    description?: string;
    quantity?: number;
    group_id?: string;
    level?: number;
}

export interface APIUpdateInventory {
    name?: string;
    type?: InventoryItemType;
    description?: string;
    quantity?: number;
    group_id?: string | null;
    level?: number;
}

export interface APIInventoryItem {
    id: string;
    character_id: string;
    profile_id: string;
    name: string;
    quantity: number;
    description?: string;
    group_id?: string;
    level: number;
    type: InventoryItemType;
    created_at: string;
}
