import React from 'react';
import * as S from './DamageBar.style'

export interface DamageBarProps {
    currentLevel: string;
}

export function DamageBar() {


    return (
        <S.DamageBarWrapper>
            <S.DamageBarLevel>
                <S.DamageBarLabel>
                    Impared
                </S.DamageBarLabel>
                <S.DamageBarIndicator active={true}/>
            </S.DamageBarLevel>
            <S.DamageBarLevel>
                <S.DamageBarLabel>
                    Debilitated
                </S.DamageBarLabel>
                <S.DamageBarIndicator active={true}/>
            </S.DamageBarLevel>
            <S.DamageBarLevel>
                <S.DamageBarLabel>
                    Unconsious
                </S.DamageBarLabel>
                <S.DamageBarIndicator active={false}/>
            </S.DamageBarLevel>
        </S.DamageBarWrapper>
    )
}