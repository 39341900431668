import styled from 'styled-components';

export const Root = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TitleText = styled.div`
    font-size: 38px;
    display: flex;
    flex-grow: 1;
    text-decoration: underline;
    color: ${(p) => p.theme.colours.heading};
    ${(p) => p.theme.text.Display.Roman55};
`;
