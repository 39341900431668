import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIUserResource } from '../../types';

export interface ResourcesSliceState {
    resources: APIUserResource[];
}

const initialState: ResourcesSliceState = {
    resources: []
};

export const resources = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        save: (state, action: PayloadAction<APIUserResource[]>) => {
            state.resources = action.payload;
        },
        clear: (state) => {
            state.resources = [];
        }
    }
});
