import styled from 'styled-components';
import { TextStyles } from '../../../theme/fonts';

import { ReactComponent as Cross } from '../../../icons/svgs/Cross.svg';

export const CardWrapper = styled.div`
    height: 595px;
    width: 374px;
    border-radius: 25px;
    background: ${(p) => p.theme.colours.card};
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(102%);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
`;

export const CardImage = styled.img``;

export const CardDetails = styled.div`
    width: 100%;
    text-align: center;
    position: absolute;
    top: 425px;
`;

export const CardTitle = styled.div`
    ${TextStyles.Display.Medium65}
    font-size: 48px;
    color: ${(p) => p.theme.colours.heading};
`;

export const CardSubTitle = styled.div`
    ${TextStyles.Display.Roman55}
    font-size: 19px;
    max-width: 75%;
    margin: 0 auto;
    color: ${(p) => p.theme.colours.subHeadingAlt}; ;
`;

export const RemoveButton = styled(Cross)`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    min-width: 20px;
    cursor: pointer;
`;
