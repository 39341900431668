import React, { FC } from 'react';
import CreateCharacterModal from '../CreateCharacterModal/CreateCharacterModal';
import * as S from './CharacterCard.style';
import styled from 'styled-components';

import { ReactComponent as Cross } from '../../../icons/svgs/Plus.svg'

const StyledCross = styled(Cross)`
    width: 175px;
    fill: #CCCCCC;
    transform: translate(0, -50px);
`;

const CreateCharacterCard: FC = () => {

    const [createModalShow, setCreateModalShow] = React.useState(false);

    return(
        <>
            <S.CardWrapper onClick={() => setCreateModalShow(true)}>
                <StyledCross />
                <S.CardDetails>
                    <S.CardTitle>Create new</S.CardTitle>
                    <S.CardSubTitle>{"Create a new character to start wonderful advetnreus"}</S.CardSubTitle>
                </S.CardDetails>
            </S.CardWrapper>
            <CreateCharacterModal show={createModalShow} onHide={() => {
                setCreateModalShow(false);
                window.location.reload();
                }} />
        </>
    )
}

export default CreateCharacterCard;