import React from 'react';
import { format } from 'date-fns';

import { APIEvent, APIUpdateLogEvent, LogEvent } from '../../../types';
import { useActiveCampaign, useLogEvents } from '../../../hooks/dataHooks';
import { useEnterKeyRef, useScrollToRef } from '../../../hooks/utilHooks';

import * as S from './SessionLog.style';
import { Box, Button, Flex, IconButton, Textarea, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useTheme } from 'styled-components';
import { ConfirmDialog, useConfirmDialog } from '../../../components/ConfirmDialog';
import { DataModal, DataModalTextArea } from '../../../components/DataModal';
import { MarkdownText } from '../../../components/MarkdownText/MarkdownText';

interface IUpdateLogModalProps {
    isOpen: boolean;
    onClose: () => void;
    event?: APIEvent<LogEvent>;
    updateLogEvent: (eventID: string, eventData: APIUpdateLogEvent) => void;
}

function UpdateLogModal(props: IUpdateLogModalProps) {
    const { isOpen, onClose, event, updateLogEvent } = props;
    const [currentMessage, setCurrentMessage] = React.useState(event?.event_data?.log_message ?? '');

    React.useEffect(() => {
        setCurrentMessage(event?.event_data?.log_message ?? '');
    }, [isOpen, event]);

    const onUpdateEvent = () => {
        if (event) {
            updateLogEvent(event.id, { log_message: currentMessage });
        }
        onClose();
    };

    return (
        <DataModal title="Update Log Message" isOpen={isOpen} onClose={onClose} onAction={onUpdateEvent}>
            <DataModalTextArea value={currentMessage} onChange={(evt) => setCurrentMessage(evt.target.value)} />
        </DataModal>
    );
}

// ===== Log Row =====

function convertToJSX(text: string) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.matchAll(urlRegex);
    const noticeTextArr: (string | JSX.Element)[] = [];
    let lastIndex = 0;

    for (const match of matches as any) {
        const [fullMatch, matchUrl] = match;
        noticeTextArr.push(text.substring(lastIndex, match.index));
        noticeTextArr.push(<a href={matchUrl}>{matchUrl}</a>);
        lastIndex = match.index + fullMatch.length;
    }

    if (lastIndex < text.length) {
        noticeTextArr.push(text.substring(lastIndex));
    }

    return noticeTextArr;
}

interface ILogRowProps {
    profileID: string;
    isGameMaster: boolean;
    event: APIEvent<LogEvent>;
    deleteEvent: (eventID: string) => void;
    onEditEvent: (eventID: string) => void;
}

function LogRow(props: ILogRowProps) {
    const { event, deleteEvent, profileID, isGameMaster, onEditEvent } = props;
    const theme = useTheme();
    const [onHover, setOnHover] = React.useState(false);
    const shouldShowEvent = (profileID == event.profile_id || isGameMaster) && onHover;
    const buttonWidth = isGameMaster && profileID != event.profile_id ? '30px' : '58px';
    return (
        <Flex
            padding="4px"
            borderRadius="8px"
            flexDirection="column"
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            _hover={{ backgroundColor: theme.colours.background }}
        >
            <Flex position="relative" flexDirection="row" alignItems="center" gap="4px">
                <S.MemberNameText>{event.event_data?.member_name}</S.MemberNameText>
                <Box position="relative" height="100%">
                    <Flex
                        position="absolute"
                        right="0"
                        overflow="hidden"
                        width={shouldShowEvent ? buttonWidth : '0'}
                        transition="width .2s"
                        direction="row"
                        gap="8px"
                    >
                        {profileID == event.profile_id && (
                            <>
                                <IconButton
                                    size="xs"
                                    aria-label="Edit Log"
                                    icon={<EditIcon />}
                                    onClick={() => onEditEvent(event.id)}
                                />
                                <IconButton
                                    size="xs"
                                    aria-label="Delete Log"
                                    icon={<DeleteIcon />}
                                    onClick={() => deleteEvent(event.id)}
                                />
                            </>
                        )}
                    </Flex>
                </Box>
                <S.LogTimeText>{format(new Date(`${event.created_at}`), 'h:mm a')}</S.LogTimeText>
            </Flex>
            <S.LogMessage>
                <MarkdownText value={event.event_data?.log_message ?? ''} />
            </S.LogMessage>
        </Flex>
    );
}

// ===== MessageInput =====

interface IMessageInputProps {
    memberName: string;
    loadSessionData: () => void;
}

function MessageInput(props: IMessageInputProps) {
    const theme = useTheme();
    const { memberName, loadSessionData } = props;
    const { activeUser, loadLogEvents, saveLogEvent } = useLogEvents();
    const [logMessage, setLogMessage] = React.useState('');
    const [scrollHeight, setScrollHeight] = React.useState<number>(0);
    const [doScroll, setDoScroll] = React.useState(false);

    const onSubmitLogMessage = async () => {
        if (logMessage && activeUser) {
            saveLogEvent({
                log_message: logMessage,
                member_name: memberName
            });
            setLogMessage('');
            setScrollHeight(0);
            setDoScroll(false);
            await Promise.all([loadLogEvents(), loadSessionData()]);
        }
    };

    const onChange = (evt: any) => {
        let val = evt.target.value;
        const matches = val.match(/\r\n|\n\r|\n|\r/g);
        const count = matches ? matches.length + 1 : 1;
        setLogMessage(val);
        setScrollHeight(count * 34);
        setDoScroll(count > 4);
    };

    const { elementRef } = useEnterKeyRef(onSubmitLogMessage);
    return (
        <Flex flexGrow={1} gap="8px">
            <Textarea
                value={logMessage}
                ref={elementRef}
                autoComplete="off"
                placeholder="Enter a message..."
                resize="none"
                onChange={onChange}
                height={`${scrollHeight + 16}px`}
                minHeight="50px"
                maxHeight="152px"
                overflow={doScroll ? 'auto' : 'hidden'}
                fontSize="26px"
                lineHeight="34px"
                padding="8px"
                boxSizing="border-box"
                color={theme.colours.heading}
            />
            <Button size="lg" flexGrow={1} colorScheme={theme.colours.buttonScheme} onClick={onSubmitLogMessage}>
                Send
            </Button>
        </Flex>
    );
}

// ===== Session Log =====

interface ISessionLogProps {
    memberName: string;
    loadSessionData: () => void;
}

export function SessionLog(props: ISessionLogProps) {
    const { memberName, loadSessionData } = props;
    const { isGameMaster } = useActiveCampaign();
    const { scrollRef, scrollToElement } = useScrollToRef();
    const { logEvents, latestEventID, loadLogEvents, deleteLogEvent, activeUser, updateLogEvent } = useLogEvents();
    const { openDialog, dialogProps } = useConfirmDialog(deleteLogEvent);
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [activeLog, setActiveLog] = React.useState('');

    React.useEffect(() => {
        if (latestEventID !== logEvents?.[0]?.id ?? '') {
            loadLogEvents();
        }
    }, [latestEventID]);

    React.useEffect(() => {
        scrollToElement();
    }, [logEvents]);

    const onEditEvent = (eventID: string) => {
        setActiveLog(eventID);
        onOpen();
    };

    return (
        <S.LogRoot>
            <S.LogMessagesContainer>
                <div ref={scrollRef} />
                {logEvents.length === 0 && <S.NoLogevents>The session log is empty</S.NoLogevents>}
                {logEvents.map((evt) => (
                    <LogRow
                        key={evt.id}
                        event={evt}
                        deleteEvent={openDialog}
                        onEditEvent={onEditEvent}
                        isGameMaster={isGameMaster}
                        profileID={activeUser?.profile_id ?? ''}
                    />
                ))}
            </S.LogMessagesContainer>
            <S.SubmitLogContainer>
                <MessageInput memberName={memberName} loadSessionData={loadSessionData} />
            </S.SubmitLogContainer>
            <ConfirmDialog
                {...dialogProps}
                title="Delete Event"
                body="Are you sre you want to delete this log event?"
            />
            <UpdateLogModal
                onClose={onClose}
                isOpen={isOpen}
                updateLogEvent={updateLogEvent}
                event={logEvents.filter((evt) => evt.id === activeLog)?.[0] ?? null}
            />
        </S.LogRoot>
    );
}
