import styled from 'styled-components';

export const SwitchRoot = styled.div<{ enabled: boolean }>`
    --switch-height: 30px;
    display: flex;
    cursor: pointer;
    padding-left: 4px;
    aspect-ratio: 2 / 1;
    align-items: center;
    box-sizing: border-box;
    height: var(--switch-height);
    transition: background-color 0.2s linear;
    border-radius: calc(var(--switch-height) / 2);
    background-color: ${(p) => (p.enabled ? p.theme.colours.button : p.theme.colours.buttonDisabled)};
`;

export const SwitchThumb = styled.div<{ enabled: boolean }>`
    height: calc(100% - 8px);
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: white;
    transition: 0.2s ease-in-out;
    ${(p) => p.enabled && 'transform: translateX(calc(100% + 8px));'}
`;
