import { APICharacter, APIUpdateCharacter } from '../types';
import { apiHost, authHeaders } from './api';

async function getUserCharacters(): Promise<APICharacter[] | null> {
    const result = await fetch(`${apiHost()}/api/v1/characters`, {
        method: 'GET',
        headers: { ...authHeaders() }
    });

    if (result.status !== 200) {
        console.error(await result.text());
    }

    return await result.json();
}

async function loadCampaignCharacters(campaignUUID: string): Promise<APICharacter[] | null> {
    const result = await fetch(`${apiHost()}/api/v1/campaigns/${campaignUUID}/characters`, {
        method: 'GET',
        headers: { ...authHeaders() }
    });

    if (result.status !== 200) {
        console.error(await result.text());
    }

    return await result.json();
}

async function getCharacter(userID: string, characterID: string): Promise<APICharacter | null> {
    const result = await fetch(`${apiHost()}/api/v1/characters/${characterID}`, {
        method: 'GET',
        headers: { ...authHeaders(), 'X-USER-ID': userID }
    });

    if (result.status !== 200) {
        console.error(await result.text());
    }

    return await result.json();
}

async function updateCharacter(characterID: string, characterData: APIUpdateCharacter): Promise<APICharacter | null> {
    const result = await fetch(`${apiHost()}/api/v1/characters/${characterID}`, {
        method: 'PUT',
        headers: { ...authHeaders(), 'Content-Type': 'application/json' },
        body: JSON.stringify(characterData)
    });

    if (result.status !== 200) {
        console.error(await result.text());
    }

    return await result.json();
}

async function deleteCharacter(characterID: string): Promise<boolean> {
    const result = await fetch(`${apiHost()}/api/v1/characters/${characterID}`, {
        method: 'DELETE',
        headers: { ...authHeaders() }
    });

    if (result.status !== 204) {
        return false;
    }

    return true;
}

export const character = {
    getUserCharacters,
    loadCampaignCharacters,
    getCharacter,
    updateCharacter,
    deleteCharacter
};
