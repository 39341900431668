import React from 'react';

import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    TextareaProps,
    InputProps,
    Textarea,
    Box
} from '@chakra-ui/react';
import Selectable from 'react-select';
import { useTheme } from 'styled-components';
import { useEnterKeyRef } from '../../hooks/utilHooks';

// ===== Data Modal Select =====

interface IDataModelSelectProps {
    title?: string;
    options: any;
    defaultValue: any;
    onChange: (val: any) => void;
}

export function DataModalSelect(props: IDataModelSelectProps) {
    const theme = useTheme();
    const { title, options, defaultValue, onChange } = props;
    return (
        <Flex direction="column" gap="2px">
            {title && (
                <Box color={theme.colours.buttonTextDisabled} fontSize={theme.chakra.fontSizes['md']}>
                    {title}
                </Box>
            )}
            <Selectable
                isSearchable
                options={options}
                onChange={onChange}
                defaultValue={defaultValue}
                styles={{
                    control: (provided: any) => ({
                        ...provided,
                        height: '50px',
                        borderRadius: '8px',
                        borderColor: theme.colours.body,
                        backgroundColor: theme.colours.card
                    }),
                    menu: (provided: any) => ({
                        ...provided,
                        color: theme.colours.body,
                        backgroundColor: theme.colours.card
                    }),
                    singleValue: (provided: any) => ({
                        ...provided,
                        color: theme.colours.body
                    })
                }}
            />
        </Flex>
    );
}

// ===== Data Modal Text Area =====

interface IDataModelTextAreaProps extends TextareaProps {
    title?: string;
}

export function DataModalTextArea(props: IDataModelTextAreaProps) {
    const theme = useTheme();
    const { title, ...textareaProps } = props;
    return (
        <Flex direction="column" gap="2px">
            {title && (
                <Box color={theme.colours.buttonTextDisabled} fontSize={theme.chakra.fontSizes['md']}>
                    {title}
                </Box>
            )}
            <Textarea
                resize="none"
                height="300px"
                padding="8px"
                borderRadius="8px"
                autoComplete="off"
                paddingInline="12px"
                data-lpignore="true"
                data-form-type="other"
                bg={theme.colours.card}
                borderColor={theme.colours.body}
                fontSize={theme.chakra.fontSizes.md}
                _placeholder={{ color: theme.colours.buttonTextDisabled }}
                {...textareaProps}
            />
        </Flex>
    );
}

// ===== Data Modal Input =====

interface IDataModelInputProps extends InputProps {
    title?: string;
    placeholder?: string;
    onEnter?: () => void;
}

export function DataModalInput(props: IDataModelInputProps) {
    const theme = useTheme();
    const { title, onEnter = () => null, ...inputProps } = props;
    const { elementRef } = useEnterKeyRef(onEnter);
    return (
        <Flex direction="column" gap="2px">
            {title && (
                <Box color={theme.colours.buttonTextDisabled} fontSize={theme.chakra.fontSizes['md']}>
                    {title}
                </Box>
            )}
            <Input
                size="lg"
                ref={elementRef}
                autoComplete="off"
                borderRadius="8px"
                data-lpignore="true"
                data-form-type="other"
                bg={theme.colours.card}
                borderColor={theme.colours.body}
                fontSize={theme.chakra.fontSizes.md}
                _placeholder={{ color: theme.colours.buttonTextDisabled }}
                {...inputProps}
            />
        </Flex>
    );
}

// ===== Data Modal =====

interface IDataModalProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    onAction: () => void;
    actionButton?: string;
}

export function DataModal(props: React.PropsWithChildren<IDataModalProps>) {
    const theme = useTheme();
    const { isOpen, onClose, onAction, title, children, actionButton = 'Save' } = props;
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
            <ModalOverlay />
            <ModalContent
                borderWidth="1px"
                color={theme.colours.body}
                bg={theme.colours.background}
                borderColor={theme.colours.body}
                fontSize={theme.chakra.fontSizes.md}
            >
                <ModalHeader fontSize={theme.chakra.fontSizes['2xl']}>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex direction="column" gap="8px">
                        {children}
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button size="md" mr={3} onClick={onClose} flexGrow={1} color="black">
                        Cancel
                    </Button>
                    <Button size="md" flexGrow={1} colorScheme={theme.colours.buttonScheme} onClick={onAction}>
                        {actionButton}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
